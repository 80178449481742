import { Flex, Image, Text, Link, Box, Skeleton } from "@chakra-ui/react";

import s from "./GroupChatInfoAvatar.module.scss";
import { AppSettings } from "../../../../../../../../common/AppSettings";
import { OverflowEllipsesText } from "../../../../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { GroupChatModel } from "../../../../../../GroupChatModel";
import { useGetLoadingState } from "../../../../../../../../common/loading/hooks/useGetLoadingState";
import TelegramIcon from "../../../../../../../../assets/images/telegramImage.svg";
import { useTranslation } from "react-i18next";
import { RenderSmoothImage } from "../../../../../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";
import useGA from "../../../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatEvents } from "../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

declare const appSettings: AppSettings;

export const GroupChatInfoAvatar = ({ contactInfo }: { contactInfo?: GroupChatModel }) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const loadingGroupChatInfo = useGetLoadingState("getGroupChatInfo");
  const trackEvent = useGA(EventCategories.Group);

  return (
    <Flex className={s.container} flexDir={"column"} alignItems={"center"}>
      <Skeleton borderRadius="50%" isLoaded={!(contactInfo === undefined || loadingGroupChatInfo)}>
        <Box>
          {contactInfo?.avatars?.length ? (
            <RenderSmoothImage
              alt={contactInfo?.title || ""}
              src={`${appSettings.apiBaseUrl}/file/${contactInfo?.avatars[0][0].fileId}`}
              boxSize="100px"
            />
          ) : (
            <RenderSmoothImage boxSize="100px" alt={contactInfo?.title || ""} />
          )}
        </Box>
      </Skeleton>

      <Flex className={s.info}>
        <Skeleton isLoaded={!(contactInfo === undefined || loadingGroupChatInfo)}>
          <OverflowEllipsesText
            text={contactInfo?.title}
            content={
              <Text mb="4px" variant="largeBold">
                {contactInfo?.title}
              </Text>
            }
          />
        </Skeleton>
        <Skeleton isLoaded={!(contactInfo === undefined || loadingGroupChatInfo)}>
          <OverflowEllipsesText
            text={`${groupChatInfoTranslation("{{members}} members", {
              members: `${contactInfo?.membersCount}`,
              count: contactInfo?.membersCount || 0,
            })}`}
            content={
              <Text color="var(--chakra-colors-darkGrey)" variant="small">
                {`${groupChatInfoTranslation("{{members}} members", {
                  members: `${contactInfo?.membersCount}`,
                  count: contactInfo?.membersCount || 0,
                })}`}
              </Text>
            }
          />
        </Skeleton>

        {contactInfo?.username && (
          <Skeleton isLoaded={!(contactInfo === undefined || loadingGroupChatInfo)}>
            <Flex className={s.groupUrl}>
              <Image src={TelegramIcon} boxSize={"20px"} />
              <Link
                href={`https://t.me/${contactInfo.username}`}
                data-pw="contact-telegram-link"
                isExternal
                ml={"8px"}
                color={"blueLink"}
                onClick={() => trackEvent(GroupsChatEvents.GroupsChatTGForward)}
              >
                {`https://t.me/${contactInfo.username}`}
              </Link>
            </Flex>
          </Skeleton>
        )}
      </Flex>
    </Flex>
  );
};
