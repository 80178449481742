import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  ListItem,
  List,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import s from "./AccordionUI.module.scss";

export const AccordionUI = ({
  items,
  showTitle,
  hiddenTitle,
  onOpen,
}: {
  items: ReactNode[];
  showTitle: string;
  hiddenTitle: string;
  onOpen?: () => void;
}) => {
  const [accordionText, setAccordionText] = useState(showTitle);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickButton = () => {
    if (!isOpen && onOpen) onOpen();
    setIsOpen(!isOpen);
  };

  return (
    <Accordion
      variant="stepsAccordion"
      onChange={e => {
        const isExpanded = e.valueOf() !== -1;
        setAccordionText(isExpanded ? hiddenTitle : showTitle);
        setIsOpen(isExpanded);
      }}
      allowToggle
    >
      <AccordionItem>
        <AccordionButton p="12px" onClick={handleClickButton}>
          <Box flex="1" textAlign="left">
            <Text variant="medium">{accordionText}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <List className={s.instructionStepsList} spacing={4}>
            {items &&
              items.map((el, ind) => {
                return (
                  <ListItem key={ind} className={s.listItem}>
                    {el}
                  </ListItem>
                );
              })}
          </List>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
