import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebBotState } from "./WebBotState";
import { BotModel } from "../bot/BotModel";
import { RootState } from "../../common/state/store";
import { CreateWebBotType } from "./WebBotApi";

const initialState: WebBotState = {
  webBot: undefined,
};

export const webBotSlice = createSlice({
  name: "webBotSlice",
  initialState,
  reducers: {
    createWebBot: (state, action: PayloadAction<CreateWebBotType>) => {
      return {
        ...state,
      };
    },
    createWebBotCompleted: (state, action: PayloadAction<BotModel>) => {
      return {
        ...state,
        webBot: action.payload,
      };
    },
    updateWebBot: (state, action: PayloadAction<BotModel>) => {
      return {
        ...state,
      };
    },
    updateWebBotCompleted: (state, action: PayloadAction<BotModel>) => {
      return {
        ...state,
        webBot: action.payload,
      };
    },
  },
});

export const { createWebBot, createWebBotCompleted, updateWebBot } = webBotSlice.actions;

export const selectWebBot = (state: RootState) => state.app.webBotState.webBot;

export default webBotSlice.reducer;
