import { Box, Flex } from "@chakra-ui/react";
import { ConversationMessageModel, ConversationTypeEnum, MessageStatusEnum } from "../../ConversationModel";
import { ErrorMessageItem } from "./ErrorMessageItem";
import { FormattedText } from "../../../../common/formattedText/formattedText";
import s from "../../Conversation.module.scss";

interface Props {
  message: ConversationMessageModel;
  conversationType: ConversationTypeEnum;
  highlightedText?: string;
}

export const TextMessageItem = (props: Props) => {
  const timeString = new Date(props?.message?.date).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    hourCycle: "h23",
  });

  const messageStatus = props.message.status;
  const messageTextLength = props.message.text?.length;
  const messagePhotoLength = props.message.photos.length;
  const messageDocumentLength = props.message.documents.length;
  return (
    <Box>
      {messageTextLength && messageStatus === MessageStatusEnum.Failed ? (
        <Flex
          className={`${s.messageTextWrapper} 
              ${(!!messageDocumentLength || messagePhotoLength > 0) && s.textWithImg} 
              ${props.message.replyMarkup !== null && s.textWithBtn}
              ${props.message.replyToMessage !== null ? s.textWithReply : ""}`}
          pr={!props.message.errorCode && (!!messageDocumentLength || messagePhotoLength > 0) ? "40px !important" : ""}
        >
          <Box className={s.messageTextWrapper_messageText}>
            <FormattedText text={props.message.text} entities={props.message.entities ?? []}></FormattedText>
          </Box>
          <Flex>
            <Box className={s.date}>{timeString}</Box>

            <Box ml={2} mb={-1.5}>
              <ErrorMessageItem errorCode={props.message.errorCode} conversationType={props.conversationType} />
            </Box>
          </Flex>
        </Flex>
      ) : messageTextLength ? (
        <>
          <Box
            className={`${s.messageTextWrapper} 
              ${(!!messageDocumentLength || messagePhotoLength > 0) && s.textWithImg} 
              ${props.message.replyMarkup !== null && s.textWithBtn} 
              ${props.message.replyToMessage !== null ? s.textWithReply : ""}`}
            pr={!props.message.errorCode && (!!messageDocumentLength || messagePhotoLength > 0) ? "40px !important" : ""}
          >
            <Box className={s.messageTextWrapper_messageText}>
              <FormattedText text={props.message.text} entities={props.message.entities ?? []}></FormattedText>
            </Box>
            {!messagePhotoLength && !messageDocumentLength && <Box className={s.date}>{timeString}</Box>}
          </Box>
        </>
      ) : null}
    </Box>
  );
};
