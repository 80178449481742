import { Flex, Skeleton } from "@chakra-ui/react";

interface Props {
  height?: string | number;
  width?: string | number;
  isMobile: boolean;
  count?: number;
}

export const SkeletonList = ({ height = "136px", width = "auto", isMobile, count = 15 }: Props) => {
  return (
    <Flex flexDirection="column" gap="8px">
      {Array(count)
        .fill(1)
        .map((el, ind) => (
          <Skeleton
            speed={0.5}
            startColor="line"
            endColor="bgLight"
            height={!isMobile ? "60px" : height}
            width={!isMobile ? "100%" : width}
            borderColor="bgMiddle"
            borderBottom="1px solid"
            borderRadius="8px"
            key={ind}
          />
        ))}
    </Flex>
  );
};
