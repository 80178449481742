import { BotModel } from "../../SidebarModel";
import { Avatar, Box, Flex, Icon, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import s from "../../Sidebar.module.scss";
import { BotListModel } from "../../../botList/BotListModel";
import { useTranslation } from "react-i18next";
import DownArrowMenu from "../../../../assets/images/downArrow.svg?react";

interface Props {
  botList: BotListModel | undefined;
  isOpenSideBar: boolean;
  loadingBots: boolean;
  changeCurrentBot: (bot: BotModel) => void;
  getAdditionalBots: () => void;
}

export const SideBarBotList = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
  return (
    <Box height={"60vh"} flexGrow={1} overflowY={"auto"} p={0}>
      {!props.loadingBots ? (
        <>
          {props.botList && props.botList.totalItems ? (
            <>
              {props.botList.items.map((el: BotModel) => (
                <Tooltip
                  display={el?.name?.length > 18 ? "auto" : "none"}
                  key={el.id}
                  variant="dominoLight"
                  label={el.name}
                  placement="right"
                >
                  <Flex
                    data-pw={el.name}
                    pl={"15px"}
                    onClick={() => props.changeCurrentBot(el)}
                    height={{ base: "48px", md: "60px" }}
                    flexDirection="row"
                    alignItems={"center"}
                    maxWidth={"100%"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                    textOverflow={"ellipsis"}
                    fontSize={{ base: "14px", md: "15px" }}
                    _hover={{ bgColor: "#F1F4F9" }}
                  >
                    <Avatar variant="dominoAvatar" className={s.menuBotAvatar} name={el.name} size="sm" />
                    <Text noOfLines={1}>{el.name}</Text>
                  </Flex>
                </Tooltip>
              ))}
              {props.botList && props.botList.currentPage < props.botList.totalPages && (
                <Flex
                  pl={!props.isOpenSideBar ? "8px" : "0px"}
                  onClick={props.getAdditionalBots}
                  height={{ base: "48px", md: "60px" }}
                  alignItems={"center"}
                  justifyContent={"center"}
                  w={"100%"}
                  cursor={"pointer"}
                  fontSize={{ base: "14px", md: "15px" }}
                  _hover={{ bgColor: "#F1F4F9" }}
                  data-pw="show-more"
                >
                  {props.isOpenSideBar && <Box>{t("show more")}</Box>}
                  <Icon as={DownArrowMenu} boxSize="26px" color="midDeepBlue" />
                </Flex>
              )}
            </>
          ) : (
            <Text pt={"12px"} textAlign="center" color="darkGrey">
              {t("No results found")}
            </Text>
          )}
        </>
      ) : (
        <Skeleton
          speed={0.5}
          startColor="line"
          endColor="bgLight"
          borderRadius="4px"
          height="100%"
          width="100%"
          border="1px solid"
          borderColor="line"
          fadeDuration={1}
        />
      )}
    </Box>
  );
};
