import { Box, Button, Link, Text, Flex } from "@chakra-ui/react";
import { BotModel } from "../../BotModel";
import s from "./BotInfo.module.scss";
import { useTranslation } from "react-i18next";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";
import { useGA } from "../../../../common/ga/GAEventTracker";
import { BotDeleteEvents, BotInteractionEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  botInfo: BotModel;
  onOpenBotRemoveModal: () => void;
  onOpenRefreshTokenModal: () => void;
}

export const BotInfo = ({ botInfo, onOpenBotRemoveModal, onOpenRefreshTokenModal }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const trackEvent = useGA(EventCategories.Bot);

  const onOpenRefreshTokenModalClick = () => {
    trackEvent(BotInteractionEvents.BotRefresh_Token);
    onOpenRefreshTokenModal();
  };

  const onOpenBotRemoveModalClick = () => {
    onOpenBotRemoveModal();
    trackEvent(BotDeleteEvents.BotDelete);
  };

  const redirectToTelegram = () => {
    const telegramUrl = "https://t.me/BotFather";
    window.open(telegramUrl, "_blank", "noopener,noreferrer");
  };

  const renderBotInfoItem = (labelKey: string, text: string, color: string, href: string) => (
    <Flex className={s.botInfo}>
      <Text className={s.botInfoLabel}>{labelKey}</Text>
      <Box className={s.botInfoText}>
        <ClipboardText
          textIsCopy={text}
          elemIsCopy={
            href ? (
              <Link className={s.rowUrlText} href={href} isExternal>
                {text}
              </Link>
            ) : (
              <Text>{text}</Text>
            )
          }
          color={color}
        />
      </Box>
    </Flex>
  );

  return (
    <Flex className={s.main}>
      <Flex className={s.block}>
        <Flex className={s.blockTitle}>
          <Text>{t("Information")}</Text>
        </Flex>
        <Flex className={s.blockContent}>
          {renderBotInfoItem(t("Bot Telegram Id"), botInfo.externalId, "#3e5e95", "")}
          {renderBotInfoItem(t("Bot User Name"), `@${botInfo.username}`, "#3e5e95", "")}
          {renderBotInfoItem(t("Bot URL"), botInfo.botUrl, "#3e5e95", botInfo.botUrl)}
        </Flex>
      </Flex>

      <Flex className={s.secondBlock}>
        <Flex className={s.block}>
          <Flex className={s.blockTitle}>
            <Text>{t("Bot Display Name")}</Text>
          </Flex>
          <Flex className={s.blockContent} flexDir="column" gap="12px">
            <Flex className={s.botToken}>
              <Text className={s.botInfoText} fontSize="15px">
                {botInfo.name}
              </Text>
              <Button
                variant="dominoOutlineViolet"
                onClick={redirectToTelegram}
                overflow={{ base: "hidden", md: "" }}
                textOverflow={"ellipsis"}
                w="140px"
                className={s.buttons}
                h="36px"
              >
                {t("Change")}
              </Button>
            </Flex>
            <Text className={s.botTokenText}>
              {t("To change the bot's name, you need to open")}{" "}
              <Link href="https://t.me/BotFather" isExternal className={s.link}>
                @BotFather
              </Link>{" "}
              {t("on Telegram and enter “Change“ above and type the command")}{" "}
              <Text as="span" color="#3E5E95">
                /setname
              </Text>{" "}
              {t("in Telegram.")}
            </Text>
          </Flex>
        </Flex>
        <Flex className={s.block}>
          <Flex className={s.blockTitle}>
            <Text>{t("Access Token")}</Text>
          </Flex>
          <Flex className={`${s.blockContent} ${s.blockToken}`}>
            <Flex className={s.botToken}>
              <Text className={s.botInfoText} fontSize="15px">
                {botInfo.accessToken}
              </Text>
              <Button
                variant="dominoOutlineViolet"
                onClick={onOpenRefreshTokenModalClick}
                overflow={{ base: "hidden", md: "" }}
                textOverflow={"ellipsis"}
                w="140px"
                className={s.buttons}
                h="36px"
              >
                {t("Refresh Token")}
              </Button>
            </Flex>
            <Text className={s.botTokenText}>{t("Access Token text")}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={s.block}>
        <Flex className={s.blockTitle}>
          <Text>{t("Remove Telegram Bot")}</Text>
        </Flex>
        <Flex className={s.blockContent} flexDir="column" gap="12px" flex="1 0 0">
          <Flex className={`${s.botToken} ${s.botTokenDelete}`}>
            <Text className={s.botTokenText}>{t("Remove bot text")}</Text>
            <Button
              className={`${s.buttons} ${s.buttonDel}`}
              variant="dominoOutlineRed"
              onClick={onOpenBotRemoveModalClick}
              h="36px"
            >
              {t("Remove")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
