import { call, put, select, takeLatest } from "redux-saga/effects";
import * as AutomationApi from "./AutomationApi";
import { begin, beginScope, complete, completeScope } from "../../common/loading/LoadingStateActions";
import { automationSlice, selectFlowList } from "./AutomationSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { FlowAutomationData, FlowListData, FlowTemplateData, TriggerListData, UsedFlowsInFlowData } from "./AutomationData";
import { handleException } from "../../common/SagaHelper";
import { routerSlice } from "../../common/router/RouterSlice";
import { store } from "../..";
import { FlowListModel, FlowTemplateModel, UsedFlowModel } from "./AutomationModel";
import { isChangePage } from "../../common/utils/pagination";
import { getTriggerModel, mapUsedFlowsInFlowData } from "./AutomationMapper";
import { CustomVariableData, TariffPlanFeatureTypeEnum } from "../../common/AppEnums";
import { organisationSlice, selectOrganisation } from "../organisation/OrganisationSlice";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import { downloadFileFromJson } from "../../common/utils/downloadFile";
import { FlowModel } from "../flowBuilder/FlowBuilderModel";
import { OrganisationModel } from "../organisation/OrganisationModel";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { t } from "i18next";
import { getTariffPlanAccessability } from "../../common/tariffPlan/TariffPlanUtil";
import { getPlan } from "../../common/tariffPlan/TariffPlanSaga";
import GTM from "../../common/ga/GAEventTracker";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { FlowEvents } from "../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";
import { BotModel } from "../sidebar/SidebarModel";

const trackEvent = GTM(EventCategories.Flow);

export function* automationSaga() {
  yield takeLatest(automationSlice.actions.addNewFlow, addNewFlow);
  yield takeLatest(automationSlice.actions.getFlowList, getFlowList);
  yield takeLatest(automationSlice.actions.getFlowTemplates, getFlowTemplates);
  yield takeLatest(automationSlice.actions.getExportFlow, getExportFlow);
  yield takeLatest(automationSlice.actions.getTriggerList, getTriggerList);
  yield takeLatest(automationSlice.actions.getUsersInFlow, getUsersInFlow);
  yield takeLatest(automationSlice.actions.getUsedFlowsInFlow, getUsedFlowsInFlow);
  yield takeLatest(automationSlice.actions.reorderFlow, reorderFlow);
  yield takeLatest(automationSlice.actions.deleteFlow, deleteFlow);
  yield takeLatest(automationSlice.actions.switchTriggerStatus, switchTriggerStatus);
  yield takeLatest(automationSlice.actions.copyFlow, copyFlow);
  yield takeLatest(automationSlice.actions.getBotVariablesInFlow, getBotVariablesInFlow);
  yield takeLatest(automationSlice.actions.renameFlow, renameFlow);
}

function* reorderFlow(
  action: PayloadAction<{
    botId: string;
    sourceFlowId: string;
    destinationFlowId: string;
  }>,
) {
  yield call(AutomationApi.reorderFlow, action.payload.botId, action.payload.sourceFlowId, action.payload.destinationFlowId);
}

function* addNewFlow(action: PayloadAction<string>) {
  const draftFlowId = getDraftFlowId(action.payload);
  yield call(removeDraftFlow, draftFlowId);
  yield put(routerSlice.actions.redirect(`/automation/flows/${action.payload}`));
}

function* getFlowList(action: PayloadAction<{ botId: string; page: number; search: string }>) {
  try {
    yield put(beginScope("getFlowList"));
    const organisation: OrganisationModel | undefined = yield select(selectOrganisation);
    if (!organisation) {
      yield put(organisationSlice.actions.getOrganisation());
    }
    const botId = action.payload.botId;
    const data: FlowListData = yield call(AutomationApi.getFlowList, botId, action.payload.search, action.payload.page);
    const model: FlowListModel = {
      ...data,
      items: data.items.map(el => {
        return { ...el, isDrag: false };
      }),
    };
    yield put(automationSlice.actions.getFlowListSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getFlowList"));
  }
}

export function* setLimitsPerBillingPlan(organisationModel: OrganisationModel) {
  let tariffPlan = store.getState().tariffPlanState.features;
  if (!tariffPlan.length) {
    yield getPlan();
  }

  tariffPlan = store.getState().tariffPlanState.features;

  const activeFlowsLimit = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ActiveFlows, tariffPlan);
  if (
    !activeFlowsLimit.IsUnlimited &&
    organisationModel?.totalFlow &&
    activeFlowsLimit.MaxCount <= organisationModel?.totalFlow
  ) {
    yield put(automationSlice.actions.setAccessToAddNewFlow(false));
  } else {
    yield put(automationSlice.actions.setAccessToAddNewFlow(true));
  }
  const activeImportLimit = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ImportExportFlow, tariffPlan);
  if (
    !activeImportLimit.IsUnlimited &&
    organisationModel?.totalFlow &&
    activeImportLimit.MaxCount <= organisationModel?.totalFlow
  ) {
    yield put(automationSlice.actions.setAccessToImportNewFlow(false));
  } else {
    yield put(automationSlice.actions.setAccessToImportNewFlow(true));
  }
}

function* getFlowTemplates() {
  try {
    yield put(beginScope("getFlowTemplates"));
    const currentBot: BotModel = yield select(selectCurrentBot);
    const data: FlowTemplateData[] = yield call(AutomationApi.getFlowTemplates, currentBot?.channel);
    const model: FlowTemplateModel[] = data;
    yield put(automationSlice.actions.getFlowTemplatesSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getFlowTemplates"));
  }
}

function* getExportFlow(action: PayloadAction<{ flowId: string; title: string }>) {
  try {
    yield put(begin());
    const flowId = action.payload.flowId;
    const data: string = yield call(AutomationApi.getExportFlow, flowId);
    downloadFileFromJson(data, action.payload.title);
    const organisation: OrganisationModel | undefined = yield select(selectOrganisation);
    if (!organisation) {
      yield put(organisationSlice.actions.getOrganisation());
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(complete());
  }
}

function* getTriggerList(action: PayloadAction<{ botId: string; page: number }>) {
  try {
    yield put(beginScope("getTriggerList"));
    const botId = action.payload.botId;
    const data: TriggerListData = yield call(AutomationApi.getTriggerList, botId, action.payload.page);
    trackEvent(FlowEvents.FlowTriggerUpload);
    yield put(automationSlice.actions.getTriggerListSucceed(getTriggerModel(data)));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getTriggerList"));
  }
}

function* switchTriggerStatus(
  action: PayloadAction<{ flowId: string | undefined; triggerId: string | undefined; isEnabled: boolean }>,
) {
  try {
    if (action.payload.flowId && action.payload.triggerId) {
      yield put(
        automationSlice.actions.switchTriggerStatusCompleted({
          flowId: action.payload.flowId,
          triggerId: action.payload.triggerId,
          isEnabled: action.payload.isEnabled,
        }),
      );
      yield call(AutomationApi.switchTriggerStatus, action.payload.flowId, action.payload.triggerId, action.payload.isEnabled);
    }
  } catch (e: unknown) {
    handleException(e);
    yield put(
      automationSlice.actions.switchTriggerStatusCompleted({
        flowId: action.payload.flowId,
        triggerId: action.payload.triggerId,
        isEnabled: !action.payload.isEnabled,
      }),
    );
  }
}

function* getUsersInFlow(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getUsersInFlow"));
    const data: number = yield call(AutomationApi.getUsersInFlow, action.payload);
    const model = data;
    yield put(automationSlice.actions.getUsersInFlowSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getUsersInFlow"));
  }
}

function* getUsedFlowsInFlow(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getUsedFlowsInFlow"));
    const data: UsedFlowsInFlowData = yield call(AutomationApi.getUsedFlowsInFlow, action.payload);
    const model: UsedFlowModel[] = mapUsedFlowsInFlowData(data);
    yield put(automationSlice.actions.getUsedFlowInFlowSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getUsedFlowsInFlow"));
  }
}

function* deleteFlow(action: PayloadAction<{ flowId: string; forceDelete: boolean }>) {
  try {
    yield put(beginScope("deleteFlow"));
    const botId = store.getState().app.sidebarState.bot?.id ?? "";
    const flowList: ReturnType<typeof selectFlowList> = yield select(selectFlowList);
    let page = flowList?.currentPage;
    if (flowList && page && isChangePage(flowList)) {
      page -= 1;
    }

    if (action.payload.forceDelete) {
      yield call(AutomationApi.deleteUsersInFlow, action.payload.flowId);
    }

    yield call(AutomationApi.deleteFlow, action.payload.flowId);

    yield put(automationSlice.actions.getFlowList({ botId, page: page ?? 1, search: "" }));
    yield put(automationSlice.actions.getTriggerList({ botId, page: 1 }));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteFlow"));
  }
}

function* copyFlow(action: PayloadAction<{ flowId: string; botId: string; locale: string }>) {
  try {
    yield put(beginScope("copyFlow"));
    const newFlow: FlowAutomationData = yield call(
      AutomationApi.copyFlow,
      action.payload.flowId,
      action.payload.botId,
      action.payload.locale,
    );
    yield put(
      notificationSlice.actions.notify({
        message: t("flow.Flow copied!"),
        type: "success",
      }),
    );
    trackEvent(FlowEvents.FlowCopyCompleted);
    yield put(routerSlice.actions.redirect(`/automation/flows/${newFlow.id}`));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("copyFlow"));
  }
}

function* getBotVariablesInFlow(action: PayloadAction<{ flowId: string; targetBotId: string }>) {
  try {
    yield put(beginScope("getBotVariablesInFlow"));
    const botVarsInFlow: CustomVariableData[] = yield call(
      AutomationApi.getBotVariablesInFlow,
      action.payload.flowId,
      action.payload.targetBotId,
    );
    yield put(
      automationSlice.actions.getBotVariablesInFlowSucceed({
        botVariablesInFlow: { flowId: action.payload.flowId, botVariables: botVarsInFlow },
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getBotVariablesInFlow"));
  }
}

function* renameFlow(action: PayloadAction<{ flowId: string; newName: string }>) {
  try {
    const draftFlowId = getDraftFlowId(action.payload.flowId);
    const isDraft: FlowModel = yield call(getDraftFlowInternal, draftFlowId);
    yield call(AutomationApi.renameFlow, action.payload.flowId, action.payload.newName);

    if (isDraft) {
      renameDraftFlow(action.payload.flowId, action.payload.newName, draftFlowId);
      yield put(
        automationSlice.actions.renameFlowCompleted({
          flowId: action.payload.flowId,
          newName: action.payload.newName,
        }),
      );
    }
    yield put(
      automationSlice.actions.renameFlowCompleted({
        flowId: action.payload.flowId,
        newName: action.payload.newName,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function renameDraftFlow(flowId: string, newName: string, draftFlowId: string) {
  const flowJson = localStorage.getItem(draftFlowId);
  const model = flowJson ? JSON.parse(flowJson) : undefined;
  if (model) {
    model.title = newName;
    localStorage.setItem(draftFlowId, JSON.stringify(model));
  }
}

const getDraftFlowInternal = (draftFlowId: string) => {
  const flowJson = localStorage.getItem(draftFlowId);
  return flowJson ? JSON.parse(flowJson) : undefined;
};

const getDraftFlowId = (flowId: string) => {
  return `flowData-${flowId}`;
};

const removeDraftFlow = (draftFlowId: string) => {
  localStorage.removeItem(draftFlowId);
};
