import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import {
  Button,
  Flex,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";

import { useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import s from "./GroupChatPage.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import {
  selectGroupChatList,
  selectSearchGroupChatList,
  selectTotalItems,
} from "../../../groupChat/components/GroupChatList/GroupChatListSlice";
import { selectOverLimitedConversations } from "../../../chatList/ChatListSlice";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import useGA from "../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatEvents } from "../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export const GroupChatPageHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "groupChatHeader" });
  useDocumentTitle(t("Group Chats", "Group Chats"));
  const currentBot = useAppSelector(selectCurrentBot);
  const groupList = useAppSelector(selectGroupChatList);
  const groupListSearch = useAppSelector(selectSearchGroupChatList);
  const groupChatListCount = useAppSelector(selectTotalItems);
  const overLimitConversations = useAppSelector(selectOverLimitedConversations);
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const trackEvent = useGA(EventCategories.Group);

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;

  return (
    <Flex className={s.groupChatHeaderContainer}>
      <Text noOfLines={1} className={s.hedaerText} variant="h2">
        {t("Group Chats")}
      </Text>
      {currentBot && !(groupChatListCount === 0 && groupListSearch === "" && groupList?.items.length === 0 && !isOverLimit) && (
        <>
          {isOverLimit ? (
            <Popover trigger={"hover"}>
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button size="sm" variant="dominoViolet" px="24px" className={sp.starPlanButton} data-pw="new-flow-button">
                      <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                      {t("Add Group")}
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                      <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                      <Flex alignItems="center" justifyContent="center" direction="column">
                        <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                          {tp("Advanced feature")}
                        </PopoverHeader>
                        <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                          {tp("Please upgrade your plan to create more group chats")}
                        </PopoverBody>
                        <TariffPlanRedirectButton onClose={onClose} />
                      </Flex>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          ) : (
            <Link
              isExternal
              onClick={() => trackEvent(GroupsChatEvents.GroupsChatAdd)}
              href={`https://t.me/${currentBot?.username}?startgroup&admin=change_info+post_messages+edit_messages+delete_messages+restrict_members+invite_users+
pin_messages+manage_topics+promote_members+manage_chat`}
            >
              <Button variant="dominoViolet">
                <Flex className={s.hedaerButton}>
                  <PlusIcon />
                  {t("Add Group")}
                </Flex>
              </Button>
            </Link>
          )}
        </>
      )}
    </Flex>
  );
};
