import { MessageOriginType } from "../../../../../../../common/AppEnums";
import { BotModel } from "../../../../../../sidebar/SidebarModel";
import { TooltipWithTouch } from "../../../../../../../common/tooltipWithTouch/TooltipWithTouch";
import { Avatar } from "@chakra-ui/react";
import { BotAvatarComponent } from "./components/BotAvatarComponent";
import { BroadcastAvatarComponent } from "./components/BroadcastAvatarComponent";
import { AiAssistantAvatarComponent } from "./components/AiAssistantAvatarComponent";

export const MessageAvatar = (props: {
  origin?: {
    type: MessageOriginType;
    name: string | null;
    id: string | null;
  };
  botInfo: BotModel;
}) => {
  if (!props.origin) {
    return <BotAvatarComponent botInfo={props.botInfo} />;
  }

  switch (props.origin.type) {
    case MessageOriginType.Broadcast:
      return <BroadcastAvatarComponent name={props.origin.name} id={props.origin.id} />;
    case MessageOriginType.Operator:
      return !!props.origin.name ? (
        <TooltipWithTouch label={props.origin.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
          <Avatar bg={"#3E5E95"} color={"#FFFFFF"} size={"sm"} name={props.origin.name} />
        </TooltipWithTouch>
      ) : (
        <BotAvatarComponent botInfo={props.botInfo} />
      );
    case MessageOriginType.AiAssistant:
      return <AiAssistantAvatarComponent />;
    case MessageOriginType.Bot:
    case MessageOriginType.ApiClient:
    case MessageOriginType.Undefined:
    default:
      return <BotAvatarComponent botInfo={props.botInfo} />;
  }
};
