import { Box, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, useMediaQuery } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BotImageStart from "../../assets/images/botImageStart.svg?react";
import s from "./BotTabs.module.scss";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { clearBotList, getBotList, selectBotList } from "../botList/BotListSlice";
import { useLocation, useSearchParams } from "react-router-dom";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../sidebar/SidebarSlice";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { clearBotInfo } from "../bot/BotSlice";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";
import { BotListContainer } from "../botList/BotListContainer";
import { WebBotList } from "../webBot/WebBotList";

export const BotTabs = () => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const dispatch = useAppDispatch();
  const botList = useAppSelector(selectBotList);
  const [SearchParams, setSearchParams] = useSearchParams("");
  const [activeTab, setActiveTab] = useState(0);
  const currentPage = Number(SearchParams.get("page") ?? 1);
  const search = SearchParams.get("search") ?? "";
  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const loadingBotList = useGetLoadingState("getBotList");
  const location = useLocation();
  const isLocationAddBot = location.pathname === "/addbot";
  const tab = SearchParams.get("tab");
  const isDesktop = useMediaQuery("(min-width: 1000px)")[0];

  enum ActiveTab {
    "TelegramBots" = "telegramBots",
    "WebBots" = "webBots",
  }

  const selectActiveTab = (tab: string) => {
    switch (tab) {
      case ActiveTab.TelegramBots:
        setActiveTab(0);
        setSearchParams(prev => {
          prev.set("tab", ActiveTab.TelegramBots);
          return prev;
        });
        break;
      case ActiveTab.WebBots:
        setActiveTab(1);
        setSearchParams(prev => {
          prev.set("tab", ActiveTab.WebBots);
          return prev;
        });
        break;
      default:
        setActiveTab(0);
        setSearchParams(prev => {
          prev.set("tab", ActiveTab.TelegramBots);
          return prev;
        });
        break;
    }
  };

  const onTelegramBotsTabSelect = () => {
    selectActiveTab(ActiveTab.TelegramBots);
    const existingData = botList?.items?.find(bot => bot.channel === "Telegram");
    if (!existingData) {
      dispatch(clearBotList());
      dispatch(getBotList({ page: currentPage, search, channel: "Telegram" }));
    }
  };

  const onWebBotsTabSelect = () => {
    selectActiveTab(ActiveTab.WebBots);
    const existingData = botList?.items?.find(bot => bot.channel === "Web");
    if (!existingData) {
      dispatch(clearBotList());
      dispatch(getBotList({ page: currentPage, search, channel: "Web" }));
    }
  };

  useEffect(() => {
    if (tab) {
      selectActiveTab(tab);
    } else {
      setSearchParams(prev => {
        prev.set("tab", ActiveTab.TelegramBots);
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    const tab = SearchParams.get("tab") || ActiveTab.TelegramBots;
    const channel = tab === ActiveTab.TelegramBots ? "Telegram" : "Web";
    dispatch(clearBotInfo());
    dispatch(getBotList({ page: currentPage, search, channel }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, SearchParams]);

  if (loadingBotList || isCurrentBotLoading) {
    return (
      <>
        <Tabs index={activeTab} isManual mb="24px">
          <TabList className={s.tabs}>
            <Tab onClick={onTelegramBotsTabSelect} className={s.tab}>
              {t("Telegram bots")}
            </Tab>
            <Tab onClick={onWebBotsTabSelect} className={s.tab}>
              {t("Widget bots")}
            </Tab>
          </TabList>
        </Tabs>
        <Box paddingInline={isDesktop ? "32px" : "16px"}>
          {Array(10)
            .fill(1)
            .map((el, ind) => (
              <Skeleton
                key={ind}
                data-pw="loadingFlow"
                className={s.skeleton}
                h={isDesktop ? "70px" : "140px"}
                mb="10px"
                speed={0.5}
                borderRadius="12px"
                startColor="line"
                endColor="bgLight"
              />
            ))}
        </Box>
      </>
    );
  }

  if (!currentBot || isLocationAddBot) {
    return (
      <Box className={s.botListEmptyPageContainer}>
        <BaseEmptyPage
          isAddBot={isLocationAddBot}
          botTitle={!currentBot ? t("It's time to create the first bot!") : t("It's time to create a bot!")}
          botDescription={t("Select the type of bot you want to create")}
          Image={BotImageStart}
        />
      </Box>
    );
  }

  return (
    <>
      <Tabs index={activeTab} isManual>
        <TabList className={s.tabs}>
          <Tab onClick={onTelegramBotsTabSelect} className={s.tab}>
            {t("Telegram bots")}
          </Tab>
          <Tab onClick={onWebBotsTabSelect} className={s.tab}>
            {t("Widget bots")}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel className={s.tabsPanel}>
            <BotListContainer botList={botList} currentPage={currentPage} search={search} />
          </TabPanel>
          <TabPanel className={s.tabsPanel}>
            <WebBotList botList={botList} currentPage={currentPage} search={search} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
