/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../common/state/store";
import { MemberListModel } from "./GroupChatMemberListModel";

export interface GroupChatMemberListState {
  isOpenMember: boolean;
  memberList: MemberListModel;
  searchText: string;
}

const initialState: GroupChatMemberListState = {
  isOpenMember: false,
  memberList: { totalItems: 50, currentPage: 1, totalPages: 5 },
  searchText: "",
};

export const groupChatMemberListSlice = createSlice({
  name: "groupChatMemberListSlice",
  initialState,
  reducers: {
    setIsOpenMembers: (state, action: PayloadAction<boolean>) => {
      state.isOpenMember = action.payload;
    },
    getMemberList: (state, action: PayloadAction<{ page: number; size: number; groupId: string; search: string }>) => {
      return {
        ...state,
        memberList: {
          currentPage: state.memberList.currentPage,
          totalItems: state.memberList.totalItems,
          totalPages: state.memberList.totalPages,
          items: undefined,
        },
      };
    },
    getMemberListCompleted: (state, action: PayloadAction<MemberListModel>) => {
      state.memberList = action.payload;
    },
    blockMember: (state, action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) => {
      return { ...state };
    },
    unblockMember: (state, action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) => {
      return { ...state };
    },
    muteMember: (state, action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) => {
      return { ...state };
    },
    unmuteMember: (state, action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) => {
      return { ...state };
    },
    deleteMember: (state, action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) => {
      return { ...state };
    },
    setSearchTextMemberList: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    clearStateMemberList: state => {
      return { ...initialState };
    },
  },
});

export const {
  setIsOpenMembers,
  getMemberList,
  getMemberListCompleted,
  blockMember,
  muteMember,
  unblockMember,
  unmuteMember,
  deleteMember,
  setSearchTextMemberList,
  clearStateMemberList,
} = groupChatMemberListSlice.actions;

export const selectIsOpenMembers = (state: RootState) => state.app.groupChatMemberListState.isOpenMember;
export const selectMemberList = (state: RootState) => state.app.groupChatMemberListState.memberList;
export const selectSearchText = (state: RootState) => state.app.groupChatMemberListState.searchText;

export default groupChatMemberListSlice.reducer;
