import { httpDelete, httpGet, httpPost, httpPostForm, httpPut } from "../../common/BaseApi";

export const getFlowList = (botId: string, filter: string, page: number) => {
  return httpGet(`/api/flow/bot/${botId}?Filter=${encodeURIComponent(filter)}&page=${page}&size=${0}`);
};

export const getExportFlow = (flowId: string) => {
  return httpGet(`/api/flow/${flowId}/export`);
};

export const getFlowTemplates = (channel: string) => {
  return httpGet(`/api/flow/templates?channel=${channel}`);
};

export const importFlow = (botId: string, file?: File | null, submitWarnings?: boolean) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file, file.name);
  }
  return httpPostForm(`/api/flow/import/${botId}${submitWarnings ? "?submitWarnings=true" : ""}`, formData);
};

export const importFlowTemplate = (botId: string, flowTemplateId: string) => {
  const formData = new FormData();
  return httpPostForm(`/api/flow/fromTemplate/${botId}?flowTemplateId=${flowTemplateId}`, formData);
};

export const getTriggerList = (botId: string, page: number) => {
  return httpGet(`/api/trigger/bot/${botId}?page=${page}&size=${10}`);
};

export const switchTriggerStatus = (flowId: string, triggerId: string, isEnabled: boolean) => {
  return httpPost(`/api/trigger/flow/${flowId}/activate/${triggerId}`, { isEnabled });
};

export const reorderFlow = (botId: string, sourceFlowId: string, destinationFlowId: string) => {
  return httpPost(`/api/flow/reorder`, {
    botId,
    sourceFlowId,
    destinationFlowId,
  });
};

export const deleteFlow = (id: string) => {
  return httpDelete(`/api/flow/${id}`);
};

export const copyFlow = (flowId: string, botId: string, locale: string) => {
  return httpPost(`/api/flow/${flowId}/copy`, { botId, locale });
};

export const getUsersInFlow = (flowId: string) => {
  return httpGet(`/api/flow/${flowId}/activeconversations`);
};

export const getUsedFlowsInFlow = (flowId: string) => {
  return httpGet(`/api/flow/${flowId}/referencedFlows`);
};

export const deleteUsersInFlow = (flowId: string) => {
  return httpDelete(`/api/flow/${flowId}/activeconversations`);
};

export const getBotVariablesInFlow = (flowId: string, targetBotId: string) => {
  return httpGet(`/api/customvariable/checkUsed/${flowId}/bot/${targetBotId}`);
};

export const renameFlow = (flowId: string, newName: string) => {
  return httpPut(`/api/flow/${flowId}/rename?newName=${newName}`, { flowId, newName });
};
