import { Button, Flex, Icon, Skeleton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GroupChatListModel } from "../../../../../../../../../GroupChatModel";
import ProfanityFilterIcon from "../../../../../../../../../../../assets/icons/profanityFilterIcon.svg?react";
import BackArrowIcon from "../../../../../../../../../../../assets/icons/backArrowIcon.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../common/state/store";
import {
  selectIsProfanityFilterEnabled,
  setIsOpenProfanityFilter,
} from "../../../../../../Tabs/ProfanityFilter/ProfanityFilterSlice";
import s from "../../../../../GroupChatInfo.module.scss";
import useGA from "../../../../../../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatProfanityEvents } from "../../../../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

interface Props {
  loadingGroupChatInfo: boolean;
  groupChatList: GroupChatListModel | undefined;
}

export const ProfanityFilterButton = ({ loadingGroupChatInfo, groupChatList }: Props) => {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo" }).t;
  const dispatch = useAppDispatch();
  const isEnabled = useAppSelector(selectIsProfanityFilterEnabled);
  const trackEvent = useGA(EventCategories.Group);

  return (
    <Skeleton isLoaded={!(loadingGroupChatInfo || groupChatList === undefined)}>
      <Button
        className={`${s.buttonInfo} ${s.buttonFirst}`}
        borderRadius="0px"
        w="100%"
        leftIcon={<Icon className={s.leftIcon} boxSize="20px" as={ProfanityFilterIcon} />}
        rightIcon={<Icon className={s.rightIcon} transform="rotate(180deg)" p="3px" boxSize="20px" as={BackArrowIcon} />}
        onClick={() => {
          dispatch(setIsOpenProfanityFilter(true));
          trackEvent(GroupsChatProfanityEvents.GroupsChatProfanity);
        }}
      >
        <Flex w="100%" align="center" justifyContent="space-between">
          <Text variant="medium" color="newBlack">
            {groupChatInfoTranslation("Profanity filter")}
          </Text>
          <Text variant="medium" color="var(--chakra-colors-darkGrey)">
            {isEnabled ? commonWords("On") : commonWords("Off")}
          </Text>
        </Flex>
      </Button>
    </Skeleton>
  );
};
