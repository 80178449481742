export const GroupConfettiModalEvents = {
  GroupsModal: "GroupsModal",
  GroupsModalClose: "GroupsModalClose",
  GroupsModalTry: "GroupsModalTry",
};

export const GroupsAddMacEvents = {
  GroupsAddMacInstractionsVectorOpen: "GroupsAddMacInstractionsVectorOpen",
  GroupsAddMacPermissionsVectorOpen: "GroupsAddMacPermissionsVectorOpen",
  GroupsAddMacTGNotifications: "GroupsAddMacTGNotifications",
  GroupsAddMacTGNotificationsClose: "GroupsAddMacTGNotificationsClose",
  GroupsAddMacTGNotificationsGo: "GroupsAddMacTGNotificationsGo",
  GroupsAddMacTGNotificationsCancel: "GroupsAddMacTGNotificationsCancel",
  GroupsAddMacFinish: "GroupsAddMacFinish",
  GroupsSendALinkeToTelegramMac: "GroupsSendALinkeToTelegramMac",
  GroupsAddMacModal: "GroupsAddMacModal",
  GroupsAddMacModalClose: "GroupsAddMacModalClose",
};

export const GroupsAddWinEvents = {
  GroupsAddWinSelectGroup: "GroupsAddWinSelectGroup",
  GroupsAddWinSendLink: "GroupsAddWinSendLink",
  GroupsAddWinPermissionsVectorOpen: "GroupsAddWinPermissionsVectorOpen",
  GroupsAddWinFinish: "GroupsAddWinFinish",
};

export const GroupsChatEvents = {
  GroupsChatAdd: "GroupsChatAdd",
  GroupsChatConversationOpen: "GroupsChatConversationOpen",
  GroupsChatMessageSent: "GroupsChatMassageSent",
  GroupsChatMessageMediaSent: "GroupsChatMassageMediaSent",
  GroupsChatMessageAttachmentSent: "GroupsChatMassageAttachmentSent",
  GroupsChatTGForward: "GroupsChatTGForward",
};

export const GroupsChatMembersEvents = {
  GroupsChatMembers: "GroupsChatMembers",
  GroupsChatMembersLoaded: "GroupsChatMembersLoaded",
  GroupsChatMembersSearch: "GroupsChatMembersSearch",
  GroupsChatMembersMute: "GroupsChatMembersMute",
  GroupsChatMembersBlock: "GroupsChatMembersBlock",
  GroupsChatMembersUnblock: "GroupsChatMembersUnblock",
  GroupsChatMembersExclude: "GroupsChatMembersExclude",
  GroupsChatMembersRefresh: "GroupsChatMembersRefresh",
};

export const GroupsChatProfanityEvents = {
  GroupsChatProfanity: "GroupsChatProfanity",
  GroupsChatProfanityToggle: "GroupsChatProfanityToggle",
  GroupsChatProfanitySave: "GroupsChatProfanitySave",
  GroupsChatProfanityReset: "GroupsChatProfanityReset",
  GroupsChatProfanityCancel: "GroupsChatProfanityCancel",
  GroupsChatProfanitySaveFinish: "GroupsChatProfanitySaveFinish",
};
