import { httpDelete, httpGet, httpPostForm, httpPutForm } from "../../common/BaseApi";
import { EditAIAssistantFormData, NewAIAssistantFormData } from "./AIAssistantListData";

export const getAIAssistantList = () => {
  return httpGet(`/api/aiAgents`);
};

export const getAIAssistant = (agentId: string) => {
  return httpGet(`/api/aiAgents/${agentId}`);
};

export const createAIAssistant = (newAIAssistantData: NewAIAssistantFormData) => {
  const formData = new FormData();
  formData.append("name", newAIAssistantData.name);

  if (newAIAssistantData.aiSourceText) {
    formData.append("aiSourceText", newAIAssistantData.aiSourceText);
  }
  if (newAIAssistantData.file) {
    formData.append("file", newAIAssistantData.file, newAIAssistantData.file.name);
  }
  return httpPostForm(`/api/aiAgents`, formData);
};

export const updateAIAssistant = (updatedAIAssistantData: EditAIAssistantFormData, agentId: string) => {
  const formData = new FormData();
  formData.append("name", updatedAIAssistantData.name);

  if (updatedAIAssistantData.aiSourceText) {
    formData.append("aiSourceText", updatedAIAssistantData.aiSourceText);
  }
  if (updatedAIAssistantData.file) {
    formData.append("file", updatedAIAssistantData.file, updatedAIAssistantData.file.name);
  }
  if (updatedAIAssistantData.id) {
    formData.append("id", updatedAIAssistantData.id);
  }
  if (updatedAIAssistantData.isFileUpdated) {
    formData.append("isFileUpdated", updatedAIAssistantData.isFileUpdated.toString());
  }
  return httpPutForm(`/api/aiAgents/${agentId}`, formData);
};

export const deleteAIAssistant = (agentId: string) => {
  return httpDelete(`/api/aiAgents/${agentId}`);
};

export const getFlowsInAIAssistant = (agentId: string) => {
  return httpGet(`/api/flow/referencedFlows/aiAgent/${agentId}`);
};
