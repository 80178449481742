import {
  Text,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Alert,
  Checkbox,
  Icon,
} from "@chakra-ui/react";
import s from "./BotRemovePopup.module.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AlertIcon from "../../../../assets/icons/alertIcon.svg?react";
import { useGA } from "../../../../common/ga/GAEventTracker";
import { BotDeleteEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  deleteBotPopupState: {
    id: string;
    title: string;
  };
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
  botFirstText: string;
  botSecondText: string;
}

export const BotRemovePopup = (props: Props) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bot.botDeletePopup",
  });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const trackEvent = useGA(EventCategories.Bot);
  const [checkboxState, setCheckBoxState] = useState(false);

  const onRemoveBotClick = () => {
    props.onDelete();
    trackEvent(BotDeleteEvents.BotDeleteConfirm);
  };

  const onRemoveBotCancelClick = () => {
    props.onClose();
    trackEvent(BotDeleteEvents.BotDeleteCancel);
  };

  useEffect(() => {
    if (checkboxState) trackEvent(BotDeleteEvents.BotDeleteCheckBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxState]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent minW={"300px"} p={{ base: "32px 16px", md: "32px" }} w={"95%"} maxW={"460px"}>
        <ModalCloseButton />
        <ModalBody fontSize="15px" p="0">
          <Flex flexDir="column" justifyContent="space-between">
            <Text fontSize={{ base: "20px", md: "24px" }} lineHeight={{ base: "26px", md: "32px" }}>
              {t("Do you really want to remove")} <q>{props.deleteBotPopupState.title}</q> ?
            </Text>
            <Alert variant="dominoWarningAlertVariant" status="warning" alignItems="flex-start" mt="16px">
              <Icon as={AlertIcon} boxSize="24px" mr="21px" />
              <Text>
                {t(props.botFirstText)}, <b> {t("all your conversations will be deleted")}.</b> {t(props.botSecondText)}
              </Text>
            </Alert>
            <Checkbox mt="26px" colorScheme="green" isChecked={checkboxState} onChange={e => setCheckBoxState(e.target.checked)}>
              {t("I understand, that all conversations will be removed")}
            </Checkbox>
          </Flex>
        </ModalBody>

        <ModalFooter p="0">
          <Flex className={s.buttons}>
            <Button variant="dominoOutlineViolet" onClick={onRemoveBotCancelClick} className={s.button}>
              {ct("Cancel")}
            </Button>
            <Button variant="dominoPrimaryRed" isDisabled={!checkboxState} onClick={onRemoveBotClick} className={s.button}>
              {t("Remove Bot")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
