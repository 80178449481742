import { takeLatest, put, call, takeEvery, select } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { PayloadAction } from "@reduxjs/toolkit";
import { userProfileSlice } from "./UserProfileSlice";
import { ChangeUserPasswordSendData, UserProfileData, UserProfileNotificationsData } from "./UserProfileData";
import {
  changePasswordApi,
  getUserProfileApi,
  getUserProfileNotificationSettings,
  setUserProfileNotificationSettings,
} from "./UserProfileApi";
import { notify } from "../../common/notifications/NotificationSlice";
import { t } from "i18next";
import { UserProfileModel, UserProfileNotificationsModel } from "./UserProfileModel";
import { changeUserProfile } from "../../common/user/UserApi";
import { userSlice } from "../../common/user/UserSlice";
import i18n from "../../common/i18next/i18n";
import { OperatorData } from "../flowBuilder/FlowBuilderData";
import * as FlowBuilderApi from "../flowBuilder/FlowBuilderApi";

export function* userProfileSaga() {
  yield takeEvery(userProfileSlice.actions.changePassword, changePassword);
  yield takeLatest(userProfileSlice.actions.getUserProfile, getUserProfile);
  yield takeLatest(userProfileSlice.actions.setUserProfile, setUserProfile);
  yield takeLatest(userProfileSlice.actions.getUserProfileNotifications, getNotifications);
  yield takeLatest(userProfileSlice.actions.setUserProfileNotifications, setNotifications);
}

function* changePassword(action: PayloadAction<ChangeUserPasswordSendData>) {
  try {
    yield call(changePasswordApi, action.payload);
    yield put(
      notify({
        message: t("userProfile.Password changed successfully!"),
        type: "success",
      }),
    );
    yield put(userProfileSlice.actions.getUserProfile());
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getUserProfile() {
  try {
    const userProfileData: UserProfileData = yield call(getUserProfileApi);
    yield put(userProfileSlice.actions.getUserProfileSucceed(userProfileData));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* setUserProfile(action: PayloadAction<UserProfileModel>) {
  const currentProfile: UserProfileModel = yield select(getUserProfile);
  try {
    const updateData = mapUserProfileModelToData(action.payload);
    yield call(changeUserProfile, updateData);

    if (currentProfile.locale !== action.payload.locale) {
      i18n.changeLanguage(action.payload.locale);
      yield put(userSlice.actions.setLanguageCompleted(action.payload.locale));
    }
    yield put(userSlice.actions.getProfileCompleted({ ...action.payload }));
    const operator: OperatorData = yield call(FlowBuilderApi.getOperatorTitle, action.payload.id);
    if (operator.roles) {
      yield put(userSlice.actions.setRoles(operator.roles));
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

const mapUserProfileModelToData = (model: UserProfileModel): UserProfileData => {
  return {
    ...model,
  };
};

function* getNotifications() {
  try {
    const data: UserProfileNotificationsData = yield call(getUserProfileNotificationSettings);
    const model: UserProfileNotificationsModel = { notifications: data.notifications, telegramBots: data.telegramBots };
    yield put(userProfileSlice.actions.getUserProfileNotificationsSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* setNotifications(action: PayloadAction<UserProfileNotificationsModel>) {
  try {
    const data: UserProfileNotificationsData = {
      notifications: action.payload.notifications,
      telegramBots: action.payload.telegramBots,
    };
    yield call(setUserProfileNotificationSettings, data);
    const model: UserProfileNotificationsModel = { notifications: data.notifications, telegramBots: action.payload.telegramBots };
    yield put(userProfileSlice.actions.getUserProfileNotificationsSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  }
}
