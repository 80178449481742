import { Helmet } from "react-helmet";

export default function WidgetScript() {
  return (
    <Helmet>
      <script>
        {`function loadChatWidgetScript() {
        const chatScript = document.createElement("script");
        chatScript.src = appSettings.chatWidgetScript;
        chatScript.id = "chatWidgetScript";
        chatScript.onload = function () {
          initializeChatWidget();
        };
        document.head.appendChild(chatScript);
      }

      function initializeChatWidget() {
        const newWidgetInfo = localStorage.getItem("widgetInfo") && JSON.parse(localStorage.getItem("widgetInfo"));
        const defaultLanguage = navigator.language || navigator.userLanguage;
        const currentLanguage = localStorage.getItem("i18nextLng") || defaultLanguage;
        if (currentLanguage === "en" || currentLanguage === "en-US") {
          window.dominoChatWidget.init(
            newWidgetInfo?.orgId || appSettings.chatWidgetOrgId,
            newWidgetInfo?.botId || appSettings.chatWidgetBotEnId,
            appSettings.chatWidgetUrl,
            newWidgetInfo?.title
          );
        } else if (currentLanguage === "ru" || currentLanguage === "ru-RU") {
          window.dominoChatWidget.init(
            newWidgetInfo?.orgId || appSettings.chatWidgetOrgId,
            newWidgetInfo?.botId || appSettings.chatWidgetBotRuId,
            appSettings.chatWidgetUrl,
            newWidgetInfo?.title
          );
        }
      }

      window.addEventListener("storageTest", () => {
        if (document.getElementById("chat-widget-iframe")) {
          window.dominoChatWidget.clearConversation();
        }
      });

      if (appSettings.showChatWidget) {
        loadChatWidgetScript();
      }`}
      </script>
    </Helmet>
  );
}
