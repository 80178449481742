import { httpDelete, httpGet, httpPost, httpPut } from "../../common/BaseApi";
import { BotVariableData, BotVariableDefinitionData, EditBotData, reorderCommandsData, switchCommandData } from "./BotData";
import { BotMenuCreateModel, BotUtmTagCreateModel, BotVariableModel, MenuCommandModel } from "./BotModel";

// bot
export const getBotData = (botId: string) => {
  return httpGet(`/api/bot/${botId}`);
};

export const getBotActiveConversationsCount = (botId: string) => {
  return httpGet(`/api/bot/${botId}/activeConversations`);
};

export const editBot = (botData: EditBotData) => {
  return httpPut(`/api/telegramBot`, botData);
};

export const deleteBot = (botId: string) => {
  return httpDelete(`/api/bot/${botId}`);
};

export const getBotVariables = (botId: string) => {
  return httpGet(`/api/customVariable/values/bot/${botId}`);
};

// variables
export const getIsVariableInUse = (variableId: string) => {
  return httpGet(`/api/customvariable/checkUsed/${variableId}`);
};

export const createVariable = (data: BotVariableModel) => {
  return httpPost(`/api/customvariable/definition`, data);
};

export const editVariableValue = (botId: string, data: BotVariableData) => {
  return httpPut(`/api/customVariable/values/bot/${botId}/variable/${data.id}`, data);
};

export const editVariableDefenition = (variableId: string, data: BotVariableDefinitionData) => {
  return httpPut(`/api/customvariable/definition/${variableId}`, data);
};

export const deleteVariable = (variableId: string) => {
  return httpDelete(`/api/customVariable/definition/${variableId}`);
};

export const setBotWebHook = (botId: string) => {
  return httpPost(`/api/telegrambotconfig/setwebhook/${botId}`, null);
};

//commands
export const addBotMenuCommandData = (botId: string, data: BotMenuCreateModel) => {
  return httpPost(`/api/telegramBotConfig/menuCommands/${botId}`, data);
};

export const deleteBotMenuCommand = (botId: string, commandId: string) => {
  return httpDelete(`/api/telegramBotConfig/menuCommands/${botId}/${commandId}`);
};

export const switchBotMenuCommands = (botId: string, data: switchCommandData) => {
  return httpPut(`/api/telegramBotConfig/menuCommands/${botId}/switch`, data);
};

export const getBotMenuCommands = (botId: string, page?: number, size?: number) => {
  return httpGet(`/api/telegramBotConfig/menuCommands/${botId}?page=${page}&size=${size}&orderBy=CommandType`);
};

export const editBotMenuCommand = (botId: string, data: MenuCommandModel) => {
  return httpPut(`/api/telegramBotConfig/menuCommands/${botId}`, data);
};

export const reorderCommandsItems = (botId: string, data: reorderCommandsData) => {
  return httpPut(`/api/telegramBotConfig/menuCommands/${botId}/reorder`, data);
};

//Utm
export const addBotUtmTag = (botId: string, data: BotUtmTagCreateModel) => {
  return httpPost(`/api/bot/${botId}/utm`, data);
};

export const deleteBotUtmTag = (botId: string, tagId: string) => {
  return httpDelete(`/api/bot/${botId}/utm/${tagId}`);
};

export const getBotUtmTags = (botId: string, page?: number, size?: number) => {
  return httpGet(`/api/bot/${botId}/utm?page=${page}&size=${size}`);
};
