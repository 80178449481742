import { Box } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import s from "../../ChatList.module.scss";
import { ChatListItemModel, ChatListModel, TeamsModel, OverLimitConversationsModel } from "../../ChatListModel";
import { MutableRefObject, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { selectConversationUploading, setSearchText } from "../../ChatListSlice";
import { ConversationsOverLimitPanel } from "../ConversationsOverLimitPanel";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import ChatListItem from "./components/ChatListItem/ChatListItem";
import ChatListFilters from "./components/ChatListFilters/ChatListFilters";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { Loading } from "../../../../common/loading/LoadingStateContainer";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../../common/hooks/useDebounce";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { useMobileView, useTabletView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";

interface Props {
  chatList?: ChatListModel;
  chatListRef: MutableRefObject<HTMLDivElement | null>;
  myTeams?: TeamsModel;
  getChatListOnScroll: (lastId: string) => void;
  overLimitConversations?: OverLimitConversationsModel[];
}

export const ChatList = ({ chatList, chatListRef, getChatListOnScroll, myTeams, overLimitConversations }: Props) => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const { t } = useTranslation("translation", { keyPrefix: "chatList" });
  const dispatch = useAppDispatch();
  const isLoading = useGetLoadingState("getChatList");

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchValue, setSearchValue] = useState(search);
  const debouncedSearchText = useDebounce(searchValue, 500);

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
    dispatch(setSearchText(debouncedSearchText));
  }, [searchValue, debouncedSearchText, search, setSearchParams, dispatch]);

  const canUploadConv = useAppSelector(selectConversationUploading);

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;
  const isTablet = useTabletView();
  const isMobile = useMobileView();

  const trackEvent = useGAWithCustomParameters(EventCategories.Chats);

  const getAdditionalChats = useCallback(() => {
    const container = chatListRef.current;
    if (!container) return;
    if (!chatList) return;

    const { scrollHeight, clientHeight } = container;
    if (scrollHeight <= clientHeight && canUploadConv && !isLoading) {
      const lastId = Number(chatList.lastId);
      const id = String(lastId + 1);
      chatList && getChatListOnScroll(id);
    }
  }, [canUploadConv, chatList, chatListRef, getChatListOnScroll, isLoading]);

  useEffect(() => {
    getAdditionalChats();
  }, [chatList, getAdditionalChats]);

  useEffect(() => {
    if (chatList && chatList?.totalItems) {
      trackEvent("ChatlistLoaded", "", { count: chatList.totalItems });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList, chatList?.totalItems]);

  const getAdditionalChatsOnScroll = () => {
    if (chatListRef.current) {
      const scrollHeight = chatListRef.current.scrollHeight;
      const scrollTop = chatListRef.current.scrollTop;
      const clientHeight = chatListRef.current?.clientHeight;
      const isBottom = clientHeight + scrollTop > scrollHeight - 2;

      if (isBottom && chatList && !isLoading && canUploadConv) {
        const lastId = Number(chatList.lastId);
        const id = String(lastId + 1);
        getChatListOnScroll(id);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getAdditionalChats);

    return () => document.removeEventListener("resize", getAdditionalChats);
  }, [getAdditionalChats]);

  return (
    <Box className={s.chatListContainer}>
      <Box className={s.header}>
        <Box className={s.searchBlock}>
          <InputSearch placeholder={t("Search by name")} setSearchText={setSearchValue} searchText={searchValue} />
        </Box>
        <ChatListFilters chatList={chatList} myTeams={myTeams} />
        {isOverLimit && (
          <Box className={s.overLimitItem}>
            <ConversationsOverLimitPanel overLimitCount={overLimitCount} />
          </Box>
        )}
      </Box>
      <Box
        className={s.content}
        h={`calc(100dvh - ${isTablet ? "237px" : "177px"} - ${isTrial && isMobile ? "60px" : "0px"})`}
        ref={chatListRef}
        onScroll={getAdditionalChatsOnScroll}
      >
        <Box h="100%" position="relative">
          {!chatList?.items.length && !isOverLimit ? (
            <Box className={s.noData}>{t("No Conversations")}</Box>
          ) : (
            chatList?.items?.map((chat: ChatListItemModel, index: number) => {
              const compareDate = chat?.lastMessage?.date == null ? chat?.createDate : chat?.lastMessage.date;
              return <ChatListItem chat={chat} compareDate={compareDate} key={index} />;
            })
          )}
          <Box maxH="10dvh">
            <Loading scope="getChatList" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
