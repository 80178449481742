import { forwardRef, MutableRefObject, Ref, useCallback, useEffect, useState } from "react";
import ReactFlow, {
  NodeChange,
  applyNodeChanges,
  EdgeChange,
  applyEdgeChanges,
  Connection,
  addEdge,
  MarkerType,
  Background,
  Controls,
  NodePositionChange,
  useReactFlow,
  Edge,
  ReactFlowInstance,
  updateEdge,
} from "reactflow";
import { v1 as uuidv1 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import {
  ButtonsContainerFlowAction,
  FlowActionModel,
  FlowActionType,
  FlowModel,
  HandleNegativeTypes,
  HandlePositiveTypes,
  MultiHanldeNodeTypeDiscriminator,
  NodeModel,
  NodeType,
  NodeValidation,
  SendTextFlowActionModel,
} from "../FlowBuilderModel";
import { setNodes, setEdges, changeNodePosition, selectIsSetCenter, setNodeCenter } from "../FlowBuilderSlice";
import { Node } from "reactflow";
import ActionNode from "./ActionNode/ActionNode";
import CustomEdge from "./CustomEdge/CustomEdge";
import StartingNode from "./StartingNode/StartingNode";
import { mapToNodeModel } from "../FlowBuilderMapper";
import { IconButton, Image } from "@chakra-ui/react";
import redTrashIcon from "../../../assets/images/redTrashIcon.svg";
import { getSideBarIsOpenFromStorage } from "../../sidebar/SidebarSaga";
import { FlowMarkupTypeDiscriminator } from "../../../common/AppEnums";
import CreationMenuNode from "./CreationMenuNode/CreationMenuNode";
import { selectTours, startTour } from "../../onboardingTour/OnboardingToursSlice";
import { TourNames } from "../../onboardingTour/OnboardingTourEnums";
import { selectOrganisation } from "../../organisation/OrganisationSlice";
import { useTabletView } from "../../layout/LayoutHelper/ResolutionHooks";
import { FlowBuilderHeader } from "./Header/FlowBuilderHeader";

interface Props {
  flow: FlowModel;
  editNode: (node: NodeModel) => void;
  deleteEdge: (id?: string) => void;
  validation: NodeValidation;
  onDrop: (node: NodeModel) => void;
  goStart: boolean;
  setGoStartNode: (status: boolean) => void;
  addNode: (node: NodeModel) => void;
  onNodeAccordionDelete: () => void;
  connectingNodeId: MutableRefObject<{
    current: string;
    handleId?: string | undefined;
  } | null>;
  isDisabledAddNode: boolean;
}

interface newConnection extends Connection {
  style?: { stroke: string };
  markerEnd: {
    type: MarkerType;
    color: string;
  };
}
const nodeTypes = { Trigger: StartingNode, Action: ActionNode || CreationMenuNode };
const edgeTypes = { custom: CustomEdge };

export const FlowBuilder = forwardRef(function FlowBuilder(props: Props, ref: Ref<HTMLDivElement>) {
  const dispatch = useAppDispatch();
  const tours = useAppSelector(selectTours);
  const isSetNodeCenter = useAppSelector(selectIsSetCenter);
  const flowBuilderTour = tours?.find(tour => tour.tourName === TourNames.FlowBuilderTourName);
  const shouldShowTour = window.innerWidth <= 999 ? false : true;
  const triggerTour = tours?.find(tour => tour.tourName === TourNames.TriggerTourName);
  const [isTriggerTourRun, setIsTriggerTourRun] = useState<boolean>(false);

  const organisations = useAppSelector(selectOrganisation);
  const isTablet = useTabletView();
  // const [nodesWithStyles, setNodesWithStyles] = useState(props.flow.nodes);
  let nodesWithStyles = props.flow.nodes;
  useEffect(() => {
    if (flowBuilderTour?.run) {
      nodesWithStyles = props.flow.nodes.map(node => {
        if (node.type === "Trigger") {
          return {
            ...node,
            zIndex: 20000,
          };
        }
        return node;
      });
      //setNodesWithStyles(newNodesWithStyles);
      dispatch(setNodes(nodesWithStyles));
    }
    return () => {
      const nodeWithoutStyles = props.flow.nodes.map(node => {
        if (node.type === "Trigger") {
          return {
            ...node,
            zIndex: 0,
          };
        }
        return node;
      });
      dispatch(setNodes(nodeWithoutStyles));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowBuilderTour?.run]);

  useEffect(() => {
    if (triggerTour?.run) {
      setIsTriggerTourRun(true);
    } else {
      setIsTriggerTourRun(false);
    }
  }, [triggerTour?.run]);

  const showFlowBuilderOnboardingTour =
    (organisations?.totalFlow ?? 0) <= 1 && !flowBuilderTour?.isCompleted && !flowBuilderTour?.isSkipped && shouldShowTour;

  const { setCenter, screenToFlowPosition } = useReactFlow();
  const [deleteBtnPosition, setDeleteBtnPosition] = useState({ x: 0, y: 0 });
  const [isDeleteBtnVisible, setDeleteBtnVisible] = useState(false);
  const [currentEdge, setCurrentEdge] = useState<Edge>();
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      if (changes[0]?.type === "position" && !(changes[0] as NodePositionChange).dragging) {
        dispatch(changeNodePosition());
      }
      dispatch(setNodes(applyNodeChanges(changes, props.flow.nodes)));
    },
    [dispatch, props.flow.nodes],
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      dispatch(setEdges(applyEdgeChanges(changes, props.flow.edges)));
    },
    [dispatch, props.flow.edges],
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      let doubleEdge = false;
      props.flow.edges.forEach(el => {
        if (!doubleEdge) {
          doubleEdge = el.source === connection.source && el.sourceHandle === connection.sourceHandle;
        }
      });

      if (doubleEdge) {
        return;
      }

      if (connection.source === connection.target) {
        return;
      }
      let newConnection: unknown = {
        ...connection,
        markerEnd: { type: MarkerType.ArrowClosed },
      };
      const isMultiHandleNode = props.flow.nodes.some(
        node => node.id === connection.source && MultiHanldeNodeTypeDiscriminator.hasOwnProperty(node.data?.typeDiscriminator),
      );
      const isPositiveHandle =
        isMultiHandleNode &&
        connection.sourceHandle !== null &&
        (connection.sourceHandle.includes(HandlePositiveTypes.ok) ||
          connection.sourceHandle?.includes(HandlePositiveTypes.true) ||
          connection.sourceHandle?.includes(HandlePositiveTypes["success-source"]));
      const isNegativeHandle =
        isMultiHandleNode && connection.sourceHandle !== null && HandleNegativeTypes.hasOwnProperty(connection.sourceHandle);

      if (isNegativeHandle) {
        newConnection = {
          ...connection,
          style: { stroke: "#EB5038" },
          markerEnd: { type: MarkerType.ArrowClosed, color: "#EB5038" },
        };
      }
      const currentNode = props.flow.nodes.find(node => node.id === connection.source)?.data as ButtonsContainerFlowAction;
      let isReply = false;
      if (currentNode && currentNode.replyMarkup) {
        isReply = currentNode.replyMarkup.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
      }
      if (isReply && connection.sourceHandle !== "common-source" && connection.sourceHandle !== "success-source") {
        newConnection = {
          ...connection,
          style: { stroke: "#325EE6" },
          markerEnd: { type: MarkerType.ArrowClosed, color: "#325EE6" },
        };
      } else {
        if (isPositiveHandle) {
          newConnection = {
            ...connection,
            style: { stroke: "#4EAA4A" },
            markerEnd: { type: MarkerType.ArrowClosed, color: "#4EAA4A" },
          };
        }
      }

      const newEdges = addEdge(newConnection as Connection, [...props.flow.edges]);
      dispatch(setEdges(newEdges));
      return;
    },
    [dispatch, props.flow.edges, props.flow.nodes],
  );

  const focustStartNode = useCallback(() => {
    const homeNode = (props.flow.nodes as NodeModel[]).find(el => el.id === "1");
    if (homeNode) {
      const node = homeNode;
      const averageStartNodeHeight = 400;
      const averageStartNodeWidth = 300;
      const x = node.position.x + averageStartNodeWidth / 2;
      const y = node.position.y + averageStartNodeHeight / 2;
      const zoom = 0.9;
      setCenter(x, y, { zoom, duration: 1000 });
    }
    props.setGoStartNode(false);
  }, [props, setCenter]);

  useEffect(() => {
    if (props.goStart) {
      focustStartNode();
    }
  }, [focustStartNode, props.goStart]);

  const markInvalidNode = useCallback(() => {
    const invalidNode = (props.flow.nodes as NodeModel[]).find(el => el.id === props.validation.nodeId);
    if (invalidNode) {
      const node = invalidNode;
      const averageStartNodeHeight = 400;
      const averageStartNodeWidth = 300;
      const x = node.position.x + averageStartNodeWidth / 2;
      const y = node.position.y + averageStartNodeHeight / 2;
      const zoom = 0.7;
      setCenter(x, y, { zoom, duration: 1000 });
    }
  }, [props.flow.nodes, props.validation.nodeId, setCenter]);

  useEffect(() => {
    if (props.validation.errors.length && props.validation.flowId) {
      markInvalidNode();
    }
  }, [markInvalidNode, props.validation]);

  const onNodeClick = (node: Node) => {
    props.editNode(mapToNodeModel(node));
  };

  const onInitFlow = (reactFlowInstance: ReactFlowInstance) => {
    setReactFlowInstance(reactFlowInstance);
    if (props.flow.nodes.find(el => el.id === props.validation.nodeId)) {
      return markInvalidNode();
    }
    return setStartNodeCenter();
  };

  const setStartNodeCenter = () => {
    const triggerNode = (props.flow.nodes as NodeModel[]).find(el => el.type === NodeType.Trigger);
    if (triggerNode) {
      const node = triggerNode;
      const averageStartNodeHeight = 400;
      const averageStartNodeWidth = 300;
      const x = node.position.x + averageStartNodeWidth / 2;
      const y = node.position.y + averageStartNodeHeight / 2;
      const zoom = 0.7;
      setCenter(x, y, { zoom, duration: 1000 });
    }
  };

  useEffect(() => {
    if (isSetNodeCenter) {
      setStartNodeCenter();
      dispatch(setNodeCenter(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetNodeCenter, flowBuilderTour?.run]);

  useEffect(() => {
    if (organisations) {
      if (showFlowBuilderOnboardingTour) {
        setTimeout(() => {
          dispatch(startTour(TourNames.FlowBuilderTourName));
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisations]);

  const onDeleteEdge = () => {
    props.deleteEdge(currentEdge?.id);
    setDeleteBtnVisible(false);
  };

  const onEdgeMouseEnter = (e: any, edge: Edge) => {
    if (e.target.classList[0] === "react-flow__edgeupdater") {
      return;
    }
    const sideBarWidth = getSideBarIsOpenFromStorage() ? 227 : 70;
    setDeleteBtnPosition({ x: e.clientX - sideBarWidth, y: e.clientY - 105 });
    setDeleteBtnVisible(true);
    setCurrentEdge(edge);
  };

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, connection: Connection) => {
      let doubleEdge = false;
      props.flow.edges.forEach(el => {
        if (el.id !== oldEdge.id && !doubleEdge) {
          doubleEdge = el.source === connection.source && el.sourceHandle === connection.sourceHandle;
        }
      });

      if (doubleEdge) {
        return;
      }

      if (connection.source === connection.target) {
        return;
      }
      let newConnection: newConnection = {
        ...connection,
        style: { stroke: "#b1b1b7" },
        markerEnd: { type: MarkerType.ArrowClosed, color: "#b1b1b7" },
      };
      const isMultiHandleNode = props.flow.nodes.some(
        node => node.id === connection.source && MultiHanldeNodeTypeDiscriminator.hasOwnProperty(node.data?.typeDiscriminator),
      );
      const isPositiveHandle =
        isMultiHandleNode &&
        connection.sourceHandle !== null &&
        (connection.sourceHandle.includes(HandlePositiveTypes.ok) ||
          connection.sourceHandle?.includes(HandlePositiveTypes.true) ||
          connection.sourceHandle?.includes(HandlePositiveTypes["success-source"]));
      const isNegativeHandle =
        isMultiHandleNode && connection.sourceHandle !== null && HandleNegativeTypes.hasOwnProperty(connection.sourceHandle);

      if (isNegativeHandle) {
        newConnection = {
          ...connection,
          style: { stroke: "#EB5038" },
          markerEnd: { type: MarkerType.ArrowClosed, color: "#EB5038" },
        };
      }
      const currentNode = props.flow.nodes.find(node => node.id === connection.source)?.data as ButtonsContainerFlowAction;
      let isReply = false;
      if (currentNode && currentNode.replyMarkup) {
        isReply = currentNode.replyMarkup.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
      }
      if (isReply && connection.sourceHandle !== "common-source" && connection.sourceHandle !== "success-source") {
        newConnection = {
          ...connection,
          style: { stroke: "#325EE6" },
          markerEnd: { type: MarkerType.ArrowClosed, color: "#325EE6" },
        };
      } else {
        if (isPositiveHandle) {
          newConnection = {
            ...connection,
            style: { stroke: "#4EAA4A" },
            markerEnd: { type: MarkerType.ArrowClosed, color: "#4EAA4A" },
          };
        }
      }
      const updateOldEdge = {
        ...oldEdge,
        style: newConnection.style,
        markerEnd: newConnection.markerEnd,
      };

      const update = updateEdge(updateOldEdge, newConnection, [...props.flow.edges]);
      dispatch(setEdges(update));
      return;
    },
    [dispatch, props.flow.edges, props.flow.nodes],
  );
  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const node = event.dataTransfer.getData("application/json");

    const position = reactFlowInstance?.screenToFlowPosition({
      x: event.clientX,
      y: event.clientY - 25,
    });
    const newNode = { ...JSON.parse(node), position };
    props.onDrop(newNode);
    const averageStartNodeHeight = 400;
    const averageStartNodeWidth = 300;
    const x = newNode.position.x + averageStartNodeWidth / 2;
    const y = newNode.position.y + averageStartNodeHeight / 2;
    const zoom = 0.7;
    setCenter(x, y, { zoom, duration: 1000 });
  };

  const onConnectStart = useCallback(
    (_: React.MouseEvent | React.TouchEvent, { nodeId, handleId }: { nodeId: string | null; handleId: string | null }) => {
      const classPoint: string = _.currentTarget.classList[0];
      if (classPoint !== "react-flow__edgeupdater" && nodeId && handleId) {
        props.connectingNodeId.current = { current: nodeId, handleId: handleId };
      }
    },
    [props.connectingNodeId],
  );

  const onConnectEnd = useCallback(
    (event: any) => {
      if (!props.connectingNodeId.current) return;
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (targetIsPane && !props.isDisabledAddNode) {
        const id = "creation_menu";
        const newNode: NodeModel = {
          id,
          type: NodeType.Action,
          position: screenToFlowPosition({
            x: event.x || 0,
            y: event.y || 0,
          }),
          flowAction: { title: `Node ${id}`, typeDiscriminator: FlowActionType.CreationMenuAction },
        };
        const nodeData: FlowActionModel = {
          text: "",
          typeDiscriminator: FlowActionType.CreationMenuAction,
          file: null,
        } as SendTextFlowActionModel;
        const addNode: NodeModel = {
          id: newNode.id,
          type: newNode.type,
          position: newNode.position,
          flowAction: nodeData,
        };

        props.addNode(addNode);
        const updatedEdges = props.flow.edges.filter(el => el.target !== "creation_menu" && el.source !== "creation_menu");

        const stroke = { stroke: "#b1b1b7", color: "#b1b1b7" };

        if (props.connectingNodeId.current.handleId === "error" || props.connectingNodeId.current.handleId === "false") {
          stroke.stroke = "#EB5038";
          stroke.color = "#EB5038";
        }
        if (
          props.connectingNodeId.current.handleId === "ok" ||
          props.connectingNodeId.current.handleId?.includes("true") ||
          props.connectingNodeId.current.handleId?.includes("success-source")
        ) {
          stroke.stroke = "#4EAA4A";
          stroke.color = "#4EAA4A";
        }

        const currentNode = props.flow.nodes.find(
          node => props.connectingNodeId.current && node.id === props.connectingNodeId.current.current,
        )?.data as ButtonsContainerFlowAction;
        let isReply = false;
        if (currentNode && currentNode.replyMarkup) {
          isReply = currentNode.replyMarkup.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
        }

        if (
          isReply &&
          props.connectingNodeId.current.handleId !== "common-source" &&
          props.connectingNodeId.current.handleId !== "success-source"
        ) {
          stroke.stroke = "#325EE6";
          stroke.color = "#325EE6";
        }

        const newEdges = updatedEdges.concat({
          id: uuidv1(),
          source: props.connectingNodeId.current.current,
          target: id,
          style: { stroke: stroke.stroke },
          sourceHandle: props.connectingNodeId.current.handleId,
          markerEnd: { type: MarkerType.ArrowClosed, color: stroke.color },
          updatable: false,
        });

        dispatch(setEdges(newEdges));
      }
    },
    [dispatch, props, screenToFlowPosition],
  );

  const onEdgeClick = (e: any, edge: Edge) => {
    const node1 = edge.target;
    const node2 = edge.source;
    const findNode1 = document.querySelector(`[data-id="${node1}"]`);
    const findNode2 = document.querySelector(`[data-id="${node2}"]`);
    if (findNode1 && findNode2) {
      findNode1.id = "active_node";
      findNode2.id = "active_node";
    }
  };

  const deleteActiveNode = (e: any) => {
    if (e.target.classList[0] !== "react-flow__edge-interaction") {
      const all = document.querySelectorAll("#active_node");

      if (all) {
        all.forEach(el => (el.id = ""));
      }
    }
  };

  return (
    <>
      {isTablet && <FlowBuilderHeader />}
      <ReactFlow
        panOnDrag={!isTriggerTourRun}
        nodesDraggable={!isTriggerTourRun}
        deleteKeyCode={""}
        ref={ref}
        nodes={nodesWithStyles}
        edges={props.flow.edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeClick={onEdgeClick}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={() => {
          setDeleteBtnVisible(false);
        }}
        onClick={e => {
          props.onNodeAccordionDelete();
          deleteActiveNode(e);
        }}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onEdgeUpdate={onEdgeUpdate}
        className="react-flow"
        onNodeClick={(e, node) => onNodeClick(node)}
        onInit={onInitFlow}
        defaultMarkerColor="#848488"
        onEdgesDelete={() => {
          setDeleteBtnVisible(false);
        }}
        onDrop={onDrop}
        onDragOver={onDragOver}
        minZoom={0.15}
        data-pw="flow-builder"
      >
        <Background />
        <Controls />
      </ReactFlow>
      <IconButton
        transition="visibility 0.1s 0.1s, opacity 0.2s "
        style={{
          visibility: isDeleteBtnVisible ? "visible" : "hidden",
          opacity: isDeleteBtnVisible ? "1" : "0",
        }}
        onMouseEnter={() => {
          setDeleteBtnVisible(true);
        }}
        onMouseLeave={() => {
          setDeleteBtnVisible(false);
        }}
        onClick={onDeleteEdge}
        position="absolute"
        left={deleteBtnPosition.x}
        top={deleteBtnPosition.y}
        icon={<Image src={redTrashIcon} />}
        aria-label={"Delete edge"}
      />
    </>
  );
});
