import { Box, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import s from "./UtmTagsItem.module.scss";
import { BotModel, BotUtmTagModel } from "../../../BotModel";
import { useMobileView, useTView } from "../../../../layout/LayoutHelper/ResolutionHooks";
import { useTranslation } from "react-i18next";
import Dashboard from "../../../../../assets/icons/sidebar/dashboard.svg?react";
import { OverflowEllipsesText } from "../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import DotMenuContainer from "../../../../../UI/atoms/DotMenu/DotMenuContainer";
import RedTrashIcon from "../../../../../assets/icons/trash.svg?react";
import DuplicateIcon from "../../../../../assets/icons/copyIconMenu.svg?react";
import { useAppDispatch } from "../../../../../common/state/store";
import { deleteBotUtmTag } from "../../../BotSlice";
import ClipboardText from "../../../../../UI/atoms/clipboardText/ClipboardText";
import { DeleteModal } from "../../../../../UI/molecules/deleteModal/DeleteModal";
import { notify } from "../../../../../common/notifications/NotificationSlice";
import i18n from "../../../../../common/i18next/i18n";
import { useNavigate } from "react-router-dom";

interface Props {
  item: BotUtmTagModel;
  botInfo: BotModel | undefined;
}
export const UtmTagsItem = ({ item, botInfo }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const cwt = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const isMobile = useMobileView();
  const isTablet = useTView();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const identifierText = () => {
    if (isMobile && i18n.language === "ru") {
      return t("IdentifierMobile");
    }
    return t("Identifier");
  };

  const onDeleteClick = () => {
    dispatch(deleteBotUtmTag({ tagId: item.id, botId: botInfo?.id ?? "" }));
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText(utmLink);
  };

  const deleteModalTitle = `${t("Utm delete title")} "${item.data}"?`;
  const deletModalMessage = t("Utm delete message");

  const utmLink = `https://t.me/${botInfo?.username}?start=${item.data}`;

  return (
    <Box className={s.row}>
      <Box overflow={"hidden"}>
        {isMobile && <Text className={s.mobileColName}>{identifierText()}</Text>}
        <OverflowEllipsesText text={item.data} content={<Text className={s.rowText}>{item.data}</Text>} />
      </Box>
      <Box>
        {isMobile && <Text className={s.mobileColName}>{t("Link")}</Text>}
        <Flex flexDirection={"row"} className={s.type}>
          {isTablet ? (
            <Box position={"relative"} overflow={"hidden"}>
              <Text overflow={"hidden"} whiteSpace={"nowrap"} textOverflow={"ellipsis"}>
                {utmLink}
              </Text>
            </Box>
          ) : (
            <OverflowEllipsesText
              text={utmLink}
              content={<ClipboardText elemIsCopy={<Text>{utmLink}</Text>} textIsCopy={utmLink} color="#3e5e95" />}
            />
          )}
        </Flex>
      </Box>
      <Box>
        {isMobile && <Text className={`${s.mobileColName} ${s.value}`}>{t("Transitions")}</Text>}
        {item.eventsCount}
      </Box>
      <Box>
        {isMobile && <Text className={s.mobileColName}>{t("Unique")}</Text>}
        {item.uniqueEventsCount}
      </Box>
      <Box>
        <DotMenuContainer
          menuItems={[
            {
              MenuItem: {
                icon: <DuplicateIcon width="18px" height="18px" />,
                text: t("Copy UTM tag"),
                clickEvent: e => {
                  onCopyClick();
                  dispatch(notify({ message: `${t("Copied")}!`, type: "success" }));
                  e.stopPropagation();
                },
              },
            },
            {
              MenuItem: {
                icon: <Icon as={Dashboard} boxSize="18px" />,
                text: t("Open in statistics"),
                clickEvent: e => {
                  e.stopPropagation();
                  navigate("/dashboard", { state: { botId: botInfo?.id, botName: botInfo?.name, utmTag: item.data } });
                },
              },
            },
            {
              MenuItem: {
                icon: <RedTrashIcon width="18px" height="18px" />,
                text: cwt("Delete"),
                color: "mainRed",
                clickEvent: e => {
                  onOpen();
                  e.stopPropagation();
                },
              },
            },
          ]}
        />
      </Box>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        onDelete={onDeleteClick}
        itemTitle={deletModalMessage}
        title={deleteModalTitle}
      />
    </Box>
  );
};
