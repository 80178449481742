import { useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Flex,
  Text,
  Heading,
  Tooltip,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverFooter,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CustomVariablesModel,
  FlowActionModel,
  NodeModel,
  NodeValidationProp,
  CalendarFlowActionModel,
} from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import DurationEditor from "../DurationEditor/DurationEditor";

import CrossIcon from "../../../../../assets/icons/cross.svg?react";

import s from "./CalendarNodePopup.module.scss";
import { CustomVariableContextMenu } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenu";
import { CustomVariableContextMenuType } from "../../../../modals/CustomVariableContextMenu/CustomVariableContextMenuModel";
import { Fallback } from "../Fallback";
import WorkingTimeContainer from "./components/WorkingTimeContainer/WorkingTimeContainer";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import {
  createCustomVariable,
  editNode,
  selectCustomVariables,
  selectCustomVariablesWithoutSystem,
} from "../../../FlowBuilderSlice";
import { CustomVariableType } from "../../../../modals/addVariable/AddVariableModel";
import { CustomVariableModel } from "../../../../../common/AppEnums";
import { NodeEditorFooter } from "../NodeEditorFooter/NodeEditorFooter";

interface Props {
  flowAction: FlowActionModel;
  node: NodeModel;
  getFlowListPage: (page: number, filter?: string) => void;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  addCustomVariableText: (variable: CustomVariablesModel, variableMaxLength: number) => void;
  onClose: () => void;
}

export default function CalendarNodePopup(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const w = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const flowAction = props.flowAction as CalendarFlowActionModel;
  const customVariables = useAppSelector(selectCustomVariablesWithoutSystem);
  const dispatch = useAppDispatch();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [intersection, setintersection] = useState(false);
  useEffect(() => {
    const isIntersection = Boolean(
      flowAction?.workingTimes?.some(day =>
        Boolean(
          day?.intervals.some((el, index, arr) => {
            if (index > 0) {
              return el.beginTime < arr[index - 1].endTime;
            } else {
              return null;
            }
          }),
        ),
      ),
    );
    setintersection(isIntersection);
  }, [flowAction]);

  const variables = useAppSelector(selectCustomVariables);

  const chosenCustomVariable = variables?.find(el => {
    const targetCustomVariableId = flowAction.targetCustomVariableId;
    return el.id === targetCustomVariableId;
  });

  const setCustomVariable = (variableId: string) => {
    props.validate({
      ...flowAction,
      targetCustomVariableId: variableId,
    } as CalendarFlowActionModel);
    dispatch(
      editNode({
        ...props.node,
        flowAction: {
          ...flowAction,
          targetCustomVariableId: variableId,
        } as CalendarFlowActionModel,
      }),
    );
  };

  const selectVariable = (variable: CustomVariablesModel) => {
    if (variable.id) {
      setCustomVariable(variable.id);
    }
  };

  const onCreateVariable = (nodeEditorInfo?: unknown) => (newCustomVariable?: CustomVariableModel) => {
    if (newCustomVariable) {
      dispatch(createCustomVariable({ variable: newCustomVariable, nodeEditorInfo: nodeEditorInfo }));
    }
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <DrawerHeader>{t("Calendar")}</DrawerHeader>
          <DrawerBody>
            <Flex mb="12px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("editNodePopup.Calendar title")}
              </Heading>
              <Tooltip
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editNodePopup.Clients will see it in your calendar")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>
            <MessageTextEditor
              variables={variables ?? []}
              text={flowAction.text ?? ""}
              maxLength={500}
              onChange={t =>
                props.onDataChange({
                  ...flowAction,
                  text: t,
                } as CalendarFlowActionModel)
              }
              validate={props.validate}
              flowAction={flowAction}
              addCustomVariableText={props.addCustomVariableText}
              borderRadiusTop="8px"
              maxHeightWithoutScroll={500}
            />
            <Flex mt="24px" mb="16px" alignItems="center">
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("Duration of service")}
              </Heading>
              <Tooltip
                variant="dominoLight"
                placement="right"
                whiteSpace={"pre-wrap"}
                label={t("editNodePopup.Specify how long it will take")}
              >
                <QuestionOutlineIcon ml={"4px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
              </Tooltip>
            </Flex>
            <DurationEditor propsFlowAction={props.flowAction} onDataChange={props.onDataChange} />
            <Popover isOpen={isOpen} onClose={onClose} placement="left">
              <PopoverTrigger>
                <Button
                  onClick={() => {
                    onToggle();
                    if (!flowAction.isAvailable) {
                      props.onDataChange({
                        ...flowAction,
                        isAvailable: true,
                        workingTimes: [
                          {
                            dayOfWeek: "Monday",
                            order: 0,
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Tuesday",
                            order: 1,
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Wednesday",
                            order: 2,
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Thursday",
                            order: 3,
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          {
                            dayOfWeek: "Friday",
                            order: 4,
                            isAvailable: true,
                            intervals: [{ beginTime: "10:00", endTime: "18:00" }],
                          },
                          { dayOfWeek: "Saturday", order: 5, isAvailable: false, intervals: [] },
                          { dayOfWeek: "Sunday", order: 6, isAvailable: false, intervals: [] },
                        ],
                      } as CalendarFlowActionModel);
                    }
                  }}
                  mt="24px"
                  fontSize={"15px"}
                  className={s.addButton}
                  data-pw={"set-schedule"}
                >
                  {t("Set a schedule")}
                </Button>
              </PopoverTrigger>
              <PopoverContent maxW="328px">
                <PopoverArrow />
                <PopoverCloseButton data-pw={"close-button"} />
                <PopoverHeader> {t("Working time")}</PopoverHeader>
                <PopoverBody p="24px 24px 12px 24px">
                  {isOpen && <WorkingTimeContainer onDataChange={props.onDataChange} flowAction={props.flowAction} />}
                </PopoverBody>
                <PopoverFooter pt={0} border="none" height="100px">
                  <Flex flexDir="column" gap="12px" height="100%">
                    <Box opacity={intersection ? 1 : 0} transition="opacity 0.3s ease-in-out" height={intersection ? "auto" : 0}>
                      {intersection ? (
                        <Text className={s.errorTimeText}>{t("editNodePopup.Please, correct the time crossing")}</Text>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Flex justify="space-between" mt="auto">
                      <Button
                        onClick={() => {
                          props.onDataChange({
                            ...flowAction,
                            isAvailable: false,
                            workingTimes: undefined,
                          } as CalendarFlowActionModel);
                        }}
                        variant="dominoOutlineRed"
                        data-pw={"delete-button"}
                      >
                        {w("Delete")}
                      </Button>
                      <Button
                        transition="background-color 0.3s ease-in-out, opacity 0.3s ease-in-out"
                        opacity={intersection ? 0.5 : 1}
                        height={"100%"}
                        onClick={onClose}
                        variant="dominoViolet"
                        isDisabled={intersection}
                        data-pw={"done-button"}
                      >
                        {w("Done")}
                      </Button>
                    </Flex>
                  </Flex>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
            <Box mt="24px">
              <h4>{t("editNodePopup.Save Response to a Custom Field")}</h4>
              <Box mt="8px">
                <CustomVariableContextMenu
                  mt="0px"
                  selectCustomVariable={selectVariable}
                  clearVariable={() => setCustomVariable("")}
                  type={CustomVariableContextMenuType.Dropdown}
                  addVariable={onCreateVariable()}
                  variables={customVariables?.filter(el => el.type === CustomVariableType.DateTime) || []}
                  chosenVariable={chosenCustomVariable}
                  onlyVariableType={CustomVariableType.DateTime}
                />
                {chosenCustomVariable && (
                  <Box position={"absolute"} right={"5px"} bottom={"7px"} onClick={() => setCustomVariable("")}>
                    <CrossIcon opacity={0} cursor={"pointer"} className={s.crossIcon} />
                  </Box>
                )}
              </Box>
            </Box>
            <Fallback
              isNodeFallback
              validate={props.validate}
              flowAction={flowAction}
              dataChange={props.onDataChange}
              maxLength={500}
              text={""}
              onChange={e =>
                props.onDataChange({
                  ...flowAction,
                  fallbackMessage: e,
                } as CalendarFlowActionModel)
              }
            />
          </DrawerBody>
          <DrawerFooter margin={"8px"} padding={"0"}>
            {props.validationInfo.isError && <NodeEditorFooter validationMessage={props.validationInfo.errors[0].message} />}
          </DrawerFooter>
          <Button variant="dominoViolet" w={"auto"} margin={"16px"} onClick={props.onClose} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
    </>
  );
}
