import { Flex, Text, Link, Button, useDisclosure } from "@chakra-ui/react";
import s from "../AddFirstGroupChatContainer.module.scss";
import { useTranslation } from "react-i18next";
import { BotFatherLink } from "../../../../../UI/atoms/botFatherLink/BotFatherLink";
import { InstructionStep } from "../../../../../UI/atoms/instructionStep/InstructionStep";
import { TranslationWithStrongWord } from "../../../../../UI/atoms/textTranslation/TextTranslation";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import { Separator } from "../../../../../UI/atoms/separator/Separator";
import { AccordionUI } from "../../../../../UI/organisms/AccordionUI/AccordionUI";
import { AddGroupNotificationModal } from "./AddGroupNotificationModal";
import { isMacOs } from "react-device-detect";
import { ConfettiGroupChatModal } from "./ConfettiGroupChatModal";
import { useState } from "react";
import { sendNotification } from "../../../GroupChatSlice";
import { selectLinkedAccounts } from "../../../../../common/user/UserSlice";
import { WarningBlock } from "../../../../../UI/atoms/warningBlock/WarningBlock";
import ShareLink from "../../../../../assets/icons/shareLink.svg?react";
import { SuccessfulModal } from "./SuccessfulModal/SuccessfulModal";
import useGA from "../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsAddMacEvents, GroupsAddWinEvents } from "../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export const AddBotStep = () => {
  const groupTranslation = useTranslation("translation", { keyPrefix: "groupChat.addingBot" }).t;
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const currentBot = useAppSelector(selectCurrentBot);
  const notificationModal = useDisclosure();
  const successfulModal = useDisclosure();
  const linkedAccounts = useAppSelector(selectLinkedAccounts);
  const isTelegramAuth = linkedAccounts.length > 0;
  const [isShowConfettiGroupChatModel, setIsShowConfettiGroupChatModel] = useState(
    Boolean(localStorage.getItem("isShowConfettiGroupChatModel")),
  );
  const dispatch = useAppDispatch();
  const trackEvent = useGA(EventCategories.Group);
  const showIsMac = isMacOs;

  return (
    <>
      {!showIsMac && (
        <Link
          className={s.link}
          isExternal
          href={`https://t.me/${currentBot?.username}?startgroup&admin=change_info+post_messages+edit_messages+delete_messages+restrict_members+invite_users+
pin_messages+manage_topics+promote_members+manage_chat`}
          w="100%"
          variant="dominoViolet"
          onClick={() => trackEvent(GroupsAddWinEvents.GroupsAddWinSelectGroup)}
        >
          {groupTranslation("Select a group for Telegram")}
        </Link>
      )}
      <Flex w="100%" flexDirection="column" gap="12px">
        <Flex flexDirection="column" w="100%" gap="12px">
          {!showIsMac && (
            <Separator>
              <Text variant="medium" px="8px">
                {commonWords("or")}
              </Text>
            </Separator>
          )}
          <Flex gap="24px" flexDirection="column">
            <Button
              leftIcon={showIsMac ? <ShareLink /> : <></>}
              iconSpacing={showIsMac ? "4px" : "0px"}
              onClick={() => {
                if (!showIsMac) trackEvent(GroupsAddWinEvents.GroupsAddWinSendLink);
                if (!isTelegramAuth) {
                  notificationModal.onOpen();
                  trackEvent(GroupsAddMacEvents.GroupsSendALinkeToTelegramMac);
                } else {
                  if (currentBot?.username) {
                    dispatch(sendNotification(currentBot?.username));
                    if (showIsMac) {
                      successfulModal.onOpen();
                      trackEvent(GroupsAddMacEvents.GroupsAddMacModal);
                    }
                  }
                }
              }}
              w="100%"
              variant={showIsMac ? "dominoViolet" : "dominoOutlineViolet"}
            >
              {groupTranslation("Send a link to Telegram")}
            </Button>
          </Flex>
        </Flex>
        {showIsMac && (
          <Flex w="100%" flexDirection="column" gap="12px">
            <AccordionUI
              showTitle={groupTranslation("Instructions for self-connection")}
              hiddenTitle={groupTranslation("Instructions for self-connection")}
              items={[
                <InstructionStep key={1} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={1}>
                  <TranslationWithStrongWord i18nKey="groupChat.addingBot.First step">
                    <Text as="span" className={s.strongWord} />
                  </TranslationWithStrongWord>
                </InstructionStep>,
                <InstructionStep key={2} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={2}>
                  <TranslationWithStrongWord i18nKey="groupChat.addingBot.Second step">
                    <Text as="span" className={s.strongWord} />
                  </TranslationWithStrongWord>
                </InstructionStep>,
                <InstructionStep key={3} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={3}>
                  <TranslationWithStrongWord i18nKey="groupChat.addingBot.Third step">
                    <Text as="span" className={s.strongWord} />
                  </TranslationWithStrongWord>
                </InstructionStep>,
                <InstructionStep key={4} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={4}>
                  <TranslationWithStrongWord i18nKey="groupChat.addingBot.Fourth step">
                    <Text as="span" className={s.strongWord} />
                  </TranslationWithStrongWord>
                </InstructionStep>,
              ]}
              onOpen={() => trackEvent(GroupsAddMacEvents.GroupsAddMacInstractionsVectorOpen)}
            />
          </Flex>
        )}
      </Flex>
      <Flex w="100%" flexDirection="column" gap="12px">
        <WarningBlock>
          <Text variant="medium">
            {groupTranslation("If the bot isn't working in the group, make sure it has all the necessary admin rights")}
          </Text>
        </WarningBlock>
        <AccordionUI
          showTitle={groupTranslation("Bot Permission Settings")}
          hiddenTitle={groupTranslation("Bot Permission Settings")}
          items={[
            <InstructionStep key={1} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={1}>
              <BotFatherLink i18nKey="groupChat.settingBot.First step" />
            </InstructionStep>,
            <InstructionStep key={2} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={2}>
              <TranslationWithStrongWord i18nKey="groupChat.settingBot.Second step">
                <Text as="span" className={s.strongWord} />
              </TranslationWithStrongWord>
            </InstructionStep>,
            <InstructionStep key={3} flexProps={{ gap: "8px", alignItems: "baseline" }} stepNumber={3}>
              <TranslationWithStrongWord i18nKey="groupChat.settingBot.Third step">
                <Text as="span" className={s.strongWord} />
              </TranslationWithStrongWord>
            </InstructionStep>,
          ]}
          onOpen={() =>
            trackEvent(
              showIsMac
                ? GroupsAddMacEvents.GroupsAddMacPermissionsVectorOpen
                : GroupsAddWinEvents.GroupsAddWinPermissionsVectorOpen,
            )
          }
        />
      </Flex>
      <AddGroupNotificationModal isOpen={notificationModal.isOpen} onClose={notificationModal.onClose} />
      <ConfettiGroupChatModal isOpen={!isShowConfettiGroupChatModel} onClose={setIsShowConfettiGroupChatModel} />
      <SuccessfulModal isOpen={successfulModal.isOpen} onClose={successfulModal.onClose} />
    </>
  );
};
