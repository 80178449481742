import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { Box, Icon, Text, Flex } from "@chakra-ui/react";
import zeroTotal from "../../../../../assets/icons/statistic.svg?react";
import { useTranslation } from "react-i18next";
import {
  DashboardFilterCheckboxTypeModel,
  DataMessagesStatisticModel,
  DataMessagesModel,
  DataUTMStatisticModel,
} from "../../../DashboardModel";
import s from "../../../DashboardContainer.module.scss";

interface Props {
  origins: DashboardFilterCheckboxTypeModel[];
  dataChart?: DataMessagesModel[] | DataMessagesStatisticModel[] | DataUTMStatisticModel[];
}

export default function DashboardZeroTotalChart({ origins, dataChart }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });

  return (
    <Box className={s.flexZeroContainerDashboard}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart className={s.dashboardItem} data={dataChart} margin={{ top: 38, right: 56, left: 20, bottom: 12 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickMargin={8} dataKey="name" />
          <YAxis type="number" domain={[0, 4]} />
          {origins.map((item, index) => (
            <Line key={"name"} dot={{ r: 0 }} type="monotone" dataKey={item.label} stroke={"black"} />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <Flex className={s.flexZeroTotal}>
        <Flex maxWidth={"270px"} flexDirection={"column"} alignItems={"center"}>
          <Icon boxSize={"38%"} as={zeroTotal} />
          <Text className={s.textZeroTotal} variant={"textMediumVariant"}>
            {t("No data available. Please choose another time period.")}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
