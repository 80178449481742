import { ProtectedHeaderContainer } from "../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Box, Flex } from "@chakra-ui/react";
import { useAppSelector } from "../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../organisation/OrganisationSlice";
import { HEADER, SUB_HEADER, TRIAL_HEIGHT } from "../../LayoutHelper/ResolutionConst";
import { useBrowserFitContent, useMobileView, useTabletView } from "../../LayoutHelper/ResolutionHooks";
import { AIAssistantListHeader } from "../../../aiAssistant/components/aiAssistantListHeader/AIAssistantListHeader";
import { AIAssistantListContainer } from "../../../aiAssistant/AIAssistantListContainer";
import s from "./AIAssistantPage.module.scss";
import { useEffect, useState } from "react";

export const AIAssistantPage = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);

  const browserFill = useBrowserFitContent();
  const isTablet = useTabletView();
  const isMobile = useMobileView();
  const [tabletView, setTabletView] = useState(isTablet);

  const calculateContainerHeight = () => {
    let height = window.innerHeight - HEADER - SUB_HEADER;

    if (isMobile && isTrial) {
      height -= TRIAL_HEIGHT;
    }

    return height;
  };

  useEffect(() => {
    if (isTablet !== tabletView) {
      setTabletView(isTablet);
    }
  }, [tabletView, isTablet]);
  return (
    <>
      <Flex className={s.aiPageContainer}>
        <ProtectedHeaderContainer>
          <AIAssistantListHeader />
        </ProtectedHeaderContainer>
        <Box bg="bgLight" height={tabletView ? calculateContainerHeight() : browserFill}>
          <AIAssistantListContainer />
        </Box>
      </Flex>
    </>
  );
};
