import s from "./QuestionMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { useTranslation } from "react-i18next";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import MessageIcon from "../../../../../assets/icons/sidebar/chatIcon.svg?react";
import { Icon, Flex } from "@chakra-ui/react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import WidgetFill from "../../../../../assets/icons/dialogFill.svg?react";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";

function QuestionMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        {currentBot?.channel === "Web" ? (
          <Flex h="32px" w="32px" rounded="full" bg="mainPurple" align="center" justify="center">
            <Icon as={WidgetFill} color="white" boxSize="20px" />
          </Flex>
        ) : (
          <Icon as={TelegramIcon} boxSize="32px" />
        )}
        <div className={s.textBlock}>
          <div className={s.telegram}>{currentBot?.channel === "Web" ? t("Widget") : t("Telegram")}</div>
          <div className={s.type}>{t("Question Message")}</div>
        </div>
      </div>
      <MessageButtonBlock actionData={props.actionData} />
      <div className={s.replyWaitingBlock}>
        <Icon as={MessageIcon} boxSize="22px" fill="mainPurple" />
        <div className={s.text}>{t("Waiting for a reply from contact")}...</div>
      </div>
    </Flex>
  );
}

export default QuestionMessageNode;
