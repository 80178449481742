import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import CopyFillIcon from "../../../../assets/icons/copyFlatten.svg?react";
import { useTranslation } from "react-i18next";
import { WarningBlock } from "../../../../UI/atoms/warningBlock/WarningBlock";
import ButtonCopy from "../../../../UI/atoms/buttonCopy/ButtonCopy";
import s from "../../FlowBuilder.module.scss";

export default function SaveFlowWebBotModal({
  isOpen,
  onClose,
  script,
}: {
  isOpen: boolean;
  onClose: () => void;
  script: string;
}) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
        p="32px"
        maxW="460px"
        borderRadius="12px"
      >
        <ModalHeader p="0 0 16px 0">
          <Text variant="h1">{t("Install the widget code on the website")}</Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody p={0} zIndex="140000" overflowY={"auto"}>
          <Flex gap="12px" flexDirection="column">
            <Text lineHeight="20px" variant="large">
              {t(
                "To ensure the bot works correctly, make sure this code is added to every page of your website before the closing",
              )}
              <Text as="span" className={s.strongWord}>
                {" </body> "}
              </Text>
              {t("tag")}
            </Text>

            <WarningBlock>
              <Text variant="medium">{script}</Text>
            </WarningBlock>
          </Flex>
        </ModalBody>
        <ModalFooter p="32px 0 0 0" justifyContent="space-between">
          <Button onClick={onClose} variant="dominoOutlineViolet">
            {commonWords("Close")}
          </Button>
          <ButtonCopy copyText={script} iconSpacing="4px" leftIcon={<CopyFillIcon />} variant="dominoViolet">
            {commonWords("Copy code")}
          </ButtonCopy>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
