import { FlowActionModel, NodeModel, NodeValidationProp, AiFlowActionModel } from "../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Checkbox,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomVariableModel, TariffPlanFeatureTypeEnum } from "../../../../../common/AppEnums";
import { MessageTextEditor } from "../MessageTextEditor/MessageTextEditor";
import { ButtonModel, ReplyKeyboardButtonModel } from "../../../../../common/AppButtonsModel";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCustomVariables } from "../../../FlowBuilderSlice";

import questionNodeIcon from "../../../../../assets/icons/questionNodeIcon.svg?react";
import CircleErrorIcon from "../../../../../assets/icons/cancelIcon.svg?react";
import CircleCheckIcon from "../../../../../assets/icons/circleCheckIcon.svg?react";

import s from "./AINodePopup.module.scss";
import { useState } from "react";
import { Select } from "../../../../../UI/atoms/Select/Select";
import { Separator } from "../../../../../UI/atoms/separator/Separator";
import { ButtonInput } from "../../../../../UI/atoms/buttonInput/ButtonInput";
import { selectAIAssistantList } from "../../../../aiAssistant/AIAssistantListSlice";
import { NewAIAssistantModal } from "../../../../aiAssistant/components/newAIAssistantModal/NewAIAssistantModal";
import { getTariffPlanAccessability } from "../../../../../common/tariffPlan/TariffPlanUtil";
import { selectTariffPlan } from "../../../../../common/tariffPlan/TariffPlanSlice";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";

interface Props {
  flowAction: FlowActionModel;
  node: NodeModel;
  onDataChange: (flowAction: FlowActionModel) => void;
  validate: (flowAction: FlowActionModel) => void;
  validationInfo: NodeValidationProp;
  addCustomVariableText: (variable: CustomVariableModel, variableMaxLength: number) => void;
  onClose: () => void;
  addButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
  setButtons: (buttons: ButtonModel[][] | ReplyKeyboardButtonModel[][]) => void;
  deleteButton: (buttonId: string) => void;
  editButton: (button: ButtonModel | ReplyKeyboardButtonModel) => void;
}

export default function AINodePopup(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const flowAction = props.flowAction as AiFlowActionModel;
  const [isOpenQuestionHeader, setIsOpenQuestionHeader] = useState(false);
  const { isOpen: isNewAIAssistantModalOpen, onOpen: onNewAIAssistant, onClose: onNewAIAssistantModalClose } = useDisclosure();

  const variables = useAppSelector(selectCustomVariables);
  const aiAssistantList = useAppSelector(selectAIAssistantList);
  const optionsName = aiAssistantList?.map(el => el.name);
  const aiAssistantsCount = aiAssistantList?.length;
  const tariffPlans = useAppSelector(selectTariffPlan);
  const accessability = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.AiAgentCount, tariffPlans);
  const { MaxCount, IsUnlimited } = accessability;
  const isOverLimit = !IsUnlimited && MaxCount === aiAssistantsCount; // use only if fix BE
  const loadingCreateAIAssistant = useGetLoadingState("createAIAssistant");

  const onChangeAssistant = (assistantName: string) => {
    props.onDataChange({
      ...flowAction,
      assistantId: aiAssistantList?.find(el => el.name === assistantName)?.id,
      assistantName: assistantName,
    } as AiFlowActionModel);
  };

  return (
    <>
      <Drawer isOpen={true} placement="right" onClose={props.onClose} variant="dominoDrawer">
        <DrawerContent>
          <Flex h="44px" gap="8px">
            <DrawerHeader flex={1}>{t("AI Assistant")}</DrawerHeader>
            <Popover placement="bottom-end" trigger="hover">
              <PopoverTrigger>
                <DrawerHeader
                  onMouseEnter={() => setIsOpenQuestionHeader(true)}
                  onMouseLeave={() => setIsOpenQuestionHeader(false)}
                  cursor="pointer"
                  className={`${s.questionNodeIconBox} ${isOpenQuestionHeader && s.questionNodeIconBoxActive}`}
                >
                  <Icon className={s.questionNodeIcon} as={questionNodeIcon} boxSize="24px" />
                </DrawerHeader>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                <PopoverBody bg="#FEF6DC">
                  <Flex fontSize="13px" lineHeight="16px" flexDirection="column">
                    <Text>
                      {t(
                        "editNodePopup.Add an AI assistant to the flow. Preload questions and answers so it can recognize queries and respond based on your file.",
                      )}
                    </Text>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
          <DrawerBody flexDirection="column" display="flex" gap="24px">
            <Flex flexDirection="column" gap="12px">
              <Text>{t("Select AI Assistant")}</Text>
              <Select
                options={optionsName || []}
                isDisabled={!optionsName?.length}
                onChange={onChangeAssistant}
                value={flowAction.assistantName || aiAssistantList?.find(el => el.id === flowAction.assistantId)?.name || ""}
                placeholder={!optionsName?.length ? t("No AI assistants") : commonWords("Select")}
                canBeCleaned
              ></Select>
              {!isOverLimit && (
                <Flex gap="8px" flexDirection="column">
                  <Flex align="center" justify="center">
                    <Separator width="50%">
                      <Text variant="medium" px="8px">
                        {commonWords("or")}
                      </Text>
                    </Separator>
                  </Flex>
                  <Button
                    isLoading={loadingCreateAIAssistant}
                    spinnerPlacement="end"
                    loadingText={t("+ Add Assistant")}
                    onClick={onNewAIAssistant}
                    variant="dominoOutlineViolet"
                  >
                    {t("+ Add Assistant")}
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex gap="12px" flexDirection="column">
              <Text>{t("Welcome message")}</Text>
              <MessageTextEditor
                variables={variables || []}
                TextAreaProps={{ minH: "60px" }}
                text={flowAction.welcomeMessageText ?? ""}
                maxLength={500}
                onChange={t =>
                  props.onDataChange({
                    ...flowAction,
                    welcomeMessageText: t,
                  } as AiFlowActionModel)
                }
                validate={props.validate}
                isEmojiDisabled
                isFormattingDisabled
                isNoVariablesButton
                flowAction={flowAction}
                addCustomVariableText={props.addCustomVariableText}
                borderRadiusTop="8px"
                borderRadiusBottom="8px"
                maxHeightWithoutScroll={500}
              />
            </Flex>
            <Flex gap="12px" flexDirection="column">
              <Text>{t("Final question")}</Text>
              <MessageTextEditor
                variables={variables || []}
                TextAreaProps={{ minH: "60px" }}
                text={flowAction.closingOptions.closingQuestionText ?? ""}
                maxLength={500}
                onChange={t =>
                  props.onDataChange({
                    ...flowAction,
                    closingOptions: {
                      ...flowAction.closingOptions,
                      closingQuestionText: t,
                    },
                  } as AiFlowActionModel)
                }
                validate={props.validate}
                flowAction={flowAction}
                isEmojiDisabled
                isFormattingDisabled
                isNoVariablesButton
                addCustomVariableText={props.addCustomVariableText}
                borderRadiusTop="8px"
                borderRadiusBottom="8px"
                maxHeightWithoutScroll={500}
              />
              <Flex flexDirection="column" w="100%" gap="8px">
                <ButtonInput
                  value={flowAction.closingOptions.successButtonText || ""}
                  leftIcon={<Icon className={s.questionNodeIcon} as={CircleCheckIcon} boxSize="24px" />}
                  onChange={t =>
                    props.onDataChange({
                      ...flowAction,
                      closingOptions: {
                        ...flowAction.closingOptions,
                        successButtonText: t,
                      },
                    } as AiFlowActionModel)
                  }
                />
                <ButtonInput
                  value={flowAction.closingOptions.failureButtonText || ""}
                  leftIcon={<Icon className={s.questionNodeIcon} as={CircleErrorIcon} boxSize="24px" />}
                  onChange={t =>
                    props.onDataChange({
                      ...flowAction,
                      closingOptions: {
                        ...flowAction.closingOptions,
                        failureButtonText: t,
                      },
                    } as AiFlowActionModel)
                  }
                />
              </Flex>
            </Flex>
            <Flex flexDirection="column">
              <Checkbox
                data-pw="contact-list-select-all"
                cursor="pointer"
                isChecked={flowAction.operatorOptions.isRequestOperatorEnabled}
                onChange={e => {
                  props.onDataChange({
                    ...flowAction,
                    operatorOptions: {
                      ...flowAction.operatorOptions,
                      isRequestOperatorEnabled: e.target.checked,
                    },
                  } as AiFlowActionModel);
                }}
                variant="dominoGreen"
                p="4px 0 4px"
                mx="4px"
              >
                <Text color="black">{t("Call an operator on request")}</Text>
              </Checkbox>
              {flowAction.operatorOptions.isRequestOperatorEnabled && (
                <MessageTextEditor
                  variables={variables || []}
                  TextAreaProps={{ minH: "60px" }}
                  text={flowAction.operatorOptions.operatorRequestedMessageText ?? ""}
                  maxLength={500}
                  onChange={t =>
                    props.onDataChange({
                      ...flowAction,
                      operatorOptions: {
                        ...flowAction.operatorOptions,
                        operatorRequestedMessageText: t,
                      },
                    } as AiFlowActionModel)
                  }
                  validate={props.validate}
                  isEmojiDisabled
                  isFormattingDisabled
                  isNoVariablesButton
                  flowAction={flowAction}
                  addCustomVariableText={props.addCustomVariableText}
                  borderRadiusTop="8px"
                  borderRadiusBottom="8px"
                  maxHeightWithoutScroll={500}
                />
              )}
            </Flex>
          </DrawerBody>
          <Button variant="dominoViolet" w={"auto"} margin={"24px 16px 16px 16px"} onClick={props.onClose} data-pw="apply-button">
            {t("Apply")}
          </Button>
        </DrawerContent>
      </Drawer>
      <NewAIAssistantModal newAIAssistantState={isNewAIAssistantModalOpen} onClose={onNewAIAssistantModalClose} />
    </>
  );
}
