import { Flex, Text, Button, Box, PlacementWithLogical, CloseButton } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import s from "./ComplexFilter.module.scss";
import { mapFilterArrayToQueryString } from "./ComplexFilterMapper";
import {
  ComplexFilterFieldTypes,
  ComplexFilterItemsModel,
  ConditionsByField,
  FieldParamModel,
  FilterParams,
  FilterParamTypes,
  MenuButtonTypes,
} from "./ComplexFilterModel";
import { FieldToFilterMenu } from "./components/FieldToFilterMenu/FieldToFilterMenu";
import { ConditionBox } from "./components/ConditionBox";
import { v1 as uuidv1 } from "uuid";
import { RootState, useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectBroadcastState, setBroadcastRecipients } from "../broadcast/BroadcastSlice";
import { useTranslation } from "react-i18next";
import { selectImportResult } from "../contactList/ContactListSlice";

interface Props {
  filterFields: ComplexFilterItemsModel;
  getItemsWithFilter: (
    queryFilterString: string | undefined,
    queryParamsString: string | undefined,
    isFilterActive: boolean,
  ) => void;
  classNameIcon?: string;
  placement?: PlacementWithLogical;
  isActive: boolean;
  setIsActive?: Dispatch<SetStateAction<boolean>>;
  componentSlice?: string;
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
  contactListQueryString?: string;
  setContactListQueryString?: (contactListQueryString: string) => void;
  componentClassName?: string;
  setWasSelectAllChecked?: Dispatch<boolean> | Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page?: string;
  size?: number;
}

export const ComplexFilter = ({ componentClassName, ...props }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "complexFilter" });
  const [filterParams, setFilterParams] = useState<FilterParams[]>([{}]);
  const importResult = useAppSelector(selectImportResult);
  const recipientList = useAppSelector((state: RootState) => state.app.broadcastState.recipientList);
  const filter = sessionStorage.getItem("ContactsFilter");

  //Only valid and full filters are cached to compare it with updated filter params
  const [caсhedFilterParams, setCaсhedFilterParams] = useState<FilterParams[]>([]);
  const broadcast = useAppSelector(selectBroadcastState).broadcast;
  const dispatch = useAppDispatch();
  const [isOpenFilters, setIsOpenFilters] = useState(true);

  useEffect(() => {
    if (filter === null && props.setContactListQueryString) {
      props.setContactListQueryString("");
    } else if (props.componentSlice === "ContactList" && filter !== null && props.contactListQueryString) {
      setFilterParams(JSON.parse(filter));
      setIsOpenFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sessionStorage.setItem("ContactsFilter", JSON.stringify(filterParams));
  }, [filterParams]);

  useEffect(() => {
    if (importResult && props.componentSlice !== "ContactList") {
      onClearFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importResult]);

  const addFilter = () => {
    const validFilters = filterParams.filter(el => isValidFilter(el));

    // Check if valid and full filters have changed or added
    if (validFilters.length && JSON.stringify(validFilters) !== JSON.stringify(caсhedFilterParams)) {
      setCaсhedFilterParams(validFilters);
      const queryString = mapFilterArrayToQueryString(validFilters);
      props.getItemsWithFilter(queryString, undefined, true);
      if (props.componentSlice === "ContactList" && props.setContactListQueryString) {
        props.setContactListQueryString(queryString);
      }
      if (props.setBroadcastFilter) {
        props.setBroadcastFilter([...validFilters]);
      }
      if (props.setIsActive) {
        props.setIsActive(true);
      }
    }
  };
  useEffect(() => {
    if (
      broadcast &&
      broadcast.recipientFilter &&
      broadcast.recipientFilter.length > 0 &&
      props.broadcastRecipientsFilters &&
      props.broadcastRecipientsFilters.length
    ) {
      setFilterParams(props.broadcastRecipientsFilters);
    }
  }, [broadcast, broadcast?.recipientFilter?.length, props.broadcastRecipientsFilters]);

  useEffect(() => {
    addFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const onSetFilterParam = (filterParamType: FilterParamTypes, fieldParamValue: FieldParamModel, id: string) => {
    setFilterParams(prevState =>
      prevState.map(el => {
        if (el.id === id || !el.id) {
          if (filterParamType === FilterParamTypes.field && el.field?.type !== fieldParamValue.type) {
            return {
              id,
              [filterParamType]: fieldParamValue,
            };
          }
          return {
            ...el,
            [filterParamType]: fieldParamValue,
          };
        }
        return el;
      }),
    );
  };

  const addCondition = () => {
    setFilterParams([...filterParams, {}]);
    sessionStorage.setItem("ContactsFilter", JSON.stringify([...filterParams, {}]));
  };

  const onClearFilter = () => {
    setFilterParams([{}]);
    sessionStorage.removeItem("ContactsFilter");

    if (props.setBroadcastFilter && props.setWasSelectAllChecked) {
      props.setBroadcastFilter([{}]);
      dispatch(setBroadcastRecipients([]));
      props.setWasSelectAllChecked(false);
    }
    if (props.componentSlice === "ContactList" && props.setContactListQueryString) {
      props.setContactListQueryString("");
    }
    setCaсhedFilterParams([]);
    props.getItemsWithFilter("", undefined, false);
    if (props.setIsActive) {
      props.setIsActive(false);
    }
  };

  /* This function restricts working with arrays. The function can be removed after fixing the DD-1612 task on BE */
  const restrictType = () => {
    props.filterFields.groups.forEach(el => {
      el.items = el.items.filter(el => el.type !== ComplexFilterFieldTypes.Array);
    });

    return props.filterFields;
  };

  const onDeleteConditionBlock = (paramsToDelete: FilterParams) => {
    const params = filterParams.filter(el => JSON.stringify(el) !== JSON.stringify(paramsToDelete));
    setFilterParams(params);
    sessionStorage.setItem("ContactsFilter", JSON.stringify(params));
    if (props.setBroadcastFilter) {
      props.setBroadcastFilter(params);
    }
  };

  const isValidFilter = (filter: FilterParams) => {
    if (
      (filter.conditionValue && filter.condition && filter.field) ||
      (filter.field && filter.condition && !isConditionValueNeeded(filter.condition.value))
    ) {
      return true;
    }
    return false;
  };

  const isConditionValueNeeded = (conditionValue: string) => {
    return !ConditionsByField.noValueConditions.includes(conditionValue);
  };

  return (
    <Flex w="100%" position={"relative"}>
      <Box className={props.componentSlice === "ContactList" ? `FieldsFilterMenuModal` : props.classNameIcon}>
        <FieldToFilterMenu
          filterFields={restrictType()}
          onSetFilterParam={onSetFilterParam}
          filterParamType={FilterParamTypes.field}
          menuButtonProps={{
            buttonType: MenuButtonTypes.Icon,
            isMenuButtonDisabled: recipientList?.availableRecipients
              ? true
              : props.componentSlice === "ContactList" && !!filterParams[0]?.field
              ? false
              : !!filterParams[0]?.field,
          }}
          placement={props.placement}
          id={uuidv1()}
          defaultIsOpen={props.componentSlice === "ContactList" ? true : false}
          isOpenModalFilters={isOpenFilters}
          setIsOpenModalFilters={setIsOpenFilters}
          filterParams={filterParams}
          componentSlice={props.componentSlice}
        />
      </Box>
      {!recipientList?.availableRecipients ? (
        !!filterParams[0]?.field &&
        props.componentSlice !== "ContactList" && (
          <Flex flexDir="column" className={componentClassName ? `${s.wrapper} ${s[`${componentClassName}`]}` : `${s.wrapper}`}>
            <Flex justifyContent="space-between" alignItems="center" className={s.filterInfoBlock}>
              <Text fontSize="18px">{t("Applied filters")}:</Text>
              <Button onClick={onClearFilter} size="sm" variant="dominoOutlineRed" data-pw="clear-filter-button">
                {t("Clear Filter")}
              </Button>
            </Flex>
            <Text className={s.filterInfoText} color="darkGrey" pt="8px">
              {t("Only contacts")}
            </Text>
            <ConditionBox
              isVariablesToCompareWithForbidden={true}
              filterFields={props.filterFields}
              filterParams={filterParams}
              onDeleteConditionBlock={onDeleteConditionBlock}
              onSetFilterParam={onSetFilterParam}
              addCondition={addCondition}
              componentSlice={props.componentSlice}
            />
          </Flex>
        )
      ) : (
        <></>
      )}
      {!!filterParams[0]?.field && props.componentSlice === "ContactList" && isOpenFilters && (
        <Flex flexDir="column" className={componentClassName ? `${s.wrapper} ${s[`${componentClassName}`]}` : `${s.wrapper}`}>
          <CloseButton
            onClick={() => setIsOpenFilters(false)}
            className={s.CloseButton}
            position={"absolute"}
            top={"4px"}
          ></CloseButton>
          <ConditionBox
            isVariablesToCompareWithForbidden={true}
            filterFields={props.filterFields}
            filterParams={filterParams}
            onDeleteConditionBlock={onDeleteConditionBlock}
            onSetFilterParam={onSetFilterParam}
            addCondition={addCondition}
          />
          <Button
            onClick={onClearFilter}
            w="calc(100% - 15px)"
            variant="dominoOutlineRed"
            data-pw="clear-filter-button"
            marginTop={"10px"}
          >
            {t("Clear Filter")}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
