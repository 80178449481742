import { PayloadAction } from "@reduxjs/toolkit";
import {
  NewTeamModel,
  OrganisationModel,
  OrganisationTeamItemModel,
  OrganisationTeamsModel,
  OrganisationUsersModel,
} from "../OrganisationModel";
import { initialState, OrganisationState } from "../OrganisationSlice";
import { TrialPlanModel } from "../components/TrialPlan/TrialPlanModel";

const organizationReducer = {
  getOrganisation: (state: OrganisationState) => {
    return {
      ...state,
    };
  },
  getOrganisationCompleted: (state: OrganisationState, action: PayloadAction<OrganisationModel>) => {
    return {
      ...state,
      organisation: action.payload,
    };
  },
  createOrganisation: (state: OrganisationState, action: PayloadAction<OrganisationModel>) => {
    return {
      ...state,
    };
  },
  getOrganisationUsers: (
    state: OrganisationState,
    action: PayloadAction<{ page: number; filter?: string; forModal?: boolean }>,
  ) => {
    return {
      ...state,
    };
  },
  getOrganisationUsersCompleted: (state: OrganisationState, action: PayloadAction<OrganisationUsersModel>) => {
    return {
      ...state,
      organisationUsers: action.payload,
    };
  },
  getModalOrganisationUsersCompleted: (state: OrganisationState, action: PayloadAction<OrganisationUsersModel>) => {
    return {
      ...state,
      organisationUsers: {
        ...state.organisationUsers,
        items: [...(state.organisationUsers?.items || []), ...action.payload.items],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
      },
    };
  },
  clearOrganisationUsers: (state: OrganisationState) => {
    return {
      ...state,
      organisationUsers: {
        items: [],
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
      },
    };
  },
  clearOrganisationTeams: (state: OrganisationState) => {
    return {
      ...state,
      organisationsTeams: {
        items: [],
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
      },
    };
  },
  getOrganisationTeams: (state: OrganisationState, action: PayloadAction<{ page: number }>) => {
    return {
      ...state,
    };
  },
  getOrganisationTeamsCompleted: (state: OrganisationState, action: PayloadAction<OrganisationTeamsModel>) => {
    return {
      ...state,
      organisationsTeams: action.payload,
    };
  },
  generateInviteToken: (state: OrganisationState) => {
    return {
      ...state,
    };
  },
  setInviteToken: (state: OrganisationState, action: PayloadAction<{ inviteToken: string }>) => {
    return {
      ...state,
      inviteToken: action.payload.inviteToken,
    };
  },
  clearInviteToken: (state: OrganisationState) => {
    return {
      ...state,
      inviteToken: undefined,
    };
  },
  updateOrganisation: (state: OrganisationState, action: PayloadAction<OrganisationModel>) => {
    return {
      ...state,
    };
  },
  deleteUserFromOrganisation: (state: OrganisationState, action: PayloadAction<{ userId: string }>) => {
    return {
      ...state,
    };
  },
  clearOrganisationState: (state: OrganisationState) => {
    return {
      ...initialState,
    };
  },
  createOrganisationTeam: (state: OrganisationState, action: PayloadAction<NewTeamModel>) => {
    return {
      ...state,
    };
  },
  editOrganisationTeam: (state: OrganisationState, action: PayloadAction<OrganisationTeamItemModel>) => {
    return {
      ...state,
    };
  },
  deleteOrganisationTeam: (state: OrganisationState, action: PayloadAction<{ teamId: string }>) => {
    return {
      ...state,
    };
  },
  deleteTeamOperator: (state: OrganisationState, action: PayloadAction<{ teamId: string; operatorIds: string[] }>) => {
    return {
      ...state,
    };
  },
  addOperatorsToTeam: (state: OrganisationState, action: PayloadAction<{ teamId: string; operatorIds: string[] }>) => {
    return {
      ...state,
    };
  },
  checkIsTeamInUse: (state: OrganisationState, action: PayloadAction<{ teamId: string }>) => {
    return {
      ...state,
    };
  },
  checkIsTeamInUseSucceed: (state: OrganisationState, action: PayloadAction<{ id: string; name: string }[]>) => {
    return {
      ...state,
      organisationTeamUsage: action.payload,
    };
  },
  checkIsOperatorInUse: (state: OrganisationState, action: PayloadAction<{ operatordId: string }>) => {
    return {
      ...state,
    };
  },
  checkIsOperatorInUseSucceed: (state: OrganisationState, action: PayloadAction<{ id: string; name: string }[]>) => {
    return {
      ...state,
      organisationUserUsage: action.payload,
    };
  },
  setOrganization: (state: OrganisationState, action: PayloadAction<string>) => {
    return {
      ...state,
    };
  },
  getTrialPlan: (state: OrganisationState) => {
    return {
      ...state,
    };
  },
  getTrialPlanCompleted: (state: OrganisationState, action: PayloadAction<TrialPlanModel>) => {
    return {
      ...state,
      organizationTrial: action.payload,
      isOrganizationTrial: action.payload.isAvailable,
    };
  },
  activateTrialPlan: (state: OrganisationState, _action: PayloadAction<string>) => {
    return {
      ...state,
    };
  },
  setTrialPlanState: (state: OrganisationState, action: PayloadAction<boolean>) => {
    return {
      ...state,
      isOrganizationTrial: action.payload,
    };
  },
  setIsInitialTrialLoading: (state: OrganisationState, action: PayloadAction<boolean>) => {
    return {
      ...state,
      isInitialTrialLoading: action.payload,
    };
  },
  dropTrialPlanState: (state: OrganisationState) => {
    return {
      ...state,
      organizationTrial: null,
      isOrganizationTrial: false,
      isInitialTrialLoading: false,
    };
  },
};

export default organizationReducer;
