import { Edge, Node } from "reactflow";
import { CustomVariableScope, CustomVariableType } from "../../common/AppEnums";
import { ReplyMarkupModel } from "../../common/AppButtonsModel";
import { PaginatorModel } from "../../common/paginator/PaginatorModel";
import TextIcon from "../../assets/icons/textIcon.svg?react";
import ImageIcon from "../../assets/icons/imageChatIcon.svg?react";
import DocumentIcon from "../../assets/icons/fileIcon.svg?react";
import HTTPIcon from "../../assets/icons/globeIcon.svg?react";
import VideoIcon from "../../assets/icons/videoIcon.svg?react";
import AudioIcon from "../../assets/icons/audioIcon.svg?react";
import ConditionIcon from "../../assets/icons/conditionIcon.svg?react";
import SystemActionsIcon from "../../assets/icons/thunderIcon.svg?react";
import DelayIcon from "../../assets/icons/clockIcon.svg?react";
import QuestionIcon from "../../assets/icons/questionNodeIcon.svg?react";
import AssignIcon from "../../assets/icons/assignIcon.svg?react";
import StartFlowIcon from "../../assets/icons/startFlowIcon.svg?react";
import CalendarIcon from "../../assets/icons/CalendarNodeIcon.svg?react";
import PaymentIcon from "../../assets/icons/PaymentNodeIcon.svg?react";
import CatalogIcon from "../../assets/icons/CatalogNodeIcon.svg?react";
import AIAssistantIcon from "../../assets/icons/sidebar/aiAssistant.svg?react";

export interface FlowModel {
  id: string;
  newFlowId?: string;
  botId: string;
  title?: string;
  nodes: Node[];
  edges: Edge[];
  triggers: TriggerModel[];
  locale?: string;
}

export interface FlowLookModel {
  id: string;
  botId: string;
  title: string;
}

export interface FlowListModel extends PaginatorModel {
  items: { id: string; botId: string; title: string }[];
}

export interface NodeModel {
  id: string;
  type: NodeType;
  flowAction?: FlowActionModel;
  position: {
    x: number;
    y: number;
  };
}

export interface TriggerNodeModel extends NodeModel {
  newTriggerType?: TriggerTypeDiscriminator;
  newVariableId?: string;
  isTriggerPopupOpen?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FlowActionModel {
  replyMarkup?: ReplyMarkupModel;
  flowId?: string;
  title?: string;
  typeDiscriminator: FlowActionType;
}

export interface TextContainerFlowAction extends FlowActionModel {
  text?: string;
}

export interface ButtonsContainerFlowAction extends FlowActionModel {
  replyMarkup?: ReplyMarkupModel;
  isFallback?: boolean;
  retryCount?: number;
  fallbackMessage?: string;
}

export interface CalendarContainerFlowAction extends FlowActionModel {
  replyMarkup?: ReplyMarkupModel;
  isFallback?: boolean;
  retryCount?: number;
  fallbackMessage?: string;
}

export interface FileContainerFlowAction extends FlowActionModel {
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  fileUrlCustomVariableIds?: string[];
}

export interface CustomVariablesContainerFlowAction extends FlowActionModel {
  customVariableIds?: string[];
  fileUrlCustomVariableIds?: string[];
}

export type TargetCustomVariablesModel = {
  contentPath: string;
  targetCustomVariableId: string | null;
}[];

export interface CurlFlowActionModel extends FlowActionModel, CustomVariablesContainerFlowAction {
  curlCommand?: string;
  successNodeId?: string;
  errorNodeId?: string;
  canSaveResponse?: boolean;
  targetCustomVariables?: TargetCustomVariablesModel;
}

export interface SendTextFlowActionModel
  extends FlowActionModel,
    TextContainerFlowAction,
    ButtonsContainerFlowAction,
    CustomVariablesContainerFlowAction {
  text?: string;
  replyMarkup?: ReplyMarkupModel;
  customVariableIds?: string[];
}

export interface QuestionFlowActionModel extends FlowActionModel, CustomVariablesContainerFlowAction {
  text?: string;
  targetCustomVariableId?: string;
  retryCount?: number;
  customVariableIds?: string[];
  questionActionType: QuestionActionType;
  answerMediaVariableId?: string;
}

export interface SendPhotoFlowActionModel
  extends FlowActionModel,
    FileContainerFlowAction,
    TextContainerFlowAction,
    ButtonsContainerFlowAction,
    CustomVariablesContainerFlowAction {
  text?: string;
  replyMarkup?: ReplyMarkupModel;
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  customVariableIds?: string[];
}

export interface SendDocumentFlowActionModel
  extends FlowActionModel,
    FileContainerFlowAction,
    TextContainerFlowAction,
    ButtonsContainerFlowAction {
  text?: string;
  replyMarkup?: ReplyMarkupModel;
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  customVariableIds?: string[];
}

export interface SendVideoFlowActionModel
  extends FlowActionModel,
    FileContainerFlowAction,
    TextContainerFlowAction,
    ButtonsContainerFlowAction {
  text?: string;
  replyMarkup?: ReplyMarkupModel;
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  customVariableIds?: string[];
}

export interface SendAudioFlowActionModel
  extends FlowActionModel,
    FileContainerFlowAction,
    TextContainerFlowAction,
    ButtonsContainerFlowAction {
  text?: string;
  replyMarkup?: ReplyMarkupModel;
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  customVariableIds?: string[];
}

export interface ConditionFlowActionModel extends FlowActionModel {
  conditions: ConditionModel[];
}

export interface ConditionModel {
  id?: string;
  conditionCustomVariableId?: string;
  conditionCustomVariableName?: string;
  condition?: string;
  valueCustomVariableId?: string;
  value?: string;
  targetNodeId?: string;
}

export interface OperatorsModel extends PaginatorModel {
  items: { id: string; name: string }[];
}

export interface TeamsModel extends PaginatorModel {
  items: { id: string; name: string }[];
}

export enum SystemActionTypeDiscriminator {
  SetVariableValueFlowAction = "SetVariableValueFlowAction",
  PutItemIntoArrayFlowAction = "PutItemIntoArrayFlowAction",
  TakeItemFromArrayFlowAction = "TakeItemFromArrayFlowAction",
}

export enum SystemActionNames {
  SetVariableValueFlowAction = "Set variable value",
  PutItemIntoArrayFlowAction = "Put item into array",
  TakeItemFromArrayFlowAction = "Take item from array",
}

export type SystemActionFlowActionModel = SystemActionModel | SetVariableModel | PutItemIntoArrayModel | TakeItemFromArrayModel;

export interface SystemActionModel extends FlowActionModel {
  typeDiscriminator: FlowActionType.SystemActionFlowAction;
  customVariableIds?: string[];
}

export interface SetVariableModel extends FlowActionModel {
  value?: string;
  sourceCustomVariableId?: string;
  targetCustomVariableId?: string;
  typeDiscriminator: FlowActionType.SetVariableValueFlowAction;
  customVariableIds?: string[];
  sourceType?: "variable" | "value";
}

export interface PutItemIntoArrayModel extends FlowActionModel {
  value?: string;
  sourceCustomVariableId?: string;
  targetCustomVariableId?: string;
  typeDiscriminator: FlowActionType.PutItemIntoArrayFlowAction;
  customVariableIds?: string[];
  sourceType?: "variable" | "value";
}

export interface TakeItemFromArrayModel extends FlowActionModel {
  sourceCustomVariableId?: string;
  targetCustomVariables?: { contentPath: string; targetCustomVariableId: string | null }[];
  typeDiscriminator: FlowActionType.TakeItemFromArrayFlowAction;
  customVariableIds?: string[];
}

export interface DelayFlowActionModel extends FlowActionModel {
  value: string;
  unit: TimeUnits;
}

export interface StartFlowActionModel extends FlowActionModel {
  flowId: string;
}

export interface CalendarFlowActionModel extends FlowActionModel {
  text?: string;
  targetCustomVariableId?: string;
  gap?: { hours: number; minutes: number };
  workingTimes?: WorkingTimeModel[];
  isFallback?: boolean;
  retryCount?: number;
  isAvailable?: boolean;
  fallbackMessage?: string;
  customVariableIds?: string[];
}

export interface PaymentFlowActionModel extends FlowActionModel {
  text?: string;
  description?: string;
  targetCustomVariablesIds?: string[];
  file?: File;
  fileId?: string;
  fileUrl?: string;
  fileInfo?: FileInfoModel;
  isFallback?: boolean;
  retryCount?: number;
  fallbackMessage?: string;
  details?: string[];
  order: {
    isAll: boolean;
    needName: boolean;
    needPhoneNumber: boolean;
    needEmail: boolean;
    needShippingAddress: boolean;
  };
  accessToken: string;
  fiscalization: boolean;
  paymentProvider: PaymentProviderType;
  price: { amount: string | null; currency: string };
  gap?: { hours: number; minutes: number };
  catalog?: CatalogModel | null;
}

export interface CatalogModel {
  customVariableId: string | null;
}

export interface ShoppingCartFlowActionModel extends FlowActionModel, ButtonsContainerFlowAction {
  text?: string;
  isFallback?: boolean;
  retryCount?: number;
  targetCustomVariableId?: string;
  fallbackMessage?: string;
  successNodeId?: string;
  defaultNodeId?: string;
  targetCustomVariablesIds?: string[];
  replyMarkup?: ReplyMarkupModel;
  fileName?: string;
}

export interface AiFlowActionModel extends FlowActionModel, ButtonsContainerFlowAction {
  assistantId: string;
  assistantName: string;
  welcomeMessageText: string;
  isFallback?: boolean;
  retryCount?: number;
  fallbackMessage?: string;
  operatorOptions: OperatorOptionsModel;
  closingOptions: ClosingOptionsModel;
}

export interface OperatorOptionsModel {
  isRequestOperatorEnabled: boolean;
  operatorRequestedMessageText: string;
  operatorRequestNodeId: string;
}

export interface ClosingOptionsModel {
  closingQuestionText: string;
  successButtonText: string;
  failureButtonText: string;
}

export interface WorkingTimeModel {
  dayOfWeek: string;
  order: number;
  isAvailable: boolean;
  intervals: Array<WorkingTimeInterval>;
  intersection?: boolean;
}

interface WorkingTimeInterval {
  beginTime: string;
  endTime: string;
}

export interface AssignFlowActionModel extends FlowActionModel {
  operatorId: string | null;
  operatorName: string | null;
  teamId: string | null;
  teamName: string | null;
}

export interface NodeValidation {
  nodeId?: string;
  flowId?: string;
  errors: NodeValidationError[];
}

export interface NodeValidationProp extends NodeValidation {
  isError: boolean;
}

export interface NodeValidationError {
  field: string;
  message: string;
}

export interface EdgeModel {
  id: string;
  sourceId: string;
  sourceHandleId?: string;
  targetId: string;
  isDirect?: boolean;
}

export interface ConnectorModel {
  key: string;
  nodeId: string;
}

export interface TriggerModel {
  id: string;
  typeDiscriminator: TriggerTypeDiscriminator;
  keywords?: string[];
  matchScore?: number;
  keywordMatchType?: KeywordMatchType;
  buttonTriggerType?: ButtonTriggerMatchType;
  buttonIds?: string[];
  isEnabled: boolean;
  triggerGroupId: number;
  triggerRefNumber?: string;
  targetCustomVariableId?: string;
  conditions?: ScheduleConditionModel[];
  scheduler?: ScheduleTrigger;
  command?: string;
}

export interface ScheduleConditionModel extends ConditionModel {
  conditionCustomVariableName?: string;
}

export interface ScheduleTrigger {
  typeDiscriminator: ScheduleTriggerTypeDiscriminator;
  scheduledTime: string | null;
  startTime: string | null;
  endTime: string | null;
  unit: TimeUnits;
  value?: string;
}

export interface PostFilePayload {
  file: File;
  nodeId: string;
}

export interface FileInfoModel {
  id: string;
  contentType: string;
  extension: string;
  externalId: string;
  name: string;
}

export enum NodeEventType {
  deleteConfirmationPopup = "deleteConfirmationPopup",
  addVariablePopup = "addVariablePopup",
  addNewTriggerPopup = "addNewTriggerPopup",
}

export type NodeEvent = {
  eventType: "deleteConfirmationPopup" | "addVariablePopup" | "addNewTriggerPopup";
  eventData: {
    [key: string]: unknown;
  };
} | null;

export interface DeleteConfirmationPopupModel {
  title?: string;
  itemTitle?: string;
  onDelete?: () => void;
}

export interface FileInfoModel {
  id: string;
  contentType: string;
  extension: string;
  externalId: string;
  name: string;
}

export interface CustomVariablesModel {
  id?: string;
  containerId: string | null;
  scope: CustomVariableScope;
  key: string;
  type: CustomVariableType;
  description?: string;
}

export enum FlowActionType {
  SendTextFlowAction = "SendTextFlowAction",
  QuestionFlowAction = "QuestionFlowAction",
  SendPhotoFlowAction = "SendPhotoFlowAction",
  SendDocumentFlowAction = "SendDocumentFlowAction",
  CurlFlowAction = "CurlFlowAction",
  SendVideoFlowAction = "SendVideoFlowAction",
  SendAudioFlowAction = "SendAudioFlowAction",

  ConditionFlowAction = "ConditionFlowAction",
  DelayFlowAction = "DelayFlowAction",
  StartSubFlowAction = "StartSubFlowAction",
  SendAppointmentFlowAction = "SendAppointmentFlowAction",
  SendPaymentFlowAction = "SendPaymentFlowAction",
  ShoppingCartFlowAction = "ShoppingCartFlowAction",
  AiFlowAction = "AiFlowAction",

  SystemActionFlowAction = "SystemActionFlowAction",
  SetVariableValueFlowAction = "SetVariableValueFlowAction",
  PutItemIntoArrayFlowAction = "PutItemIntoArrayFlowAction",
  TakeItemFromArrayFlowAction = "TakeItemFromArrayFlowAction",
  AssignFlowAction = "AssignFlowAction",

  CreationMenuAction = "CreationMenuAction",
}

export enum NodeType {
  "Action" = "Action",
  "Trigger" = "Trigger",
}

export enum TriggerTypeDiscriminator {
  "ConversationStart" = "ConversationStartFlowTrigger",
  "KeywordMatch" = "KeywordMatchFlowTrigger",
  "RefUrl" = "TelegramRefUrlFlowTrigger",
  "ButtonClick" = "ButtonClickTrigger",
  "CommandMatch" = "TelegramCommandFlowTrigger",
  "Schedule" = "SchedulerFlowTrigger",
}

export enum KeywordMatchType {
  "Is" = "Is",
  "ContainsWholeWord" = "ContainsWholeWord",
  "Contains" = "Contains",
  "BeginsWith" = "BeginsWith",
  "Like" = "Like",
  "Any" = "Any",
}

export enum keywordMatchTypeName {
  "Is" = "is",
  "Contains" = "contains",
  "ContainsWholeWord" = "contains whole word",
  "BeginsWith" = "begins with",
  "Like" = "like",
  "Any" = "Any",
}

export enum ButtonTriggerMatchType {
  "Is" = "Is",
  "Contains" = "Contains",
  "BeginsWith" = "BeginsWith",
}

export enum ButtonTriggerMatchTypeName {
  "Is" = "is",
  "Contains" = "contains",
  "BeginsWith" = "begins with",
}

export enum TriggerTypeName {
  "ConversationStartFlowTrigger" = "Conversation Start",
  "KeywordMatchFlowTrigger" = "Keyword",
  "TelegramRefUrlFlowTrigger" = "Reference URL",
  "ButtonClickTrigger" = "Button click",
  "TelegramCommandFlowTrigger" = "Command",
  "SchedulerFlowTrigger" = "Schedule",
}

export enum TimeUnits {
  "Seconds" = "Seconds",
  "Minutes" = "Minutes",
  "Hours" = "Hours",
  "Days" = "Days",
}

export enum DaysUnits {
  "Mon" = "Monday",
  "Tue" = "Tuesday",
  "Wed" = "Wednesday",
  "Thu" = "Thursday",
  "Fri" = "Friday",
  "Sat" = "Saturday",
  "Sun" = "Sunday",
}

export enum NodeDefaultSize {
  "width" = 360,
  "height" = 250,
}

export enum MultiHanldeNodeTypeDiscriminator {
  "CurlFlowAction" = "CurlFlowAction",
  "ConditionFlowAction" = "ConditionFlowAction",
  "SendPaymentFlowAction" = "SendPaymentFlowAction",
  "ShoppingCartFlowAction" = "ShoppingCartFlowAction",
  "AiFlowAction" = "AiFlowAction",
}

export enum HandleNegativeTypes {
  "error" = "error",
  "false" = "false",
}

export enum HandlePositiveTypes {
  "ok" = "ok",
  "true" = "true",
  "success-source" = "success-source",
}

export enum ScheduleTriggerTypeDiscriminator {
  "once" = "OneTimeScheduler",
  "recur" = "RecurringScheduler",
}

export enum QuestionActionType {
  "Text" = "Text",
  "Media" = "Media",
}

export enum PaymentProviderType {
  "Unknown" = "Unknown",
  "Yookassa" = "Yookassa",
}

export interface ViewNodeModel {
  title: string;
  icon?: React.ElementType;
  typeDiscriminator: keyof typeof FlowActionType;
}

export const telegramNodes: ViewNodeModel[] = [
  { title: "Text", typeDiscriminator: "SendTextFlowAction", icon: TextIcon },
  { title: "Question", typeDiscriminator: "QuestionFlowAction", icon: QuestionIcon },
  { title: "Image", typeDiscriminator: "SendPhotoFlowAction", icon: ImageIcon },
  { title: "Document", typeDiscriminator: "SendDocumentFlowAction", icon: DocumentIcon },
  { title: "Video", typeDiscriminator: "SendVideoFlowAction", icon: VideoIcon },
  { title: "Audio", typeDiscriminator: "SendAudioFlowAction", icon: AudioIcon },
];
export const extrasNodes: ViewNodeModel[] = [
  { title: "HTTP request", typeDiscriminator: "CurlFlowAction", icon: HTTPIcon },
  { title: "Condition", typeDiscriminator: "ConditionFlowAction", icon: ConditionIcon },
  { title: "System Action", typeDiscriminator: "SystemActionFlowAction", icon: SystemActionsIcon },
  { title: "Delay", typeDiscriminator: "DelayFlowAction", icon: DelayIcon },
  { title: "Assign", typeDiscriminator: "AssignFlowAction", icon: AssignIcon },
  { title: "Start Flow", typeDiscriminator: "StartSubFlowAction", icon: StartFlowIcon },
  { title: "Calendar", typeDiscriminator: "SendAppointmentFlowAction", icon: CalendarIcon },
  { title: "Payment", typeDiscriminator: "SendPaymentFlowAction", icon: PaymentIcon },
  { title: "Catalog", typeDiscriminator: "ShoppingCartFlowAction", icon: CatalogIcon },
  { title: "AI Assistant", typeDiscriminator: "AiFlowAction", icon: AIAssistantIcon },
];

export const webBotTelegramNodes: ViewNodeModel[] = [
  { title: "Text", typeDiscriminator: "SendTextFlowAction", icon: TextIcon },
  { title: "Question", typeDiscriminator: "QuestionFlowAction", icon: QuestionIcon },
];
export const webBotExtrasNodes: ViewNodeModel[] = [
  { title: "HTTP request", typeDiscriminator: "CurlFlowAction", icon: HTTPIcon },
  { title: "Condition", typeDiscriminator: "ConditionFlowAction", icon: ConditionIcon },
  { title: "System Action", typeDiscriminator: "SystemActionFlowAction", icon: SystemActionsIcon },
  { title: "Delay", typeDiscriminator: "DelayFlowAction", icon: DelayIcon },
  { title: "Assign", typeDiscriminator: "AssignFlowAction", icon: AssignIcon },
  { title: "Start Flow", typeDiscriminator: "StartSubFlowAction", icon: StartFlowIcon },
  { title: "AI Assistant", typeDiscriminator: "AiFlowAction", icon: AIAssistantIcon },
];
