export enum CustomVariableType {
  "Text" = "Text",
  "Number" = "Number",
  "Date" = "Date",
  "DateTime" = "DateTime",
  "Boolean" = "Boolean",
  "Array" = "Array",
  "Order" = "Order",
}

export enum CustomVariableScope {
  "System" = "System",
  "Contact" = "Contact",
  "Bot" = "Bot",
  "Flow" = "Flow",
}

export enum BooleanVariableValues {
  "True" = "True",
  "False" = "False",
  "Not Set" = "Not Set",
}

export enum ButtonType {
  "CallbackButton" = "CallbackButton",
  "UrlButton" = "UrlButton",
}

export enum MarkupTypeDiscriminator {
  InlineKeyboardMarkup = "InlineKeyboardMarkup",
  ReplyKeyboardMarkup = "ReplyKeyboardMarkup",
}

export enum FlowMarkupTypeDiscriminator {
  InlineKeyboardMarkup = "FlowInlineKeyboardMarkup",
  ReplyKeyboardMarkup = "FlowReplyKeyboardMarkup",
  FlowMessageShoppingCartButton = "FlowMessageShoppingCartButton",
}

export enum ButtonTypeDiscriminator {
  FlowMessageDynamicButton = "FlowMessageDynamicButton",
  FlowMessageCallbackButton = "FlowMessageCallbackButton",
  FlowMessageUrlButton = "FlowMessageUrlButton",
  FlowReplyKeyboardButton = "FlowReplyKeyboardButton",
  FlowMessageShoppingCartButton = "FlowMessageShoppingCartButton",
  InlineKeyboardButton = "InlineKeyboardButton", //Used for conversations
  ReplyKeyboardButton = "ReplyKeyboardButton", //Used for conversations
}

export const InlineKeyboardTypeDiscriminatorList: ButtonTypeDiscriminator[] = [
  ButtonTypeDiscriminator.FlowMessageCallbackButton,
  ButtonTypeDiscriminator.FlowMessageUrlButton,
];

export enum MessageDirection {
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

export enum MessageOriginType {
  Undefined = "Undefined",
  Bot = "Bot",
  Broadcast = "Broadcast",
  Operator = "Operator",
  ApiClient = "ApiClient",
  AiAssistant = "AiAssistant",
}

export interface CustomVariableModel {
  id?: string;
  containerId: string | null;
  scope: CustomVariableScope;
  key: string;
  value?: string;
  type: CustomVariableType;
  description?: string;
}

export interface CustomVariableData {
  id?: string;
  containerId: string | null;
  scope: CustomVariableScope;
  key: string;
  value?: string;
  type: CustomVariableType;
  description?: string;
}

export const IMAGE_FILE_EXTS = ".jpeg,.jpg,.png,.tiff,.gif";
export const EMOJI_DEFAULT_LENGTH = 2;

export enum UserRolesEnum {
  Owner = "Owner",
  Administrator = "Administrator",
  Operator = "Operator",
  Viewer = "Viewer",
  SuperAdmin = "SuperAdmin",
}

export enum TariffPlanFeatureTypeEnum {
  BotCount = "BotCount",
  BroadcastContacts = "BroadcastContacts",
  ActiveFlows = "ActiveFlows",
  NodeCountPerFlow = "NodeCountPerFlow",
  NodeHttpRequest = "NodeHttpReguest",
  NodeDelay = "NodeDelay",
  NodeStartFlow = "NodeStartFlow",
  OrganisationMembersCount = "OrganisationMembersCount",
  TeamsCount = "TeamsCount",
  TeamsMembersCount = "TeamsMembersCount",
  ImportExportFlow = "ImportExportFlow",
  ConversationCount = "ConversationCount",
  NodeAiAgent = "NodeAiAgent",
  AiAgentCount = "AiAgentCount",
}

export enum TariffPlanNameTypeEnum {
  Free = "Free",
  FreePlus = "Free Plus",
  Starter = "Starter",
  ProTrial = "Pro (Trial)",
  Pro = "Pro",
  Unlimited = "Unlimited",
}
