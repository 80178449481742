import {
  Flex,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import plusIconTile from "../../../../../../assets/icons/plusIconTile.svg?react";
import StarPlan from "../../../../../../assets/icons/starPlan.svg?react";
import s from "./CreationMenuNodeButton.module.scss";
import { FlowActionType, NodeType, ViewNodeModel } from "../../../../FlowBuilderModel";
import { getTariffPlanAccessability } from "../../../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../../../../../common/tariffPlan/TariffPlanRedirectButton";

import { AppSettings } from "../../../../../../common/AppSettings";

declare const appSettings: AppSettings;

interface Props {
  node: ViewNodeModel;
  isDisabled?: boolean;
  addNode: (type: NodeType, typeDiscriminator: keyof typeof FlowActionType) => void;
}

export default function CreationMenuNodeButton({ node, addNode, isDisabled }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const typeIsAccessability = () => {
    switch (node.title) {
      case "HTTP request":
        return TariffPlanFeatureTypeEnum.NodeHttpRequest;
      case "Delay":
        return TariffPlanFeatureTypeEnum.NodeDelay;
      case "Start Flow":
        return TariffPlanFeatureTypeEnum.NodeStartFlow;
      case "AI Assistant":
        return TariffPlanFeatureTypeEnum.NodeAiAgent;
      default:
        break;
    }
  };
  const typeAccessability = typeIsAccessability();
  const isAccessability = typeAccessability ? getTariffPlanAccessability(typeAccessability) : { IsAvailable: true };

  return (
    <>
      {!isAccessability["IsAvailable"] ? (
        <Popover placement="right" trigger={"hover"}>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Flex className={s.accordionNodeButton} align="center">
                  <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
                    <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
                    <Icon className={s.iconRight} boxSize="20px" as={node.icon} />
                  </Flex>
                  <Text as="span" variant="medium" noOfLines={1} color="#3E5E95">
                    {t(node.title)}
                  </Text>
                  <Flex justify="end" pr="8px" flex={1}>
                    <Icon className={s.starSVG} boxSize="20px" as={StarPlan} />
                  </Flex>
                </Flex>
              </PopoverTrigger>
              <Portal>
                <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                  <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                  <Flex alignItems="center" justifyContent="center" direction="column">
                    <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                      {tp("Advanced feature")}
                    </PopoverHeader>
                    <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                      {tp("Please upgrade your plan to invite more Operators")}
                    </PopoverBody>
                    <TariffPlanRedirectButton onClose={onClose} />
                  </Flex>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      ) : (
        <Flex
          onClick={() => {
            addNode(NodeType.Action, node.typeDiscriminator);
          }}
          className={`${s.accordionNodeButton} ${isDisabled && s.disabled}`}
          align="center"
        >
          <Flex className={s.iconsFlex} minW="44px" w="44px" gap="32px" overflowX="hidden">
            <Icon className={s.iconLeft} boxSize="20px" as={plusIconTile} />
            <Icon className={s.iconRight} boxSize="20px" as={node.icon} />
          </Flex>
          {appSettings.newItemsPointer?.find(el => el === node.title) && <Box className={s.iconPointer}></Box>}
          <Text as="span" variant="medium" noOfLines={1} color="#3E5E95">
            {t(node.title)}
          </Text>
        </Flex>
      )}
    </>
  );
}
