import { useNavigate } from "react-router-dom";
import { Box, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import s from "../../../../../../Conversation.module.scss";
import MailIcon from "../../../../../../../../assets/icons/mailIcon.svg?react";

export const BroadcastAvatarComponent = (props: { name: string | null; id: string | null }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBroadcast = () => {
    if (props.id) {
      navigate(`/broadcasts/${props.id}/view`);
    }
  };

  return (
    <Tooltip isOpen={tooltip.isOpen} label={props.name} bg={"#FEF6DC"} variant={"dominoLight"} placement="top">
      <Box
        onClick={e => openBroadcast()}
        className={`${s.avatarBroadcast} ${!!props.id ? s.canOpen : ""}`}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
      >
        <Icon as={MailIcon} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};
