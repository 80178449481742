import { httpGet } from "../../common/BaseApi";

export const getBots = (page: number, filter?: string) => {
  return httpGet(`/api/bot?Filter=${filter ?? ""}&page=${page}&size=${10}`);
};

export const getAllOrganisation = () => {
  return httpGet(`/api/organization/all`);
};

export const getAIAssistantsList = () => {
  return httpGet(`/api/aiAgents`);
};
