import { Button, ButtonProps, Flex, useClipboard, Text, Icon } from "@chakra-ui/react";
import checkChatList from "../../../assets/icons/checkChatList.svg?react";
import { useTranslation } from "react-i18next";
interface Props extends ButtonProps {
  copyText: string;
}

export default function ButtonCopy({ children, copyText, ...props }: Props) {
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { onCopy, hasCopied } = useClipboard(copyText);

  return (
    <>
      {hasCopied ? (
        <Flex align="center" gap="4px" px="24px" h={props.h}>
          <Icon as={checkChatList} boxSize="20px" />
          <Text variant="medium" color="#4EAA4A" bg="transparent">
            {commonWords("Copied")}!
          </Text>
        </Flex>
      ) : (
        <>
          <Button onClick={onCopy} {...props}>
            {children}
          </Button>
        </>
      )}
    </>
  );
}
