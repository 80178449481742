import { Dispatch, SetStateAction } from "react";
import { ProfanityFilterRule } from "../../../../ProfanityFilterModel";
import { PROFANITY_FILTER_DEFAULT } from "../../consts";

interface RulesButtons {
  setRules: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
}
export interface RulesButtonsReturnedValue {
  delete: (rulePosition: number) => void;
  add: () => void;
}

export const profanityFilterRulesButtons = ({ setRules }: RulesButtons): RulesButtonsReturnedValue => ({
  delete: (rulePosition: number) => {
    setRules(rules => rules.filter((_, index) => index + 1 !== rulePosition));
  },
  add: () => {
    setRules(rules => [...rules, { ...PROFANITY_FILTER_DEFAULT.rules[0] }]);
  },
});
