import { Flex } from "@chakra-ui/react";
import { Rule } from "./components/Rule/Rule";
import { ProfanityFilterRule } from "../../../../ProfanityFilterModel";
import { profanityFilterRulesButtons } from "./handlers";
import { Dispatch, SetStateAction } from "react";

interface Props {
  rules: ProfanityFilterRule[];
  setRules: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
}

export const ProfanityFilterRules = ({ rules, setRules }: Props) => {
  const handlers = profanityFilterRulesButtons({ setRules });

  return (
    <Flex direction="column" rowGap="16px">
      {rules.map((rule, index) => (
        <Rule rule={rule} position={index + 1} key={rule.type + index} handlers={handlers} setRules={setRules} rules={rules} />
      ))}
    </Flex>
  );
};
