import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../auth/AuthSlice";
import loginReducer from "../../components/login/LoginSlice";
import signUpReducer from "../../components/signUp/SignUpSlice";
import flowBuilderReducer from "../../components/flowBuilder/FlowBuilderSlice";
import automationReducer from "../../components/automation/AutomationSlice";
import chatListReducer from "../../components/chatList/ChatListSlice";
import botListReducer from "../../components/botList/BotListSlice";
import conversationReducer from "../../components/conversation/ConversationSlice";
import conversationViewerReducer from "../../components/conversationViewer/ConversationViewerSlice";
import routerReducer from "../router/RouterSlice";
import notificationReducer from "../notifications/NotificationSlice";
import systemNotificationReducer from "../systemNotifications/SystemNotificationSlice";
import sidebarReducer from "../../components/sidebar/SidebarSlice";
import dashboardReducer from "../../components/dashboard/DashboardSlice";
import addBotReducer from "../../components/addBot/AddBotSlice";
import botReducer from "../../components/bot/BotSlice";
import webBotReducer from "../../components/webBot/WebBotSlice";
import contactInfoReducer from "../../components/contactInfo/ContactInfoSlice";
import broadcastListReducer from "../../components/broadcastList/BroadcastListSlice";
import userProfileReducer from "../../components/userProfile/UserProfileSlice";
import userManagementReducer from "../../components/userManagement/UserManagementSlice";
import broadcastReducer from "../../components/broadcast/BroadcastSlice";
import userReducer from "../user/UserSlice";
import tariffPlanReducer from "../tariffPlan/TariffPlanSlice";
import contactListReducer from "../../components/contactList/ContactListSlice";
import aiAssistantListReducer from "../../components/aiAssistant/AIAssistantListSlice";
import contactStateReducer from "../../components/contact/ContactSlice";
import organisationReducer from "../../components/organisation/OrganisationSlice";
import onboardingReducer from "../../components/onboardingTour/OnboardingToursSlice";
import pluginAuthReducer from "../../components/pluginAuth/PluginAuthSlice";
import commonReducer from "../../components/layout/BuildMainApp/Common/CommonSlice";
import recipientListReducer from "../../components/broadcast/components/ContactList/RecipientListSlice";
import groupChatReducer from "../../components/groupChat/GroupChatSlice";
import groupChatListReducer from "../../components/groupChat/components/GroupChatList/GroupChatListSlice";
import groupChatInfoReducer from "../../components/groupChat/components/GroupChatMain/components/GroupChatInfo/GroupChatInfoSlice";
import groupChatMessagesListReducer from "../../components/groupChat/components/GroupChatMain/components/GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSlice";
import groupChatTextAreaBlockReducer from "../../components/groupChat/components/GroupChatMain/components/GroupChatConversation/components/GroupChatTextAreaBlock/GroupChatTextAreaBlockSlice";
import groupChatMemberListReducer from "../../components/groupChat/components/GroupChatMain/components/GroupChatMemberList/GroupChatMemberListSlice";
import profanityFilterReducer from "../../components/groupChat/components/GroupChatMain/components/Tabs/ProfanityFilter/ProfanityFilterSlice";

import { ApplicationState, DominoState } from "./ApplicationState";
import { loadingStateReducer } from "../loading/LoadingStateReducer";
import TelegramAuthSlice from "../TelegramAuth/TelegramAuthSlice";

export const rootReducer = (state: ApplicationState | undefined, action: AnyAction) => {
  if (action.type === "auth/logout") {
    state = {} as ApplicationState;
  }
  const combinedReducers = combineReducers<ApplicationState>({
    authState: authReducer,
    app: combineReducers<DominoState>({
      loginState: loginReducer,
      signUpState: signUpReducer,
      flowBuilderState: flowBuilderReducer,
      automationState: automationReducer,
      chatListState: chatListReducer,
      botListState: botListReducer,
      sidebarState: sidebarReducer,
      conversationState: conversationReducer,
      conversationViewerState: conversationViewerReducer,
      addBotState: addBotReducer,
      botState: botReducer,
      webBotState: webBotReducer,
      contactInfoState: contactInfoReducer,
      broadcastListState: broadcastListReducer,
      userProfileState: userProfileReducer,
      userManagementState: userManagementReducer,
      broadcastState: broadcastReducer,
      brodcastRecipientListState: recipientListReducer,
      dashboardState: dashboardReducer,
      contactListState: contactListReducer,
      aiAssistantListState: aiAssistantListReducer,
      contactState: contactStateReducer,
      organisationState: organisationReducer,
      onboardingState: onboardingReducer,
      pluginAuthState: pluginAuthReducer,
      telegramAuthState: TelegramAuthSlice,
      commonState: commonReducer,
      groupChatState: groupChatReducer,
      groupChatListState: groupChatListReducer,
      groupChatInfoSlice: groupChatInfoReducer,
      groupChatMessagesListState: groupChatMessagesListReducer,
      groupChatTextAreaBlockState: groupChatTextAreaBlockReducer,
      groupChatMemberListState: groupChatMemberListReducer,
      profanityFilterState: profanityFilterReducer,
    }),
    tariffPlanState: tariffPlanReducer,
    userState: userReducer,
    loadingState: loadingStateReducer,
    routerState: routerReducer,
    notificationState: notificationReducer,
    systemNotificationState: systemNotificationReducer,
  });
  return combinedReducers(state, action);
};
