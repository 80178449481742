import React from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, Routes } from "react-router-dom";
import s from "./App.module.scss";
import { SignInSignUpContainer } from "./components/login/SignInSignUpContainer";
import { NotificationContainer } from "./common/notifications/NotificationContainer";
import { AccountNotApprovedPage } from "./components/pages/accountNotApprovedPage/AccountNotApprovedPage";
import { ProtectedLayout } from "./components/layout/ProtectedLayout";
import { PublicLayout } from "./components/layout/PublicLayout";
import { ContactPage } from "./components/contact/ContactPage";
import { Invitation } from "./common/auth/Invitation";
import HelmetContainer from "./common/HelmetContainer";
import { ContactsPage } from "./components/layout/BuildMainApp/Contacts/ContactsPage";
import { BotsPage } from "./components/layout/BuildMainApp/Bots/BotsPage";
import { BroadcastListPage } from "./components/layout/BuildMainApp/Broadcast/broadcastListPage/BroadcastListPage";
import { BroadcastPagesLayout } from "./components/layout/BuildMainApp/Broadcast/broadcastPagesLayout/BroadcastPagesLayout";
import { BroadcastHeaderType } from "./components/broadcast/BroadcastModel";
import { Broadcast } from "./components/broadcast/components/Broadcast/Broadcast";
import { ViewBroadcast } from "./components/broadcast/components/ViewBroadcast/ViewBroadcast";
import { BotPage } from "./components/layout/BuildMainApp/Bots/pages/BotPage";
import { MyProfilePage } from "./components/layout/BuildMainApp/MyProfile/MyProfilePage";
import { UserManagementPage } from "./components/layout/BuildMainApp/UserManagment/UserManagementPage";
import { DashboardPage } from "./components/layout/BuildMainApp/Dashboard/DashboardPage";
import { SetOrganizationPage } from "./components/layout/BuildMainApp/Organisation/SetOrganizationPage";
import { SetBotPage } from "./components/layout/BuildMainApp/Bots/pages/SetBotPage";
import { OperatorsPage } from "./components/organisation/components/OperatorList/OperatorsPage";
import { TeamsPage } from "./components/organisation/components/TeamList/TeamsPage";
import { OrganisationPage } from "./components/layout/BuildMainApp/Organisation/OrganisationPage";
import { FlowBuilderPage } from "./components/layout/BuildMainApp/Automation/pages/FlowBuilder/FlowBuilderPage";
import { TriggersPage } from "./components/layout/BuildMainApp/Automation/pages/TriggersPage";
import { FlowPage } from "./components/layout/BuildMainApp/Automation/pages/FlowPage";
import { AutomationPage } from "./components/layout/BuildMainApp/Automation/AutomationPage";
import { ConversationPage } from "./components/layout/BuildMainApp/LiveChat/pages/ConversationPage";
import { IFrameContainer } from "./components/iFrame/iFrameContainer";
import { PluginPage } from "./components/layout/BuildMainApp/Plugin/PluginPage";
import { PluginAuthPage } from "./components/layout/BuildMainApp/Plugin/PluginAuthPage";
import { GeneralOnboardingTour } from "./components/onboardingTour/GeneralOnboardingTourContainer";
import { FlowBuilderOnboardingTour } from "./components/onboardingTour/FlowBuilderOnboardingContainer";
import { RegistrationSuccessful } from "./components/registrationSuccessful/RegistrationSuccessful";
import { GroupChatPage } from "./components/layout/BuildMainApp/GroupChat/GroupChatPage";
import { AppSettings } from "./common/AppSettings";
import useRemoveCustomSpinner from "./common/hooks/useRemoveCustomSpinner";
import { AIAssistantPage } from "./components/layout/BuildMainApp/AIAssistant/AIAssistantPage";
import { AddBot } from "./components/addBot/AddBot";
import { AddWebBot } from "./components/webBot/addWebBot/AddWebBot";
import { useAppSelector } from "./common/state/store";
import { selectCurrentBot } from "./components/sidebar/SidebarSlice";

declare const appSettings: AppSettings;

const App = () => {
  useRemoveCustomSpinner();
  const currentBot = useAppSelector(selectCurrentBot);
  return (
    <div className={s.App}>
      <GeneralOnboardingTour />
      <FlowBuilderOnboardingTour />
      <RouterProvider router={router(currentBot?.channel === "Web")} />
      <NotificationContainer />
      <HelmetContainer />
    </div>
  );
};

const router = (isWebBot: boolean) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/plugin/auth/:pluginName" element={<PluginAuthPage />} />
        <Route path="/auth" element={PublicLayout(<SignInSignUpContainer />, "Welcome to Domino!")} />
        <Route path="/auth/registrationSuccessful" element={PublicLayout(<RegistrationSuccessful />, "Welcome to Domino!")} />
        <Route
          path="/auth/accountNotApproved"
          element={PublicLayout(<AccountNotApprovedPage />, "Thank you for registering with Domino!")}
        />
        <Route path="/invitation/:inviteToken" element={<Invitation />} />
        <Route path="/checkauth" element={<IFrameContainer />} />
        <Route
          path="*"
          element={
            <ProtectedLayout>
              <Routes>
                <Route path="*" element={<Navigate to="/automation/flows" />} />
                <Route element={<AutomationPage />}>
                  <Route path="/automation" element={<Navigate to="/automation/flows" />} />
                  <Route path="/automation/flows" element={<FlowPage />} />
                  <Route path="/automation/triggers" element={<TriggersPage />} />
                </Route>
                <Route path="/automation/flows/:id" element={<FlowBuilderPage />} />

                <Route element={<OrganisationPage />}>
                  <Route path="/organisation" element={<Navigate to="/organisation/operators" />} />
                  <Route path="/organisation/operators" element={<OperatorsPage />} />
                  <Route path="/organisation/teams" element={<TeamsPage />} />
                </Route>
                <Route path="/setOrganisation/:organisationId" element={<SetOrganizationPage />} />

                <Route path="/broadcasts" element={isWebBot && <Navigate to="/" />}>
                  <Route path="" element={<BroadcastListPage />} />
                  <Route path="new" element={BroadcastPagesLayout(<Broadcast />, BroadcastHeaderType.new)} />
                  <Route path=":broadcastId/view" element={BroadcastPagesLayout(<ViewBroadcast />, BroadcastHeaderType.view)} />
                  <Route path=":broadcastId/edit" element={BroadcastPagesLayout(<Broadcast />, BroadcastHeaderType.edit)} />
                </Route>

                <Route path="/contacts">
                  <Route path="" element={<ContactsPage />} />
                  <Route path="*" element={<ContactPage />} />
                </Route>

                <Route path="/bots" element={<BotsPage />} />
                <Route path="/bots/:botId" element={<BotPage />} />
                <Route path="/setBot/:botId" element={<SetBotPage />} />
                <Route path="/addbot" element={<BotsPage />} />
                <Route path="/addTelegramBot" element={<AddBot />} />
                <Route path="/addWebBot" element={<AddWebBot />} />

                <Route path="/chats" element={<ConversationPage />} />
                <Route path="/chats/:conversationId" element={<ConversationPage />} />

                {appSettings.showGroupChats && (
                  <>
                    <Route path="/groupChats" element={isWebBot ? <Navigate to="/" /> : <GroupChatPage />} />
                    <Route path="/groupChats/:conversationId" element={isWebBot ? <Navigate to="/" /> : <GroupChatPage />} />
                  </>
                )}

                <Route path="/dashboard" element={isWebBot ? <Navigate to="/" /> : <DashboardPage />} />

                <Route path="/aiAssistant" element={<AIAssistantPage />} />

                <Route path="/management" element={<UserManagementPage />} />

                <Route path="/profile" element={<MyProfilePage />} />

                <Route path="/plugin/:pluginName" element={<PluginPage />} />
              </Routes>
            </ProtectedLayout>
          }
        />
      </>,
    ),
  );

export default App;
