export function getRandomRange(range: number, onlyPositive = false) {
  const randomMargin = Math.floor(Math.random() * range);
  const possibleMinus = Math.round(Math.random()) ? 1 : -1;
  return onlyPositive ? randomMargin : randomMargin * possibleMinus;
}

export function randomUniqueNum(range: number, outputCount: number) {
  const arr = [];
  for (let i = 1; i <= range; i++) {
    arr.push(i);
  }

  const result = [];

  for (let i = 1; i <= outputCount; i++) {
    const random = Math.floor(Math.random() * (range - i));
    result.push(arr[random]);
    arr[random] = arr[range - i];
  }

  return result;
}
