import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import Confetti from "../../../../../assets/icons/Confetti.png";
import { useTranslation } from "react-i18next";
import useGA from "../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useEffect } from "react";
import { GroupConfettiModalEvents } from "../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export const ConfettiGroupChatModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: (value: boolean) => void }) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.conffetiModal" }).t;
  const trackEvent = useGA(EventCategories.Group);

  useEffect(() => {
    if (isOpen) trackEvent(GroupConfettiModalEvents.GroupsModal);
  }, [isOpen, trackEvent]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose(true);
        localStorage.setItem("isShowConfettiGroupChatModel", "true");
        trackEvent(GroupConfettiModalEvents.GroupsModalClose);
      }}
    >
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
        maxW="480px"
        borderRadius="12px"
        mx="24px"
      >
        <ModalHeader
          display="flex"
          flexDirection="column"
          gap="8px"
          w="100%"
          alignItems="center"
          px={{ base: "16px", sm: "32px" }}
          pt="32px"
          pb="16px"
        >
          <Image src={Confetti} boxSize="80px" />
          <Text textAlign="center" variant="h1">
            {groupChatInfoTranslation("Hooray — group chats are here for you!")}
          </Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody textAlign="center" w="100%" px={{ base: "16px", sm: "32px" }} py="0px">
          <Text textAlign="center" variant="large">
            {groupChatInfoTranslation(
              "We did it! You can now manage conversations in groups and channels directly from the platform.",
            )}
          </Text>
        </ModalBody>
        <ModalFooter px={{ base: "16px", sm: "32px" }} pt="32px" pb="32px">
          <Button
            onClick={() => {
              onClose(true);
              localStorage.setItem("isShowConfettiGroupChatModel", "true");
              trackEvent(GroupConfettiModalEvents.GroupsModalTry);
            }}
            w="100%"
            variant="dominoViolet"
          >
            {groupChatInfoTranslation("Take part in a free beta test!")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
