import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Flex,
  Box,
} from "@chakra-ui/react";
import s from "./WebBotChangeModal.module.scss";
import { useTranslation } from "react-i18next";
import ButtonCopy from "../../../../../../UI/atoms/buttonCopy/ButtonCopy";
import CopyFillIcon from "../../../../../../assets/icons/copyFlatten.svg?react";
import CrossIcon from "../../../../../../assets/icons/cross.svg?react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  copyText: string;
}

export const WebBotChangeModal = ({ isOpen, onClose, copyText }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="16px" color="#232B39" borderRadius="12px" p="24px" maxW="440px">
        <ModalHeader p="0" fontSize="24px" lineHeight="32px" mb="16px" fontWeight="400">
          {t("The widget title has been changed.")}
        </ModalHeader>
        <ModalCloseButton borderRadius={"50%"} color={"darkGrey"} _hover={{ bg: "defaultGrey", color: "mainPurple" }}>
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody p="0" fontSize="16px" lineHeight="20px" fontWeight="500" gap="8px" display="flex" flexDir="column" mb="32px">
          <Text>{t("A new code for embedding on the site has been generated after changing the widget title.")}</Text>
          <Box>
            <Text as="span">
              {t(
                "To ensure the bot works correctly, please make sure the new code is added to every page of your site before the closing",
              )}
            </Text>
            <Text px="4px" as="span" fontWeight={700}>
              {"</body>"}
            </Text>
            <Text as="span">{t("tag.")}</Text>
          </Box>
        </ModalBody>

        <ModalFooter p="0" w="100%" justifyContent="space-between">
          <Flex className={s.buttons}>
            <Button onClick={onClose} variant="dominoOutlineViolet">
              {ct("Close")}
            </Button>
            <ButtonCopy leftIcon={<CopyFillIcon />} variant="dominoViolet" copyText={copyText}>
              {ct("Copy code")}
            </ButtonCopy>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
