import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { selectCurrentBot, selectIsCurrentBotLoading } from "../sidebar/SidebarSlice";
import { Box, Flex, Text } from "@chakra-ui/react";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";
import { useTranslation } from "react-i18next";
import { AddFirstGroupChatContainer } from "./components/AddFirstGroupChat/AddFirstGroupChatContainer";
import s from "./GroupChatContainer.module.scss";
import { GroupChatList } from "./components/GroupChatList/GroupChatList";
import EmptyChatIcon from "../../assets/icons/emptyChat.svg?react";
import EmptyBotImage from "../../assets/images/botImageHappy.svg?react";
import { useParams } from "react-router-dom";
import { GroupChatMain } from "./components/GroupChatMain/GroupChatMain";
import { useNowWidthView } from "../layout/LayoutHelper/ResolutionHooks";
import {
  clearGroupChatList,
  getGroupChatListCount,
  selectGroupChatList,
  selectSearchGroupChatList,
  selectTotalItems,
} from "./components/GroupChatList/GroupChatListSlice";
import { useEffect } from "react";
import { ChatListSortEnum } from "../chatList/ChatListModel";
import { getOverLimitConversations, selectOverLimitedConversations } from "../chatList/ChatListSlice";
import { GroupChatMemberListContainer } from "./components/GroupChatMain/components/GroupChatMemberList/GroupChatMemberListContainer";
import { ProfanityFilterContainer } from "./components/GroupChatMain/components/Tabs/ProfanityFilter/ProfanityFilterContainer";
import { selectIsOpenProfanityFilter } from "./components/GroupChatMain/components/Tabs/ProfanityFilter/ProfanityFilterSlice";

export const GroupChatContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "groupChat" });

  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const groupChatListCount = useAppSelector(selectTotalItems);
  const groupChatList = useAppSelector(selectGroupChatList);
  const searchText = useAppSelector(selectSearchGroupChatList);
  const overLimitConversations = useAppSelector(selectOverLimitedConversations);
  const isOpenProfanityFilter = useAppSelector(selectIsOpenProfanityFilter);

  const overLimitCount = overLimitConversations?.length ?? 0;
  const isOverLimit = overLimitCount > 0;

  const nowWidth = useNowWidthView();
  const dispatch = useAppDispatch();

  const params = useParams();
  const id = params["conversationId"];

  const clearGroupChatListFn = () => {
    dispatch(clearGroupChatList());
  };

  const isRightPlaceTabOpen = isOpenProfanityFilter;

  useEffect(() => {
    if (currentBot) {
      dispatch(
        getGroupChatListCount({
          botId: currentBot.id,
          search: "",
          sort: ChatListSortEnum.newest,
          lastId: "1",
        }),
      );
      dispatch(getOverLimitConversations());
    }
    return () => clearGroupChatListFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, dispatch]);

  if (!currentBot && !isCurrentBotLoading) {
    return (
      <Box h="100%" overflowY="auto">
        <BaseEmptyPage
          maxW="492px"
          overflowY="auto"
          botTitle={t("No Group Chats")}
          botDescription={t("Create or connect an existing bot to add a group chat")}
          Image={EmptyBotImage}
        />
      </Box>
    );
  }

  if (groupChatListCount === 0 && searchText === "" && groupChatList?.items.length === 0 && !isOverLimit) {
    return (
      <Box h="100%" overflowY="auto">
        <AddFirstGroupChatContainer />
      </Box>
    );
  }

  return (
    <Flex className={s.groupChatContainer} overflowX="clip">
      <Box className={s.groupChatList}>
        <GroupChatList overLimitConversations={overLimitConversations} />
      </Box>
      <Flex className={`${s.rightPlace} ${id ? s.open : ""}`}>
        {!id && (nowWidth > 768 || nowWidth === 0) ? (
          <Flex className={s.startMessaging}>
            <EmptyChatIcon />
            <Text variant="medium" color="darkGrey">
              {t("Select a conversation to start messaging")}
            </Text>
          </Flex>
        ) : (
          <>
            {id && <GroupChatMain />}
            <GroupChatMemberListContainer />
          </>
        )}
      </Flex>
      <Flex className={`${s.groupChatTabs} ${isRightPlaceTabOpen ? s.open : ""}`}>
        <ProfanityFilterContainer />
      </Flex>
    </Flex>
  );
};
