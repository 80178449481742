import { httpGet, httpPost } from "../../common/BaseApi";
import { DashboardDataModel } from "./DashboardModel";

export const getBots = (page: number) => {
  return httpGet(`/api/bot?page=${page}&size=${10}`);
};

export const getSentMessages = (data: DashboardDataModel) => {
  return httpPost(`/api/dashboard/getSentMessages`, data);
};

export const getSentReceivedMessages = (data: DashboardDataModel) => {
  return httpPost(`/api/dashboard/getSentReceivedMessages`, data);
};

export const getUTMStatistics = (botId: string, data: { from: string; to: string; isUnique: boolean }) => {
  return httpPost(`/api/bot/${botId ? botId : null}/utm/statistics`, data);
};
