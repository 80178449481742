import { Box, Flex, Text } from "@chakra-ui/react";
import { Handle, Position } from "reactflow";
import s from "../../AINode.module.scss";
import { ActionNodeProps } from "../../../ActionNodeProps";
import { AiFlowActionModel, FlowModel } from "../../../../../FlowBuilderModel";
import { useTranslation } from "react-i18next";

export const OperatorBlock = ({ actionData, flow }: { actionData: ActionNodeProps; flow: FlowModel }) => {
  const data = actionData.node.flowAction as AiFlowActionModel;
  const { t } = useTranslation("translation", { keyPrefix: "flow" });

  return (
    <Box w="100%">
      <Flex className={s.callOperator}>
        <Box>{t("Call an operator on request")}</Box>
        <Box className={s.baseTextViewBlock} minH="40px">
          {data.operatorOptions.operatorRequestedMessageText}
        </Box>
      </Flex>
      <Flex pr="8px" w="100%" justify="flex-end" mt="32px" position="relative">
        <Text textAlign="right">{t("Operator")}</Text>
        <Handle
          isValidConnection={() => !actionData.isEdgeInHandle(actionData.node.id, "ai-source")}
          isConnectable={!actionData.isEdgeInHandle(actionData.node.id, "ai-source")}
          className={`${s.handleStyle} ${actionData.isEdgeInHandle(actionData.node.id, "ai-source") ? s.active : ""}`}
          type="source"
          position={Position.Right}
          id="ai-source"
        />
      </Flex>
    </Box>
  );
};
