import { ReactElement } from "react";
import { Box, Flex } from "@chakra-ui/react";
import s from "./ContentWrapper.module.scss";

interface Props {
  blocks: ReactElement[];
}

export const ContentWrapper = ({ blocks }: Props) => {
  return (
    <Flex className={s.wrapper}>
      <Flex direction="column" w="100%">
        {blocks.map(block => {
          return (
            <Box className={s.itemWrapper} key={block.key}>
              {block}
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};
