import { useEffect } from "react";
import { Box, Text, Button, useDisclosure, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { selectAIAssistantList, getAIAssistantList, clearAIAssistantListState } from "./AIAssistantListSlice";
import { NewAIAssistantModal } from "./components/newAIAssistantModal/NewAIAssistantModal";
import { AIAssistantList } from "./components/AIAssistantList";
import { SkeletonList } from "../../UI/organisms/SkeletonList";
import { useMobileView } from "../layout/LayoutHelper/ResolutionHooks";
import { TariffPlanNameTypeEnum } from "../../common/AppEnums";
import BaseEmptyPage from "../../common/baseEmptyPage/BaseEmptyPage";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";
import { selectTariffPlanName } from "../../common/tariffPlan/TariffPlanSlice";
import { AppSettings } from "../../common/AppSettings";
import ImageCreateAIAssistant from "../../assets/images/imageCreateAIAssistant.svg?react";
import PlusIcon from "../../assets/icons/plusIconTile.svg?react";
import { TranslationWithStrongWord } from "../../UI/atoms/textTranslation/TextTranslation";
import s from "./AIAssistantListContainer.module.scss";

declare const appSettings: AppSettings;

export const AIAssistantListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "AI-assistant" });
  const dispatch = useAppDispatch();
  const isMobile = useMobileView();
  const { isOpen: isNewAIAssistantModalOpen, onOpen: onNewAIAssistant, onClose: onNewAIAssistantModalClose } = useDisclosure();
  const loadingAIAssistantList = useGetLoadingState("getAIAssistantList");
  const loadingCreateAIAssistant = useGetLoadingState("createAIAssistant");
  const isUpdateLoading = useGetLoadingState("updateAIAssistant");
  const isDeleteLoading = useGetLoadingState("deleteAIAssistant");
  const aiAssistantList = useAppSelector(selectAIAssistantList);

  const tariffPlanName = useAppSelector(selectTariffPlanName);

  const isTariffProOrUnlimitedOrTrial =
    tariffPlanName === TariffPlanNameTypeEnum.Pro ||
    tariffPlanName === TariffPlanNameTypeEnum.Unlimited ||
    tariffPlanName === TariffPlanNameTypeEnum.ProTrial;

  const isTariffFree = tariffPlanName === TariffPlanNameTypeEnum.Free;

  const isStarterTariff = tariffPlanName === TariffPlanNameTypeEnum.Starter;
  const isTariffFreeOrStarter =
    tariffPlanName === TariffPlanNameTypeEnum.Starter || tariffPlanName === TariffPlanNameTypeEnum.Free;
  const canAddAIAssistant = isTariffProOrUnlimitedOrTrial || isStarterTariff;

  const handleNewAIAssistant = () => {
    onNewAIAssistant();
  };
  const handleEditAIAssistantItem = () => {
    onNewAIAssistant();
  };

  useEffect(() => {
    dispatch(getAIAssistantList());
    return () => {
      dispatch(clearAIAssistantListState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onButtonClick = () => {
    if (canAddAIAssistant) {
      onNewAIAssistant();
    } else {
      window.open(appSettings.billingPortalUrl);
    }
  };

  const emptyPageTitle = isTariffFree
    ? t("AI Assistant title")
    : isStarterTariff
    ? t("AI Assistant - now available for you!")
    : t("You don’t have any AI assistants yet");

  const emptyPageAdditionalText = isTariffFreeOrStarter ? (
    <Text variant="medium" color="black" className={s.additionalText}>
      <TranslationWithStrongWord i18nKey="AI-assistant.Try the AI Assistant with the Starter plan for free or get it forever with the Pro plan">
        <Text as="span" className={s.strongWord} />
      </TranslationWithStrongWord>
    </Text>
  ) : (
    <></>
  );

  const emptyPageButtonName = isTariffFree ? t("Select plan") : isStarterTariff ? t("Try the AI Assistant") : t("Add Assistant");

  return (
    <>
      <Box className={s.aiAssistantListContainer}>
        {!loadingAIAssistantList && !aiAssistantList?.length && !loadingCreateAIAssistant ? (
          <Box className={s.emptyPageScroll} mx={"-16px"}>
            <BaseEmptyPage
              botTitle={t("No AI Assistants")}
              botDescription={t("Create or connect an existing bot to add your first AI Assistant.")}
              title={emptyPageTitle}
              description={t("Upload a knowledge base, and the AI will use it to answer your contacts' questions on your behalf")}
              Image={ImageCreateAIAssistant}
            >
              <Flex flexDir="column" gap="8px">
                <Button
                  variant="dominoViolet"
                  className={`${s.emptyPageButton} ${isTariffFree || isStarterTariff ? s.buttonWidth : ""}`}
                  onClick={onButtonClick}
                >
                  {canAddAIAssistant && !isStarterTariff && <PlusIcon />}
                  {emptyPageButtonName}
                </Button>
                {emptyPageAdditionalText}
              </Flex>
            </BaseEmptyPage>
          </Box>
        ) : !aiAssistantList?.length ||
          loadingAIAssistantList ||
          loadingCreateAIAssistant ||
          isUpdateLoading ||
          isDeleteLoading ? (
          <SkeletonList count={3} isMobile={isMobile} height="108px" />
        ) : (
          <Box className={s.aiAssistantListContent}>
            <Box className={s.aiAssistantListTitle}>
              <Text className={s.titleText} variant="medium">
                {t("Title")}
              </Text>
              <Text className={s.titleText} variant="medium">
                {t("Description")}
              </Text>
              <Text className={s.titleText} variant="medium">
                {t("Answers File")}
              </Text>
            </Box>
            <AIAssistantList onNewAIAssistant={handleNewAIAssistant} onEditAIAssistantItem={handleEditAIAssistantItem} />
          </Box>
        )}
      </Box>
      <NewAIAssistantModal newAIAssistantState={isNewAIAssistantModalOpen} onClose={onNewAIAssistantModalClose} />
    </>
  );
};
