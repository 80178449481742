import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const textH1Variant = defineStyle({
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "32px",
});

const textH2Variant = defineStyle({
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "28px",
});

const textH2BoldVariant = defineStyle({
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "28px",
  color: "#232B39",
});

const textH3Variant = defineStyle({
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "24px",
});

const textLargeBoldVariant = defineStyle({
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "20px",
});

const textExtraLargeBoldVariant = defineStyle({
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
});

const textPreLargeBoldVariant = defineStyle({
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "20px",
});

const textLargeVariant = defineStyle({
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
});
const textMediumVariant = defineStyle({
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
});

const textMediumBoldVariant = defineStyle({
  fontWeight: "700",
  fontSize: "15px",
  lineHeight: "20px",
});

const textMediumGreyVariant = defineStyle({
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "20px",
  color: "var(--chakra-colors-darkGrey)",
});

const textSmallVariant = defineStyle({
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "16px",
});

const textExtraSmallVariant = defineStyle({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "14px",
});

const text14 = defineStyle({
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
});

const textInputError = defineStyle({
  color: "red",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "14px",
});

const variants = {
  h1: textH1Variant,
  h2: textH2Variant,
  h2Bold: textH2BoldVariant,
  h3: textH3Variant,
  extraLargeBold: textExtraLargeBoldVariant,
  largeBold: textLargeBoldVariant,
  preLargeBold: textPreLargeBoldVariant,
  large: textLargeVariant,
  medium: textMediumVariant,
  mediumBold: textMediumBoldVariant,
  mediumGrey: textMediumGreyVariant,
  small: textSmallVariant,
  extraSmall: textExtraSmallVariant,
  text14: text14,
  inputError: textInputError,
};

export const textTheme = defineStyleConfig({ variants });
