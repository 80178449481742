import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import CrossIcon from "../../../../../assets/icons/cross.svg?react";
import { useTranslation } from "react-i18next";
import { useGetLoadingState } from "../../../../../common/loading/hooks/useGetLoadingState";
import { Link } from "react-router-dom";
import useGA from "../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsAddMacEvents } from "../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export const AddGroupNotificationModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.notificationModal" }).t;
  const isLoading = useGetLoadingState("blockMember");
  const trackEvent = useGA(EventCategories.Group);

  const handleClose = () => {
    onClose();
    trackEvent(GroupsAddMacEvents.GroupsAddMacTGNotificationsClose);
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
        borderRadius="12px"
        maxW="460px"
        mx="16px"
      >
        <ModalHeader px="32px" pt="32px" pb="16px">
          <Text variant="h1">{groupChatInfoTranslation("Telegram notifications are disabled")}</Text>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody px="32px" py="0px">
          <Text variant="medium">
            {groupChatInfoTranslation("Please go to your profile page to enable Telegram notifications")}
          </Text>
        </ModalBody>
        <ModalFooter px="32px" pt="32px" pb="32px" justifyContent={"space-between"}>
          <Button
            variant="dominoOutlineViolet"
            onClick={() => {
              if (!isLoading) {
                onClose();
                trackEvent(GroupsAddMacEvents.GroupsAddMacTGNotificationsCancel);
              }
            }}
          >
            {groupChatInfoTranslation("Cancel")}
          </Button>
          <Link to="/profile" onClick={() => trackEvent(GroupsAddMacEvents.GroupsAddMacTGNotificationsGo)}>
            <Button variant="dominoViolet">{groupChatInfoTranslation("Go to my profile")}</Button>
          </Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
