import { ProfanityFilterModel, ProfanityFilterTypeEnum } from "../../ProfanityFilterModel";

export const MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT = 768;
export const MAXIMAL_RESTRICTION_COUNT = 5;
export const CUSTOM_PROFANITIES_SPLITTER = ", ";

export const PROFANITY_FILTER_DEFAULT: ProfanityFilterModel = {
  isEnabled: false,
  rules: [
    {
      type: ProfanityFilterTypeEnum.Warning,
    },
  ],
  customProfanities: [],
};
