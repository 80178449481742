import { Box, Flex, Tooltip, Image, Text } from "@chakra-ui/react";
import s from "./GroupChatListItem.module.scss";
import { fromNowChat } from "../../../../../../common/utils/fromNowChat";
import { MessageStatusEnum } from "../../../../../conversation/ConversationModel";

import { AppSettings } from "../../../../../../common/AppSettings";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "../../../../../../common/user/UserSlice";
import ImageMessageIcon from "../../../../../../assets/icons/imageMessageIcon.svg?react";
import DocumentMessageIcon from "../../../../../../assets/icons/documentMessageIcon.svg?react";
import Circle from "../../../../../../assets/images/dangerCircle.svg?react";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { useNavigate, useParams } from "react-router-dom";
import { GroupChatListItemModel } from "../../../../GroupChatModel";
import { RenderSmoothImage } from "../../../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";
import { changeIsLoadData } from "../../../GroupChatMain/components/Tabs/ProfanityFilter/ProfanityFilterSlice";
import useGA from "../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatEvents } from "../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";
declare const appSettings: AppSettings;

export const GroupChatListItem = ({ chat }: { chat: GroupChatListItemModel }) => {
  const ce = useTranslation("translation", { keyPrefix: "conversation.errors" }).t;
  const cx = useTranslation("translation", { keyPrefix: "conversation" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "groupChat.groupChatMessages" });
  const trackEvent = useGA(EventCategories.Group);
  const dispatch = useAppDispatch();

  const lng = useAppSelector(selectLanguage);
  const navigate = useNavigate();

  const params = useParams();
  const id = params["conversationId"];

  const selectChat = () => {
    if (id !== chat.id) {
      navigate(`/groupChats/${chat.id}`, { replace: true });
      dispatch(changeIsLoadData(false));
      trackEvent(GroupsChatEvents.GroupsChatConversationOpen);
    }
  };

  const errorHandler = (error?: string) => {
    if (!error) {
      return "";
    }
    const tooManyRequestError = new RegExp(/Too Many Requests: retry after/gm);
    if (tooManyRequestError.test(error)) {
      return ce("Too Many Requests");
    }
    const reg = new RegExp(/Wrong HTTP URL/gm);
    return reg.test(error) ? ce("Wrong Url") : ce(error);
  };

  const isSupported =
    chat.lastMessage?.text || chat.lastMessage?.isContact || chat.lastMessage?.isDocument || chat.lastMessage?.isPhoto;

  return (
    <Box
      data-pw="groupChat-item"
      onClick={selectChat}
      className={id === chat.id ? `${s.groupChatListItem} ${s.active}` : s.groupChatListItem}
    >
      {chat.group && chat.group.avatars?.length ? (
        <RenderSmoothImage
          boxSize="36px"
          alt={chat.name}
          src={`${appSettings.apiBaseUrl}/file/${chat.group.avatars[0][0].fileId}`}
        />
      ) : (
        <RenderSmoothImage boxSize="36px" alt={chat.name} />
      )}
      <Box className={s.groupChatInfo}>
        {chat.lastMessage?.date === null ? (
          <Flex width="100%" justify="space-between" alignItems={"center"}>
            <Text noOfLines={1} fontSize={"14px"} lineHeight={"143%"} wordBreak="break-all" inlineSize={"81%"}>
              {chat.name}
            </Text>
          </Flex>
        ) : (
          <>
            {chat?.lastMessage?.status === MessageStatusEnum.Failed ? (
              <Flex width="100%" justify="space-between" alignItems={"center"}>
                <Text noOfLines={1} fontSize={"14px"} wordBreak="break-all" lineHeight={"143%"} inlineSize={"81%"}>
                  {chat.name}
                </Text>
                <Flex alignItems="center">
                  <Tooltip
                    hasArrow
                    variant="dominoChat"
                    label={errorHandler(chat?.lastMessage?.errorCode)}
                    openDelay={500}
                    placement="top"
                    background="#FEF6DC"
                  >
                    <Box>
                      <Image w={4} as={Circle} alt="" />
                    </Box>
                  </Tooltip>
                  <Text minW="max-content" noOfLines={2} variant="extraSmall" color="darkGrey" lineHeight="14px" textAlign="end">
                    {fromNowChat(chat.lastMessage?.date, lng, true)}
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <Flex width="100%" justify="space-between" alignItems={"center"}>
                <Text noOfLines={1} fontSize={"14px"} lineHeight={"143%"} wordBreak="break-all" inlineSize={"81%"}>
                  {chat.name}
                </Text>
                <Text minW="max-content" noOfLines={2} variant="extraSmall" color="darkGrey" lineHeight="14px" textAlign="end">
                  {fromNowChat(chat.lastMessage?.date, lng, true)}
                </Text>
              </Flex>
            )}
          </>
        )}
        <Flex width="98%" justify="space-between">
          {isSupported ? (
            <Flex width="90%" gap="4px" alignItems="center">
              {chat?.lastMessage?.isContact && <span className={s.lastMessageContact}>{"Contact"}</span>}
              {chat?.lastMessage?.isPhoto && <ImageMessageIcon />}
              {chat?.lastMessage?.isDocument && <DocumentMessageIcon />}
              <Tooltip variant="dominoLight" label={chat?.lastMessage?.text} openDelay={500} placement="bottom-start">
                <Text noOfLines={1} width="100%" fontSize={"14px"} lineHeight={"143%"} color="midDeepBlue" height="fit-content">
                  {chat?.lastMessage?.text || (!chat?.lastMessage && cx("There's nothing here yet"))}
                </Text>
              </Tooltip>
            </Flex>
          ) : (
            <Flex width="90%" gap="4px" alignItems="center">
              <Tooltip variant="dominoLight" label={t("Message format not available")} openDelay={500} placement="bottom-start">
                <Text noOfLines={1} width="100%" fontSize={"14px"} lineHeight={"143%"} color="midDeepBlue" height="fit-content">
                  {t("Message format not available")}
                </Text>
              </Tooltip>
            </Flex>
          )}

          {!!chat?.unreadMessageCount && <div className={s.unreadMessageCount}>{chat?.unreadMessageCount}</div>}
        </Flex>
      </Box>
    </Box>
  );
};
