export enum ProfanityFilterTypeEnum {
  Warning = "Warning",
  Mute = "Mute",
  Block = "Block",
  Exclude = "Exclude",
}

export interface ProfanityFilterRule {
  type: ProfanityFilterTypeEnum;
  forLong?: string | null; // "hh:mm" | "dd.mm:hh"
}

export interface ProfanityFilterModel {
  isEnabled: boolean;
  rules: ProfanityFilterRule[];
  customProfanities: string[];
}
