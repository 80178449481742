import { useTranslation } from "react-i18next";

const VALID_CHARACTERS_REGEX = /^[a-zA-Zа-яА-Я0-9_\s-]*$/;

export const useValidateInput = () => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot.createNew" });

  return (value: string) => {
    if (!value) {
      return t("Fill in the field");
    }

    if (!VALID_CHARACTERS_REGEX.test(value)) {
      return t("The field may only contain letters, digits, and underscores");
    }

    return null;
  };
};
