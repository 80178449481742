import { BotModel } from "../../../../../../../sidebar/SidebarModel";
import { useNavigate } from "react-router-dom";
import { Box, Icon, Tooltip, useDisclosure } from "@chakra-ui/react";
import s from "../../../../../../Conversation.module.scss";
import BotAvatar from "../../../../../../../../assets/icons/sidebar/botIcon.svg?react";

export const BotAvatarComponent = (props: { botInfo: BotModel }) => {
  const navigate = useNavigate();
  const tooltip = useDisclosure();

  const openBot = () => {
    navigate(`/bots/${props.botInfo?.id}`);
  };

  return (
    <Tooltip
      isOpen={tooltip.isOpen}
      label={props.botInfo?.name ?? props.botInfo?.username}
      bg={"#FEF6DC"}
      variant={"dominoLight"}
      placement="top"
    >
      <Box
        className={s.avatarBot}
        onMouseEnter={e => tooltip.onOpen()}
        onMouseLeave={e => tooltip.onClose()}
        onClick={e => openBot()}
      >
        <Icon as={BotAvatar} boxSize="20px" viewBox="0 0 20 20" />
      </Box>
    </Tooltip>
  );
};
