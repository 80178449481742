import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateBotTab } from "./CreateBotTab";
import { ConnectBotTab } from "./ConnectBotTab";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { connectBot } from "../../AddBotSlice";
import { selectNotification } from "../../../../common/notifications/NotificationSlice";
import useGA from "../../../../common/ga/GAEventTracker";
import { BotIndexTabNames } from "../../AddBotModel";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { BotAddEvents, BotTabsEvents } from "../../../../common/ga/gaEventsEnums.ts/BotGAEventsEnums";
import s from "../../components/CreateConnectBot/CreateConnectBotContainer.module.scss";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useNavigate } from "react-router-dom";

interface Props {
  onConnect?: (token: string) => void;
}

export const CreateConnectBotContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const creactTabTitle = useBreakpointValue({
    base: t("Create Bot"),
    md: t("Create New Bot"),
  });
  const connectTabTitle = useBreakpointValue({
    base: t("Connect Bot"),
    md: t("Connect Existing Bot"),
  });

  const trackEvent = useGA(EventCategories.Bot);
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);
  const notificationType = notification?.type;
  const tokenExample = "7013558025:AAG5jNdQSyEH9I4SCT1FrDQ-0Zk35iaS0YQ";
  const [tab, setTab] = useState<number>(BotIndexTabNames.CREATE);
  const [token, setToken] = useState<string>("");
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false);
  const isLoading = useGetLoadingState("connectBot");
  const navigate = useNavigate();

  const handleTabChange = (index: number) => {
    setToken("");
    setIsTokenInvalid(false);
    if (index === BotIndexTabNames.CREATE) {
      trackEvent(BotTabsEvents.BotCreateTab);
      setTab(BotIndexTabNames.CREATE);
    } else if (index === BotIndexTabNames.CONNECT) {
      trackEvent(BotTabsEvents.BotConneсtTab);
      setTab(BotIndexTabNames.CONNECT);
    }
  };

  const onChangeToken = (e: { target: { value: SetStateAction<string> } }) => {
    setIsTokenInvalid(false);
    setToken(e.target.value);
  };

  useEffect(() => {
    if (notificationType === "error") {
      setIsTokenInvalid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const onConnect = (token: string) => {
    tab === BotIndexTabNames.CREATE ? trackEvent(BotAddEvents.BotCreate) : trackEvent(BotAddEvents.BotConneсt);
    dispatch(connectBot({ token, tab }));
  };

  return (
    <Flex className={s.containerAddBot} justify="center" pt="32px">
      <Box className={s.tabsContainer}>
        <Tabs isLazy={true} isFitted variant="enclosed" onChange={handleTabChange}>
          <TabList>
            <Tab
              _hover={{ color: "black" }}
              _selected={{
                bg: "white",
                borderBottom: "none",
                color: "black",
              }}
            >
              {creactTabTitle}
            </Tab>
            <Tab _hover={{ color: "black" }} _selected={{ bg: "white", borderBottom: "none", color: "black" }}>
              {connectTabTitle}
            </Tab>
          </TabList>
          <TabPanels className={s.tabsPanels}>
            <TabPanel className={s.tabPanel}>
              <CreateBotTab />
            </TabPanel>
            <TabPanel className={s.tabPanel}>
              <ConnectBotTab />
            </TabPanel>
            <FormControl mt="24px">
              <FormLabel className={s.tgFormLabel}>{t("Telegram bot token")}</FormLabel>
              <Input
                className={`${isTokenInvalid ? s.invalidTokenValidation : ""} ${s.tokenInput} `}
                value={token}
                onChange={onChangeToken}
                placeholder={tokenExample}
              />
            </FormControl>
            <Flex className={s.buttons}>
              <Button isLoading={isLoading} onClick={() => navigate("/addbot")} variant="dominoOutlineViolet">
                <span>{ct("Cancel")}</span>
              </Button>
              <Button isLoading={isLoading} onClick={() => onConnect(token)} variant="dominoViolet">
                <span>{t("Connect")}</span>
              </Button>
            </Flex>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};
