import { Flex, Grid, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import DotMenuContainer, { MenuItemsDot } from "../../../../../../../../../../UI/atoms/DotMenu/DotMenuContainer";
import GroupExclude from "../../../../../../../../../../assets/icons/groupExclude.svg?react"; // TODO: removed for DD-5223
import BlockIcon from "../../../../../../../../../../assets/icons/blockIcon.svg?react";
import ProfanityFilterIcon from "../../../../../../../../../../assets/icons/profanityFilterIcon.svg?react";
import UnmuteIcon from "../../../../../../../../../../assets/icons/unmuteIcon.svg?react";
import UnblockIcon from "../../../../../../../../../../assets/icons/revote.svg?react";
import s from "./GroupChatMemberListItem.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNowWidthView } from "../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { MemberRoleEnum, MemberStatusEnum } from "../../../../GroupChatMemberListModel";
import { OverflowEllipsesText } from "../../../../../../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { GroupChatMemberListMuteModal } from "../GroupChatMemberListMuteModal/GroupChatMemberListMuteModal";
import { GroupChatMemberListBlockModal } from "../GroupChatMemberListBlockModal/GroupChatMemberListBlockModal";
import { GroupChatMemberListDeleteModal } from "../GroupChatMemberListDeleteModal/GroupChatMemberListDeleteModal";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../../../../../../../../common/state/store";
import { selectMemberList, unblockMember, unmuteMember } from "../../../../GroupChatMemberListSlice";
import { selectBots, selectCurrentBot } from "../../../../../../../../../sidebar/SidebarSlice";
import { selectConversationInfo } from "../../../../../GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSlice";
import { ConversationTypeEnum } from "../../../../../../../../../conversation/ConversationModel";
import { fromNow } from "../../../../../../../../../../common/utils/fromNow";
import { selectLanguage } from "../../../../../../../../../../common/user/UserSlice";
import useGA from "../../../../../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsChatMembersEvents } from "../../../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

const LONG_DASH = "\u2013";

export const GroupChatMemberListItem = React.memo(function GroupChatMemberListItem({
  firstDate,
  lastDate,
  role,
  status,
  username,
  id,
  isBot,
  externalId,
}: {
  username: string;
  role: null | MemberRoleEnum;
  status: null | MemberStatusEnum;
  firstDate: string;
  lastDate: string;
  id: string;
  isBot: boolean;
  externalId: string;
}) {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.groupChatInfo.MembersPage" }).t;
  const modalMute = useDisclosure();
  const modalBlocked = useDisclosure();
  const modalDelete = useDisclosure();
  const nowWidth = useNowWidthView();
  const currentBot = useAppSelector(selectCurrentBot);
  const botList = useAppSelector(selectBots);
  const memberList = useAppSelector(selectMemberList);
  const isMobile = ((nowWidth > 769 && nowWidth < 1040) || nowWidth < 480) && nowWidth !== 0;
  const dispatch = useAppDispatch();
  const conversationInfo = useAppSelector(selectConversationInfo);
  const lng = useAppSelector(selectLanguage);
  const trackEvent = useGA(EventCategories.Group);

  const orgBotsThatAreMembers =
    botList && memberList ? botList.items.filter(bot => memberList?.items?.some(member => member.username === bot.username)) : [];
  const hasMatchingUsername = orgBotsThatAreMembers.some(bot => bot.username === username);
  const isAdminOrOwner = role === MemberRoleEnum.Administrator || role === MemberRoleEnum.Owner || hasMatchingUsername;

  const menuItems: MenuItemsDot = [
    {
      isActive: true,
      MenuItem:
        conversationInfo?.conversationType === ConversationTypeEnum.Supergroup
          ? {
              icon: (
                <>
                  {status === MemberStatusEnum.Restricted ? (
                    <UnmuteIcon width="18px" height="18px" />
                  ) : (
                    <ProfanityFilterIcon width="18px" height="18px" />
                  )}
                </>
              ),
              clickEvent: e => {
                if (status === MemberStatusEnum.Restricted) {
                  dispatch(unmuteMember({ memberId: id, memberName: username, botId: currentBot?.id || "" }));
                  trackEvent(GroupsChatMembersEvents.GroupsChatMembersRefresh);
                } else {
                  modalMute.onOpen();
                }
                e.stopPropagation();
              },
              text: `${
                status === MemberStatusEnum.Restricted
                  ? groupChatInfoTranslation("Unmute")
                  : groupChatInfoTranslation("Mute for 24 hours", { username: "" })
              }`,
              isDisabled:
                status === MemberStatusEnum.Banned ||
                status === MemberStatusEnum.Left ||
                role === MemberRoleEnum.Administrator ||
                role === MemberRoleEnum.Owner ||
                externalId === currentBot?.externalId,
            }
          : undefined,
    },
    {
      isActive: true,
      MenuItem:
        conversationInfo?.conversationType === ConversationTypeEnum.Supergroup
          ? {
              icon: (
                <>
                  {status === MemberStatusEnum.Banned ? (
                    <UnblockIcon width="18px" height="18px" />
                  ) : (
                    <BlockIcon width="18px" height="18px" />
                  )}
                </>
              ),
              clickEvent: e => {
                if (status === MemberStatusEnum.Banned) {
                  dispatch(unblockMember({ memberId: id, memberName: username, botId: currentBot?.id || "" }));
                  trackEvent(GroupsChatMembersEvents.GroupsChatMembersUnblock);
                } else {
                  modalBlocked.onOpen();
                }
                e.stopPropagation();
              },
              text: `${
                status === MemberStatusEnum.Banned ? groupChatInfoTranslation("Unblock") : groupChatInfoTranslation("Block")
              }`,
              isDisabled:
                status === MemberStatusEnum.Restricted ||
                role === MemberRoleEnum.Administrator ||
                role === MemberRoleEnum.Owner ||
                externalId === currentBot?.externalId,
            }
          : undefined,
    },
    {
      isActive: true,
      MenuItem:
        conversationInfo?.conversationType === ConversationTypeEnum.Group
          ? {
              color: "mainRed",
              icon: <GroupExclude width="20px" height="20px" />,
              clickEvent: e => {
                modalDelete.onOpen();
                e.stopPropagation();
              },
              text: groupChatInfoTranslation("Exclude"),
              isDisabled:
                status === MemberStatusEnum.Restricted ||
                role === MemberRoleEnum.Administrator ||
                role === MemberRoleEnum.Owner ||
                status === MemberStatusEnum.Left ||
                externalId === currentBot?.externalId,
            }
          : undefined,
    },
    // { // TODO: removed for DD-5223
    //   isActive: true,
    //   MenuItem: {
    //     color: "mainRed",
    //     icon: <RedTrashIcon width="18px" height="18px" />,
    //     clickEvent: e => {
    //       modalDelete.onOpen();
    //       e.stopPropagation();
    //     },
    //     text: groupChatInfoTranslation("Delete"),
    //     isDisabled: role === MemberRoleEnum.Administrator || role === MemberRoleEnum.Owner,
    //   },
    // },
  ];

  const formattedFirstDate = dayjs(firstDate).format("DD.MM.YYYY HH:mm");
  const formattedLastDate = !!new Date(lastDate).getTime() ? dayjs(lastDate).format("DD.MM.YYYY HH:mm") : LONG_DASH;
  const diffDate = fromNow(lastDate || firstDate, lng).firstCharToUpperCase();

  return (
    <>
      {isMobile ? (
        <Flex className={s.memberListItemContainerMobile}>
          <Flex className={s.mobileFlex}>
            <Text noOfLines={1} variant="medium">
              {groupChatInfoTranslation("Username")}
            </Text>
            {/* <Text noOfLines={1} variant="medium">
              {groupChatInfoTranslation("The role")}
            </Text> */}
            <Text noOfLines={1} variant="medium">
              {groupChatInfoTranslation("Status")}
            </Text>
            <Flex flexDirection="column">
              <Text variant="medium" noOfLines={1}>
                {groupChatInfoTranslation("First/Last")}
              </Text>
              <Text variant="medium" noOfLines={1}>
                {groupChatInfoTranslation("activity")}
              </Text>
            </Flex>
          </Flex>
          <Flex gap="8px" flex={1} flexDirection="column">
            <Flex align="center" gap="4px">
              <Text variant="medium" noOfLines={1}>
                {username}
              </Text>
              {isBot && (
                <Text className={s.botTag} variant="small">
                  {groupChatInfoTranslation("Bot")}
                </Text>
              )}
            </Flex>
            {/* <Text variant="medium">{groupChatInfoTranslation(`roleEnum.${role}`)}</Text> */}
            <Text variant="medium">{groupChatInfoTranslation(`statusEnum.${status}`)}</Text>
            <Flex flexDirection="column">
              <OverflowEllipsesText
                text={formattedFirstDate}
                isOnlyText
                content={
                  <Text variant="medium" noOfLines={1}>
                    {formattedFirstDate}
                  </Text>
                }
              />
              <OverflowEllipsesText
                text={formattedLastDate}
                isOnlyText
                content={
                  <Text variant="medium" noOfLines={1}>
                    {formattedLastDate}
                  </Text>
                }
              />
            </Flex>
          </Flex>
          {!isAdminOrOwner && <DotMenuContainer menuItems={menuItems} />}
        </Flex>
      ) : (
        <Grid className={s.memberListItemContainerDesktop}>
          <Flex align="center" gap="4px">
            <Text variant="medium" noOfLines={1}>
              {username}
            </Text>
            {isBot && (
              <Text className={s.botTag} variant="small">
                {groupChatInfoTranslation("Bot")}
              </Text>
            )}
          </Flex>
          {/* <Text variant="medium" noOfLines={1}>
            {groupChatInfoTranslation(`roleEnum.${role}`)}
          </Text> */}
          <Text variant="medium">{groupChatInfoTranslation(`statusEnum.${status}`)}</Text>
          <Flex className={s.activeDesktop}>
            <Tooltip
              shouldWrapChildren
              label={
                <Flex flexDirection="column">
                  <Text>
                    {groupChatInfoTranslation(`First`)}: {formattedFirstDate}
                  </Text>
                  <Text>
                    {groupChatInfoTranslation(`Last`)}: {formattedLastDate}
                  </Text>
                </Flex>
              }
              placement="top"
              variant={"dominoChat"}
              bg={"#FEF6DC"}
              hasArrow={true}
              openDelay={100}
            >
              <Text variant="medium" noOfLines={1}>
                {diffDate}
              </Text>
            </Tooltip>
            {!isAdminOrOwner && <DotMenuContainer menuItems={menuItems} />}
          </Flex>
        </Grid>
      )}

      <GroupChatMemberListMuteModal memberId={id} username={username} isOpen={modalMute.isOpen} onClose={modalMute.onClose} />
      <GroupChatMemberListBlockModal
        memberId={id}
        username={username}
        isOpen={modalBlocked.isOpen}
        onClose={modalBlocked.onClose}
      />
      <GroupChatMemberListDeleteModal
        memberId={id}
        username={username}
        isOpen={modalDelete.isOpen}
        onClose={modalDelete.onClose}
      />
    </>
  );
});
