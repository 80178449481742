import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { groupChatListSlice, selectGroupChatList } from "./GroupChatListSlice";
import { handleException } from "../../../../common/SagaHelper";
import {
  CHAT_LIST_FILTER_VARIANTS,
  ChatListFilterTypes,
  ChatListSortEnum,
  OverLimitConversationsModel,
} from "../../../chatList/ChatListModel";
import { beginScope, completeScope } from "../../../../common/loading/LoadingStateActions";
import { getFilterFromStorage } from "../../../chatList/ChatListSaga";
import { store } from "../../../..";
import * as GroupChatListApi from "./GroupChatListApi";
import { GroupChatListData } from "../../GroupChatData";
import {
  mapConversationInfoDataToModel,
  mapConversationInfoModelToModelList,
  mapGroupChatListDataToModel,
} from "../../GroupChatListMapper";
import { signalRConnection } from "../../../../middlewares/signalRMiddleware";
import { ConversationInfoData } from "../../../conversation/ConversationData";
import { GroupChatListItemModel } from "../../GroupChatModel";

import dayjs from "dayjs";
import { chatListSlice } from "../../../chatList/ChatListSlice";
import { OverLimitConversationsData } from "../../../chatList/ChatListData";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { BotModel } from "../../../sidebar/SidebarModel";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../../../common/ga/GAEventTracker";
import { isMacOs } from "react-device-detect";
import { GroupsAddMacEvents, GroupsAddWinEvents } from "../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export function* groupChatListSaga() {
  yield takeLatest(groupChatListSlice.actions.getGroupChatList, getGroupChatList);
  yield takeLatest(groupChatListSlice.actions.getGroupChatListCount, getGroupChatListCount);
  yield takeLatest(groupChatListSlice.actions.addNewConversation, addConversation);
  yield takeLatest(groupChatListSlice.actions.addChat, chatListReorder);
  yield takeEvery(groupChatListSlice.actions.updateChatInfo, chatListReorder);
  yield takeLatest(chatListSlice.actions.getOverLimitConversations, getOverLimitConversations);

  signalRConnection.addHandler("receiveGroupConversation", (data: ConversationInfoData) => {
    const currentBotId = store.getState().app.sidebarState.bot?.id;
    if (data.botId !== currentBotId) {
      return;
    }

    const chatList = store.getState().app.groupChatListState.chatList;
    const search = store.getState().app.groupChatListState.search;
    let overLimitConversations = store.getState().app.chatListState.overLimitedConversations;
    if (data.isOverLimited && data.botId === currentBotId) {
      overLimitConversations = [
        ...(overLimitConversations?.filter(x => x.conversationId !== data.id) ?? []),
        {
          conversationId: data.id,
        },
      ];
      store.dispatch(chatListSlice.actions.getOverLimitConversationsCompleted(overLimitConversations));
      return;
    }
    const isItemChatList = chatList?.items.find(el => el.id === data.id);
    const itemModel = mapConversationInfoModelToModelList(data);
    const groupChatInfo = mapConversationInfoDataToModel(data);
    if (isItemChatList && chatList) {
      const newChatListItems = chatList.items.map(el => {
        if (el.id === data.id) {
          const newElem: GroupChatListItemModel = {
            ...el,
            lastMessage: data.lastMessage,
            unreadMessageCount: data.unreadMessageCount,
            group: data.group,
            groupId: data.groupId,
          };
          return newElem;
        }
        return el;
      });
      store.dispatch(groupChatListSlice.actions.getGroupChatListCompleted({ ...chatList, items: newChatListItems }));
    } else {
      if (!search) {
        store.dispatch(groupChatListSlice.actions.addNewConversation(itemModel));
        const trackEvent = GTM(EventCategories.Group);
        trackEvent(isMacOs ? GroupsAddMacEvents.GroupsAddMacFinish : GroupsAddWinEvents.GroupsAddWinFinish);
      }
    }
    const conversationInfoNow = store.getState().app.conversationState.conversationInfo;
    if (conversationInfoNow?.id === groupChatInfo.id) {
      //store.dispatch(groupChatInfoSlice.actions.getGroupChatInfoCompleted());
      if (data.lastMessage.isPhoto) {
        if (
          data.action !== "Assignment" &&
          data.action !== "Closed" &&
          data.status !== "Closed" &&
          data.lastMessage.status === "NotDefined"
        ) {
          //store.dispatch(groupChatListSlice.actions.incMediaFilesCompleted());
        }
      }
      if (data.lastMessage.isDocument) {
        //store.dispatch(groupChatListSlice.actions.incDocFilesCompleted());
      }
    }
  });
}

function getQueryStringFromParams(params: {
  search: string;
  sort?: ChatListSortEnum;
  filter?: { type: ChatListFilterTypes; teamId?: string };
  lastId: string;
}) {
  const filter = { type: "all", teamId: undefined }; //params.filter || { type: "all" }
  const filterParams = CHAT_LIST_FILTER_VARIANTS.all; //CHAT_LIST_FILTER_VARIANTS[filter.type]
  const filterQueryString =
    filterParams?.field !== ""
      ? `filters[0].field=${filterParams.field}&filters[0].condition=${filterParams.condition}${
          filterParams.value.length ? `&filters[0].value=${filter.teamId ?? filterParams.value}` : ""
        }`
      : "";
  const searchQueryString = `filter=${params.search}&filterBy=Name`;
  return `?${filterQueryString}&orderBy=LastMessageDate&orderDirection=${params.sort}&${searchQueryString}${
    params.lastId ? "&lastId=" + params.lastId : "1"
  }&size=14`;
}

function getQueryStringFromParamsCount(params: {
  search: string;
  sort?: ChatListSortEnum;
  filter?: { type: ChatListFilterTypes; teamId?: string };
  lastId: string;
}) {
  const filter = { type: "all", teamId: undefined }; //params.filter || { type: "all" }
  const filterParams = CHAT_LIST_FILTER_VARIANTS.all; //CHAT_LIST_FILTER_VARIANTS[filter.type]
  const filterQueryString =
    filterParams?.field !== ""
      ? `filters[0].field=${filterParams.field}&filters[0].condition=${filterParams.condition}${
          filterParams.value.length ? `&filters[0].value=${filter.teamId ?? filterParams.value}` : ""
        }`
      : "";
  const searchQueryString = `filter=${params.search}&filterBy=Name`;
  return `?${filterQueryString}&orderBy=LastMessageDate&orderDirection=${params.sort}&${searchQueryString}${
    params.lastId ? "&lastId=" + params.lastId : "1"
  }&size=14`;
}

function* getGroupChatList(
  action: PayloadAction<{
    botId: string;
    search: string;
    sort?: ChatListSortEnum;
    filter?: { type: ChatListFilterTypes; teamId?: string };
    lastId: string;
  }>,
) {
  try {
    yield put(beginScope("getGroupChatList"));
    const { search, sort, filter, lastId } = action.payload;
    const filterFromStorage = getFilterFromStorage();
    const prevChatListItems = lastId === "1" ? [] : store.getState().app.groupChatListState.chatList?.items;
    const data: GroupChatListData = yield call(
      GroupChatListApi.getGroupConversationList,
      action.payload.botId,
      getQueryStringFromParams({ search, sort, filter: filterFromStorage ?? filter, lastId }),
    );

    const chatModel = mapGroupChatListDataToModel(data, 0);
    yield put(groupChatListSlice.actions.updateConversationsUploading(true));
    if (!data.items.length && lastId !== "1") {
      yield put(groupChatListSlice.actions.updateConversationsUploading(false));
      return;
    }

    const newConcatedItems = prevChatListItems?.concat(chatModel.items);
    const dictionaryCounts = {} as Record<string, number>;
    newConcatedItems?.forEach(item => {
      if (dictionaryCounts?.[item.id as string] !== undefined) {
        dictionaryCounts[item.id as string] += 1;
      } else {
        dictionaryCounts[item.id] = 1;
      }
    });
    const concatedItems = lastId ? newConcatedItems?.filter(item => dictionaryCounts[item.id] === 1) : chatModel.items;
    const concatedChatModel = {
      ...chatModel,
      items: concatedItems ?? chatModel.items,
    };
    yield put(groupChatListSlice.actions.getGroupChatListCompleted(concatedChatModel));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getGroupChatList"));
  }
}

function* getGroupChatListCount(
  action: PayloadAction<{
    botId: string;
    search: string;
    sort?: ChatListSortEnum;
    filter?: { type: ChatListFilterTypes; teamId?: string };
    lastId: string;
  }>,
) {
  try {
    yield put(beginScope("getGroupChatListCount"));
    const { search, sort, filter, lastId } = action.payload;
    const filterFromStorage = getFilterFromStorage();
    const { count }: { count: number } = yield call(
      GroupChatListApi.getGroupConversationCount,
      action.payload.botId,
      getQueryStringFromParamsCount({ search, sort, filter: filterFromStorage ?? filter, lastId }),
    );
    yield put(groupChatListSlice.actions.getGroupChatListCountCompleted(count));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getGroupChatListCount"));
  }
}

function* addConversation(action: PayloadAction<GroupChatListItemModel>) {
  try {
    //const chatList: ReturnType<typeof selectGroupChatList> = yield select(selectGroupChatList);
    //const currentBotId: ReturnType<typeof selectCurrentBotId> = yield select(selectCurrentBotId);
    //const searchText: ReturnType<typeof selectSearchText> = yield select(selectSearchGroupChatList);
    //const contact = action.payload.contact;
    //const searchTextLowerCase = searchText.toLowerCase();
    // const isConversationSuitSearch =
    //   contact?.firstName?.toLocaleLowerCase().includes(searchTextLowerCase) ||
    //   contact?.lastName?.toLocaleLowerCase().includes(searchTextLowerCase) ||
    //   contact?.username?.toLocaleLowerCase().includes(searchTextLowerCase) ||
    //   action.payload.name.toLocaleLowerCase().includes(searchTextLowerCase);
    //let matchFilter = false;
    //const isIncludedItem = chatList?.items.some((item: { id: string }) => item?.id === action.payload?.id);
    //const isNewConversation = action.payload.isNew;
    // if (!matchFilter && isIncludedItem) {
    //   yield put(groupChatListSlice.actions.removeChat(action.payload));
    //   return;
    // } else if (
    //   isNewConversation &&
    //   matchFilter &&
    //   isConversationSuitSearch &&
    //   !isIncludedItem &&
    //   currentBotId === action.payload.botId
    // ) {
    //   yield put(groupChatListSlice.actions.addChat(action.payload));
    // } else if (matchFilter && currentBotId === action.payload.botId && isConversationSuitSearch && !isIncludedItem) {
    //   yield put(groupChatListSlice.actions.addChat(action.payload));
    // }
    yield put(groupChatListSlice.actions.addChat(action.payload));
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* chatListReorder() {
  try {
    const chatList: ReturnType<typeof selectGroupChatList> = yield select(selectGroupChatList);
    if (chatList) {
      const updatedChatListItems = [...chatList.items];
      updatedChatListItems.sort((item2, item1) => {
        const compareDate1 = item1.lastMessage?.date == null ? item1?.createDate : item1.lastMessage?.date;
        const compareDate2 = item2.lastMessage?.date == null ? item2?.createDate : item2.lastMessage?.date;
        const sortItem1BeforeItem2 = dayjs(compareDate1).isBefore(dayjs(compareDate2));
        const sortItem1AfterItem2 = dayjs(compareDate1).isAfter(dayjs(compareDate2));
        return sortItem1BeforeItem2 ? -1 : sortItem1AfterItem2 ? 1 : 0;
      });
      yield put(
        groupChatListSlice.actions.getGroupChatListCompleted({
          ...chatList,
          items: updatedChatListItems,
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
  }
}

function* getOverLimitConversations() {
  try {
    const bot: BotModel = yield select(selectCurrentBot);
    const data: OverLimitConversationsData = yield call(GroupChatListApi.getOverLimitConversations, bot.id);

    const model: OverLimitConversationsModel[] = data.conversationIds.map(x => {
      return {
        conversationId: x,
      } as OverLimitConversationsModel;
    });

    yield put(chatListSlice.actions.getOverLimitConversationsCompleted(model));
  } catch (e: unknown) {
    handleException(e);
  }
}
