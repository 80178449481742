import { Dispatch, SetStateAction } from "react";
import { ProfanityFilterModel, ProfanityFilterRule } from "../../ProfanityFilterModel";
import { saveChanges } from "../../ProfanityFilterSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { CUSTOM_PROFANITIES_SPLITTER, PROFANITY_FILTER_DEFAULT } from "./consts";
import { GroupsChatProfanityEvents } from "../../../../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

interface ButtonsHandlers {
  profanityFilter: ProfanityFilterModel;
  rulesCopy: ProfanityFilterRule[];
  customProfanities: string;
  dispatch: Dispatch<AnyAction>;
  setCustomProfanities: Dispatch<SetStateAction<string>>;
  setRulesCopy: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
  setWasReset: Dispatch<SetStateAction<boolean>>;
  trackEvent: (action: string, label?: string, data?: unknown) => void;
}
export interface ButtonsHandlersReturnedValue {
  cancelChanges: () => void;
  resetToDefault: () => void;
  saveChanges: () => void;
}

export const profanityFilterButtonsHandlers = ({
  profanityFilter,
  rulesCopy,
  customProfanities,
  dispatch,
  setCustomProfanities,
  setRulesCopy,
  setWasReset,
  trackEvent,
}: ButtonsHandlers): ButtonsHandlersReturnedValue => ({
  cancelChanges: () => {
    setRulesCopy(profanityFilter.rules);
    setCustomProfanities(profanityFilter.customProfanities.join(CUSTOM_PROFANITIES_SPLITTER));

    setWasReset(false);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanityCancel);
  },
  resetToDefault: () => {
    setRulesCopy(PROFANITY_FILTER_DEFAULT.rules);
    setCustomProfanities(PROFANITY_FILTER_DEFAULT.customProfanities.join(CUSTOM_PROFANITIES_SPLITTER));

    setWasReset(true);
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanityReset);
  },
  saveChanges: () => {
    const parsedCustomProfanities = customProfanities.trim().split(CUSTOM_PROFANITIES_SPLITTER);
    dispatch(
      saveChanges({
        ...profanityFilter,
        rules: rulesCopy,
        customProfanities: parsedCustomProfanities,
      }),
    );
    trackEvent(GroupsChatProfanityEvents.GroupsChatProfanitySave, "", { customProfanities: parsedCustomProfanities });
  },
});
