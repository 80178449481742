import s from "./AddNodePopup.module.scss";
import { useReactFlow, Viewport } from "reactflow";
import { v1 as uuidv1 } from "uuid";
import {
  AssignFlowActionModel,
  ConditionFlowActionModel,
  CurlFlowActionModel,
  DelayFlowActionModel,
  FlowActionModel,
  FlowActionType,
  NodeDefaultSize,
  NodeModel,
  NodeType,
  QuestionFlowActionModel,
  QuestionActionType,
  SendAudioFlowActionModel,
  SendDocumentFlowActionModel,
  SendPhotoFlowActionModel,
  SendTextFlowActionModel,
  SendVideoFlowActionModel,
  SystemActionFlowActionModel,
  TimeUnits,
  CalendarFlowActionModel,
  PaymentFlowActionModel,
  ShoppingCartFlowActionModel,
  AiFlowActionModel,
} from "../../FlowBuilderModel";
import { useTranslation } from "react-i18next";
import { getRandomRange } from "../../../../common/utils";
import { StartFlowActionModel } from "../../FlowBuilderModel";
import { Box, Icon } from "@chakra-ui/react";
import { ButtonType, ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../../../common/AppEnums";
import { v4 as uuidv4 } from "uuid";
import { ReplyKeyboardButtonModel, ReplyKeyboardMarkupModel } from "../../../../common/AppButtonsModel";
import { EventCategories } from "../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import { FlowEvents } from "../../../../common/ga/gaEventsEnums.ts/FlowGAEventsEnums";
import { AppSettings } from "../../../../common/AppSettings";

declare const appSettings: AppSettings;

type As = React.ElementType;

interface Props {
  addNode: (newNode: NodeModel) => void;
  iconType: As | undefined;
  title: string;
  actionType: keyof typeof FlowActionType;
  flowBuilderWidth: number;
  flowBuilderHeight: number;
  isDisabled?: boolean;
}

export const NodeItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const commonWords = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { getViewport } = useReactFlow();
  const trackEvent = useGAWithCustomParameters(EventCategories.Flow);

  const generateNode = (type: NodeType, typeDiscriminator: keyof typeof FlowActionType) => {
    let nodeData: FlowActionModel | null = null;

    if (typeDiscriminator === FlowActionType.SendTextFlowAction) {
      nodeData = {
        text: "",
        typeDiscriminator,
        file: null,
      } as SendTextFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.QuestionFlowAction) {
      nodeData = {
        customVariableIds: [],
        typeDiscriminator,
        retryCount: 3,
        file: null,
        questionActionType: QuestionActionType.Text,
      } as QuestionFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendPhotoFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendPhotoFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendDocumentFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendDocumentFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.CurlFlowAction) {
      nodeData = {
        typeDiscriminator,
        targetCustomVariables: [{ contentPath: "", targetCustomVariableId: null }],
      } as CurlFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SendVideoFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendVideoFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SendAudioFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SendAudioFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.ConditionFlowAction) {
      nodeData = {
        typeDiscriminator,
        conditions: [{}],
      } as ConditionFlowActionModel;
    }

    if (typeDiscriminator === FlowActionType.SystemActionFlowAction) {
      nodeData = {
        typeDiscriminator,
      } as SystemActionFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.DelayFlowAction) {
      nodeData = {
        typeDiscriminator,
        unit: TimeUnits.Minutes,
        value: "",
      } as DelayFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.AssignFlowAction) {
      nodeData = {
        typeDiscriminator,
        operatorId: null,
        operatorName: null,
        teamId: null,
        teamName: null,
      } as AssignFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.StartSubFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
      } as StartFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendAppointmentFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
      } as CalendarFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.SendPaymentFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
        gap: { hours: 0, minutes: 15 },
      } as PaymentFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.ShoppingCartFlowAction) {
      const replyButton: ReplyKeyboardButtonModel = {
        id: uuidv4(),
        label: t("editNodePopup.Name your order button"),
        typeDiscriminator: ButtonTypeDiscriminator.FlowReplyKeyboardButton,
        targetNodeId: "",
        type: ButtonType.CallbackButton,
        ...{ requestOrder: true },
      };

      const replyKeyboardMarkup: ReplyKeyboardMarkupModel = {
        buttons: [[replyButton]],
        typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
      };

      nodeData = {
        typeDiscriminator,
        flowId: "",
        replyMarkup: replyKeyboardMarkup,
      } as ShoppingCartFlowActionModel;
    }
    if (typeDiscriminator === FlowActionType.AiFlowAction) {
      nodeData = {
        typeDiscriminator,
        flowId: "",
        welcomeMessageText: t("Hi! I’m your AI assistant. How can I help you?"),
        closingOptions: {
          closingQuestionText: t("Have I answered your question?"),
          failureButtonText: commonWords("No"),
          successButtonText: commonWords("Yes"),
        },
        operatorOptions: {
          isRequestOperatorEnabled: false,
          operatorRequestedMessageText: t("Our operator is already on their way to help!"),
        },
      } as AiFlowActionModel;
    }
    return nodeData;
  };

  const addNode = (type: NodeType, typeDiscriminator: keyof typeof FlowActionType) => {
    const nodeData = generateNode(type, typeDiscriminator);

    if (nodeData) {
      const viewport = getViewport();
      const newNode: NodeModel = {
        id: uuidv1(),
        type,
        position: convertToNodePosition(viewport),
        flowAction: nodeData,
      };
      props.addNode(newNode);
      trackEvent(FlowEvents.FlowAddNodeFromMenu, "", { typeDiscriminator: typeDiscriminator });
    }
  };

  const convertToNodePosition = (viewport: Viewport) => {
    const marginLeft = (props.flowBuilderWidth - NodeDefaultSize.width * viewport.zoom) / 2;
    const marginTop = (props.flowBuilderHeight - NodeDefaultSize.height * viewport.zoom) / 2;

    const convertedX = (-viewport.x + marginLeft + getRandomRange(20)) / viewport.zoom;
    const convertedY = (-viewport.y + marginTop + getRandomRange(20)) / viewport.zoom;
    return { x: convertedX, y: convertedY };
  };
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    type: NodeType,
    typeDiscriminator: keyof typeof FlowActionType,
  ) => {
    const nodeData = generateNode(type, typeDiscriminator);
    if (nodeData) {
      const newNode: NodeModel = {
        id: uuidv1(),
        type,
        position: { x: 0, y: 0 },
        flowAction: nodeData,
      };
      event.dataTransfer.setData("application/json", JSON.stringify(newNode));
      event.dataTransfer.effectAllowed = "move";
    }
  };

  return (
    <Box
      onClick={() => addNode(NodeType.Action, props.actionType)}
      className={`${s.typeItem} ${props.isDisabled ? s.disabled : ""}`}
      onDragStart={event => onDragStart(event, NodeType.Action, props.actionType)}
      draggable
      data-pw={props.title}
    >
      <Icon className={s.svg} as={props.iconType} />
      <Box className={s.typeName}>{t(props.title)}</Box>
      {appSettings.newItemsPointer?.find(el => el === props.title) && <Box className={s.iconPointer}></Box>}
    </Box>
  );
};
