import { Paginator } from "../Paginator";
import { useSearchParams } from "react-router-dom";
import { Box, ResponsiveValue } from "@chakra-ui/react";
import { OrganisationTeamsModel, OrganisationUsersModel } from "../../../components/organisation/OrganisationModel";
import { ContactListPaginatorModel } from "../../../components/contactList/ContactListModel";
import { BotListModel } from "../../../components/sidebar/SidebarModel";
import { BroadcastListModel } from "../../../components/broadcastList/BroadcastListModel";
import { FlowListModel } from "../../../components/flowBuilder/FlowBuilderModel";
import { TriggerListModel } from "../../../components/automation/AutomationModel";
import { ContactVariableListPaginatorModel } from "../../../components/contact/ContactModel";
import { useEffect } from "react";
import { PaginatorModel } from "../PaginatorModel";
import { MemberListPaginatorModel } from "../../../components/groupChat/components/GroupChatMain/components/GroupChatMemberList/GroupChatMemberListModel";

interface Props {
  data:
    | OrganisationUsersModel
    | MemberListPaginatorModel
    | OrganisationTeamsModel
    | ContactListPaginatorModel
    | BotListModel
    | BroadcastListModel
    | FlowListModel
    | TriggerListModel
    | ContactVariableListPaginatorModel;
  typeOfPage: string;
  paddingRight?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  paddingLeft?:
    | ResponsiveValue<number | string | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset">
    | undefined;
  loadingScope?: string;
  sizeItems: number;
}

export const PaginatorWithSearchParams = (props: Props) => {
  const { data, typeOfPage } = props;
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    setSearchParams(prev => {
      if (data.currentPage == 1) {
        prev.delete("page");
        return prev;
      }
      if (data.currentPage >= 1) {
        prev.set("page", data.currentPage.toString());
      }
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentPage]);

  const getPage = (page: number) => {
    setSearchParams(prev => {
      if (page >= 1) {
        prev.set("page", page.toString());
      }
      return prev;
    });
  };

  return (
    <Box position="sticky" bottom="-1px" width="100%">
      {data && (
        <Paginator
          getPage={getPage}
          data={data as PaginatorModel}
          typeOfPage={typeOfPage}
          paddingRight={props.paddingRight}
          paddingLeft={props.paddingLeft}
          loadingScope={props.loadingScope}
          sizeItems={props.sizeItems}
        />
      )}
    </Box>
  );
};
