import s from "./ImageMessageNode.module.scss";
import ImageIconGrey from "../../../../../assets/images/imageIconGrey.svg";
import { AppSettings } from "../../../../../common/AppSettings";
import { ActionNodeProps } from "../ActionNodeProps";
import { SendPhotoFlowActionModel } from "../../../FlowBuilderModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import imageErrorIcon from "../../../../../assets/icons/imageError.svg?react";
import { Icon, Box, Text, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getButtonsWithExactType } from "../../../utils";
import WidgetFill from "../../../../../assets/icons/dialogFill.svg?react";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";

declare const appSettings: AppSettings;

function ImageMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendPhotoFlowActionModel;
  const [imageError, setImageError] = useState(false);
  const currentBot = useAppSelector(selectCurrentBot);

  useEffect(() => setImageError(false), [data]);

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        {currentBot?.channel === "Web" ? (
          <Flex h="32px" w="32px" rounded="full" bg="mainPurple" align="center" justify="center">
            <Icon as={WidgetFill} color="white" boxSize="20px" />
          </Flex>
        ) : (
          <Icon as={TelegramIcon} boxSize="32px" />
        )}
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Image Message")}</div>
        </div>
      </div>
      <div className={s.imageBlockWrapper}>
        <Loading scope={`postFile${props.actionData.node.id}`} />
        {data.fileId && (
          <>
            {!imageError ? (
              <img
                onError={() => setImageError(true)}
                alt=""
                className={`${s.itemImg} ${
                  (data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length) &&
                  s.imgWithText
                }`}
                src={`${appSettings.apiBaseUrl}/file/${data.fileId}`}
                data-pw="image"
              />
            ) : (
              <Flex className={s.imageNotFound}>
                <Box>
                  <Icon
                    as={imageErrorIcon}
                    boxSize="32px"
                    className={`${s.itemImg} ${
                      (data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length) &&
                      s.imgWithText
                    }`}
                    data-pw="image-error-icon"
                  />
                  <Text color="#EB5038 !important" variant="preLargeBold" data-pw="image-error-text">
                    {t("Image not found")}
                  </Text>
                </Box>
              </Flex>
            )}
          </>
        )}
        {data.fileUrl && <MessageButtonBlock actionData={props.actionData} textType="fileUrl" imageType={true} />}
        {!data.file && !data.fileId && !data.fileUrl ? (
          <div
            className={`${s.noMessageWrapper} ${
              (data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length) && s.withText
            }`}
          >
            <img src={ImageIconGrey} alt="" />
            <h4>{data?.fileUrl ?? t("Add Image")} </h4>
          </div>
        ) : (
          ""
        )}
      </div>
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock
          borderRadius={data.fileId && "0px 0px 12px 12px !important"}
          actionData={props.actionData}
          imageType={true}
        />
      ) : (
        ""
      )}
    </Flex>
  );
}

export default ImageMessageNode;
