import { httpPost, httpPut } from "../../common/BaseApi";
import { BotModel } from "../bot/BotModel";

export type CreateWebBotType = Pick<BotModel, "name" | "title">;
export const createWebBot = (data: CreateWebBotType): BotModel => {
  return httpPost("/api/webBot", data) as BotModel;
};

export const updateWebBot = (data: BotModel): BotModel => {
  return httpPut(`/api/webBot`, data) as BotModel;
};
