import { call, put, takeLatest } from "redux-saga/effects";
import { groupChatSlice } from "./GroupChatSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { handleException } from "../../common/SagaHelper";
import * as groupChatApi from "./GroupChatApi";
import { notificationSlice } from "../../common/notifications/NotificationSlice";
import { t } from "i18next";
import { isMacOs } from "react-device-detect";

export function* groupChatSaga() {
  yield takeLatest(groupChatSlice.actions.sendNotification, sendNotification);
}

function* sendNotification(action: PayloadAction<string>) {
  try {
    yield call(groupChatApi.sendNotification, action.payload);
    if (!isMacOs) {
      yield put(
        notificationSlice.actions.notify({
          message: t("groupChat.Your bot connection link has been successfully sent"),
          type: "success",
        }),
      );
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
  }
}
