import { httpPost } from "../../../../../../../common/BaseApi";
import { ProfanityFilterData } from "./ProfanityFilterData";

interface ChangeProfanityFilter {
  profanityFilter: ProfanityFilterData;
  groupId: string;
}
export const changeProfanityFilter = ({ profanityFilter, groupId }: ChangeProfanityFilter) => {
  return httpPost(`/api/group/${groupId}/profanity`, profanityFilter);
};
