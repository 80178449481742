import s from "./DocumentMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { SendDocumentFlowActionModel } from "../../../FlowBuilderModel";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { Flex, Icon } from "@chakra-ui/react";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import FileIcon from "../../../../../assets/icons/fileIcon.svg?react";
import { getButtonsWithExactType } from "../../../utils";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import WidgetFill from "../../../../../assets/icons/dialogFill.svg?react";

function DocumentMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendDocumentFlowActionModel;
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        {currentBot?.channel === "Web" ? (
          <Flex h="32px" w="32px" rounded="full" bg="mainPurple" align="center" justify="center">
            <Icon as={WidgetFill} color="white" boxSize="20px" />
          </Flex>
        ) : (
          <Icon as={TelegramIcon} boxSize="32px" />
        )}
        <div className={s.textBlock}>
          <div className={s.telegram}>{t("Telegram")}</div>
          <div className={s.type}>{t("Document Message")}</div>
        </div>
      </div>
      {data.file?.name || data.fileInfo?.name ? (
        <div data-pw="documentMessageNode" className={s.addedDocument}>
          <Icon as={FileIcon} fill="midDeepBlue" boxSize="25px" />
          <h4>{data.fileInfo?.name ?? data.file?.name}</h4>
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      ) : (
        <div data-pw="documentMessageNode" className={s.noDocument}>
          <Icon as={FileIcon} fill="darkGrey" boxSize="25px" />
          <h4>{t("Add Document")}</h4>
          <Loading scope={`postFile${props.actionData.node.id}`} />
        </div>
      )}
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock actionData={props.actionData} />
      ) : (
        ""
      )}
    </Flex>
  );
}

export default DocumentMessageNode;
