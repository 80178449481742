import { Box, Icon } from "@chakra-ui/react";
import s from "../../../../../../Conversation.module.scss";
import AiAssistantAvatar from "../../../../../../../../assets/icons/sidebar/aiAssistant.svg?react";

export const AiAssistantAvatarComponent = () => {
  return (
    <Box className={s.avatarAiAssistant}>
      <Icon as={AiAssistantAvatar} boxSize="20px" viewBox="0 0 20 20" />
    </Box>
  );
};
