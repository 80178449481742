import { Flex } from "@chakra-ui/react";
import { ProfanityFilterRule, ProfanityFilterTypeEnum } from "../../../../../../ProfanityFilterModel";
import { useTranslation } from "react-i18next";
import { useSelect } from "../../../../../../../../../../../../../common/hooks/useSelect/useSelect";
import { RESTRICTIONS, RESTRICTIONS_NOT_SUPER_GROUP, TIME_FRAME_AMOUNTS, TIME_FRAMES } from "./consts";
import { TimeFramesEnum } from "./types";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { DefaultRuleLayout } from "./components/DefaultRuleLayout";
import { useNowWidthView } from "../../../../../../../../../../../../layout/LayoutHelper/ResolutionHooks";
import { AdaptiveRuleLayout } from "./components/AdaptiveRuleLayout";
import { MAXIMAL_RESTRICTION_COUNT, MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT } from "../../../../consts";
import { RulesButtonsReturnedValue } from "../../handlers";
import { handleRestriction, handleRuleChange } from "./handlers";
import { convertForLongToTimeFrame } from "./utils";
import s from "./Rule.module.scss";
import { ConversationTypeEnum } from "../../../../../../../../../../../../conversation/ConversationModel";
import { useAppSelector } from "../../../../../../../../../../../../../common/state/store";
import { selectConversationInfo } from "../../../../../../../../GroupChatConversation/components/GroupChatMessagesList/GroupChatMessagesListSlice";

export interface RuleProps {
  rule: ProfanityFilterRule;
  position: number;
  handlers: RulesButtonsReturnedValue;
  setRules: Dispatch<SetStateAction<ProfanityFilterRule[]>>;
  rules: ProfanityFilterRule[];
}

export const Rule = ({ rule, position, handlers, setRules, rules }: RuleProps) => {
  const { type, forLong } = rule;
  const t = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityFilterPage",
  }).t;
  const nowWidth = useNowWidthView();
  const isInitRef = useRef(false);
  const isInit = isInitRef.current;

  const timeFrames = forLong ? convertForLongToTimeFrame(forLong) : null;
  const conversationInfo = useAppSelector(selectConversationInfo);

  const restrictionHandler = handleRestriction({ position, setRules });
  const [restriction, onChangeRestriction, translattedRestrictions] = useSelect<ProfanityFilterTypeEnum>({
    options: conversationInfo?.conversationType !== ConversationTypeEnum.Supergroup ? RESTRICTIONS_NOT_SUPER_GROUP : RESTRICTIONS,
    initValue: type,
    translator: t,
    translatePrefix: "typeEnum.",
    onChange: restrictionHandler,
  });

  const [timeFrame, setTimeFrame] = useState<TimeFramesEnum>(timeFrames?.timeFrame ?? TIME_FRAMES[0]);
  const [timeFrameNumber, onChangeTimeFramesAmount] = useSelect({
    options: TIME_FRAME_AMOUNTS[timeFrame],
    initValue: String(timeFrames?.timeFrameAmount ?? ""),
  });
  const [timeFrameValue, onChangeTimeFrame, translattedTimeFrames] = useSelect<TimeFramesEnum>({
    options: TIME_FRAMES,
    translator: t,
    translatePrefix: `timeFramesEnum.`,
    initValue: timeFrame,
    numberForQuantity: Number(timeFrameNumber),
  });
  useEffect(() => {
    if (isInit) {
      ruleHandlers.timeFrame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrameNumber, timeFrameValue]);

  useEffect(() => {
    if (isInit) {
      setTimeFrame(timeFrameValue);
      onChangeTimeFramesAmount(TIME_FRAME_AMOUNTS[timeFrameValue][0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrameValue]);

  useEffect(() => {
    isInitRef.current = true;
  }, []);

  const ruleHandlers = handleRuleChange({ position, restriction, setRules, timeFrameNumber, timeFrameValue });

  const isFirstRule = position === 1;
  const isLastRule = position === rules.length;
  const isDefaultLayout = nowWidth >= MINIMAL_SCREEN_SIZE_FOR_DEFAULT_LAYOUT;
  const isShownAddButton =
    isLastRule &&
    position < MAXIMAL_RESTRICTION_COUNT &&
    type !== ProfanityFilterTypeEnum.Block &&
    type !== ProfanityFilterTypeEnum.Exclude;
  const isShownDeleteButton = rules.length > 1;

  return (
    <Flex className={`${s.ruleWrapper} ${!isFirstRule && !isDefaultLayout ? s.topLine : ""}`}>
      {isDefaultLayout ? (
        <DefaultRuleLayout
          rule={rule}
          position={position}
          handlers={handlers}
          isShownAddButton={isShownAddButton}
          isShownDeleteButton={isShownDeleteButton}
          restrictionSelect={{
            onChange: onChangeRestriction,
            options:
              conversationInfo?.conversationType !== ConversationTypeEnum.Supergroup
                ? RESTRICTIONS_NOT_SUPER_GROUP
                : RESTRICTIONS,
            value: restriction,
            translattedOptions: translattedRestrictions,
          }}
          timeFramesAmountSelect={{
            onChange: onChangeTimeFramesAmount,
            options: TIME_FRAME_AMOUNTS[timeFrame],
            value: timeFrameNumber,
          }}
          timeFrameSelect={{
            onChange: onChangeTimeFrame,
            options: TIME_FRAMES,
            value: timeFrameValue,
            translattedOptions: translattedTimeFrames,
          }}
        />
      ) : (
        <AdaptiveRuleLayout
          rule={rule}
          position={position}
          handlers={handlers}
          isShownAddButton={isShownAddButton}
          isShownDeleteButton={isShownDeleteButton}
          restrictionSelect={{
            onChange: onChangeRestriction,
            options:
              conversationInfo?.conversationType !== ConversationTypeEnum.Supergroup
                ? RESTRICTIONS_NOT_SUPER_GROUP
                : RESTRICTIONS,
            value: restriction,
            translattedOptions: translattedRestrictions,
          }}
          timeFramesAmountSelect={{
            onChange: onChangeTimeFramesAmount,
            options: TIME_FRAME_AMOUNTS[timeFrame],
            value: timeFrameNumber,
          }}
          timeFrameSelect={{
            onChange: onChangeTimeFrame,
            options: TIME_FRAMES,
            value: timeFrameValue,
            translattedOptions: translattedTimeFrames,
          }}
        />
      )}
    </Flex>
  );
};
