import { Box, Flex, FlexProps, Text, Icon, Button } from "@chakra-ui/react";
import { FunctionComponent, ReactNode } from "react";
import { selectCurrentBot } from "../../components/sidebar/SidebarSlice";
import { useAppSelector } from "../state/store";
import TelegramIcon from "../../assets/icons/tgFill.svg?react";
import DialogIcon from "../../assets/icons/dialogFill.svg?react";
import s from "./BaseEmptyPage.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface Props extends FlexProps {
  title?: string;
  description?: string;
  botTitle?: string;
  botDescription?: string;
  Image?: FunctionComponent;
  children?: ReactNode;
  isAddBot?: boolean;
}

export default function BaseEmptyPage({
  Image,
  description,
  title,
  children,
  botDescription,
  botTitle,
  isAddBot,
  ...flexProps
}: Props) {
  const currentBot = useAppSelector(selectCurrentBot);
  const { t } = useTranslation("translation", { keyPrefix: "addBot.createNew" });
  const navigate = useNavigate();

  const handleCreateTelegramBot = () => {
    navigate("/addTelegramBot");
  };

  const handleCreateWebBot = () => {
    navigate("/addWebBot");
  };

  if (!currentBot || isAddBot) {
    return (
      <Flex className={s.containerAddBot}>
        <Icon w="160px" h="160px" as={Image} />
        <Flex className={s.botTextContainer} {...flexProps}>
          <Text variant="h2Bold">{botTitle}</Text>
          <Text variant="mediumGrey">{botDescription}</Text>
        </Flex>
        <Flex className={s.buttonContainer}>
          <Button className={s.telegramButton} variant="dominoViolet" onClick={handleCreateTelegramBot}>
            <TelegramIcon />
            <Text>{t("Tg bot button")}</Text>
          </Button>
          <Button className={s.webButton} variant="dominoOutlineViolet" onClick={handleCreateWebBot}>
            <DialogIcon color="#6D5BF7" />
            <Text>{t("Web bot button")}</Text>
          </Button>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex className={s.container}>
      <Box className={s.img} as={Image} />
      <Flex className={s.textContainer}>
        <Text variant="h2Bold">{title}</Text>
        <Text variant="mediumGrey">{description}</Text>
      </Flex>
      <Flex className={s.children}>{children}</Flex>
    </Flex>
  );
}
