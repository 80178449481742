/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../common/state/store";
import { BotListModel, BotModel } from "./BotListModel";
import { BotListState } from "./BotListState";

const initialState: BotListState = {};

export const botListSlice = createSlice({
  name: "botListSlice",
  initialState,
  reducers: {
    getBotList: (state: BotListState, action: PayloadAction<{ page: number; search: string; channel: string }>) => {
      return {
        ...state,
      };
    },
    getBotListCompleted: (state: BotListState, action: PayloadAction<BotListModel>) => {
      return {
        ...state,
        botList: action.payload,
      };
    },
    createBotNewFlow: (state, action: PayloadAction<BotModel>) => {
      return {
        ...state,
      };
    },
    clearBotList: (state: BotListState) => {
      return {
        ...state,
        botList: undefined,
      };
    },
  },
});

export const { createBotNewFlow, getBotList, getBotListCompleted, clearBotList } = botListSlice.actions;

export default botListSlice.reducer;

export const selectBotList = (state: RootState) => state.app.botListState.botList;
