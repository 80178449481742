import { takeLatest, call, put, select } from "redux-saga/effects";
import { handleException } from "../../common/SagaHelper";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import { aiAssistantListSlice } from "./AIAssistantListSlice";
import { AIAssistantModel, FlowsInAIAssistantModel } from "./AIAssistantListModel";
import { AIAssistantData, EditAIAssistantFormData, FlowsInAIAssistantData, NewAIAssistantFormData } from "./AIAssistantListData";
import * as AIAssistantListApi from "./AIAssistantListApi";
import { flowBuilderSlice, selectFlow } from "../flowBuilder/FlowBuilderSlice";
import { t } from "i18next";
import { notify } from "../../common/notifications/NotificationSlice";
import { sidebarSlice } from "../sidebar/SidebarSlice";
import { AiFlowActionModel, FlowActionType, NodeModel } from "../flowBuilder/FlowBuilderModel";

export function* aiAssistantListSaga() {
  yield takeLatest(aiAssistantListSlice.actions.getAIAssistant, getAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.getAIAssistantList, getAIAssistantList);
  yield takeLatest(aiAssistantListSlice.actions.createAIAssistant, createAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.updateAIAssistant, updateAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.deleteAIAssistant, deleteAIAssistant);
  yield takeLatest(aiAssistantListSlice.actions.getFlowsInAIAssistant, getFlowsInAIAssistant);
}

function* getAIAssistant(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getAIAssistant"));
    const data: AIAssistantData = yield call(AIAssistantListApi.getAIAssistant, action.payload);
    const model: AIAssistantModel = data;
    yield put(aiAssistantListSlice.actions.getAIAssistantSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getAIAssistant"));
  }
}

function* getFlowsInAIAssistant(action: PayloadAction<string>) {
  try {
    yield put(beginScope("getFlowsInAIAssistant"));
    const data: FlowsInAIAssistantData[] = yield call(AIAssistantListApi.getFlowsInAIAssistant, action.payload);
    const model: FlowsInAIAssistantModel[] = data;
    yield put(aiAssistantListSlice.actions.getFlowsInAIAssistantSucceed(model));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getFlowsInAIAssistant"));
  }
}

function* getAIAssistantList() {
  try {
    yield put(beginScope("getAIAssistantList"));
    const data: AIAssistantData[] = yield call(AIAssistantListApi.getAIAssistantList);
    const model: AIAssistantModel[] = data;
    yield put(aiAssistantListSlice.actions.getAIAssistantListSucceed(model));
    yield put(sidebarSlice.actions.getAIAssistantsCountSucceed(model.length));
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getAIAssistantList"));
  }
}

function* createAIAssistant(action: PayloadAction<NewAIAssistantFormData>) {
  try {
    yield put(beginScope("createAIAssistant"));
    const newAiAssistant: AIAssistantModel = yield call(AIAssistantListApi.createAIAssistant, action.payload);
    const flow: ReturnType<typeof selectFlow> = yield select(selectFlow);
    const aiNode = flow && flow.nodes.find(el => el.data?.typeDiscriminator === FlowActionType.AiFlowAction);
    if (aiNode) {
      const newAiNode = aiNode as NodeModel;
      const newAiNodeActionFlow = newAiNode.flowAction as AiFlowActionModel;
      const newActionFlow: AiFlowActionModel = {
        ...newAiNodeActionFlow,
        assistantId: newAiAssistant.id,
        assistantName: newAiAssistant.name,
      };

      yield put(flowBuilderSlice.actions.editNode({ ...newAiNode, flowAction: newActionFlow }));
    }
    yield put(aiAssistantListSlice.actions.getAIAssistantList());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("createAIAssistant"));
  }
}

function* updateAIAssistant(action: PayloadAction<{ updatedAIAssistant: EditAIAssistantFormData; aiAssistantId: string }>) {
  try {
    yield put(beginScope("updateAIAssistant"));
    const { updatedAIAssistant, aiAssistantId } = action.payload;
    yield call(AIAssistantListApi.updateAIAssistant, updatedAIAssistant, aiAssistantId);
    yield put(aiAssistantListSlice.actions.getAIAssistantList());

    yield put(
      notify({
        message: t("AI-assistant.AI Assistant edited successfully"),
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("updateAIAssistant"));
  }
}

function* deleteAIAssistant(action: PayloadAction<string>) {
  try {
    yield put(beginScope("deleteAIAssistant"));
    yield call(AIAssistantListApi.deleteAIAssistant, action.payload);
    yield put(aiAssistantListSlice.actions.clearCurrentAIAssistant());
    yield put(aiAssistantListSlice.actions.getAIAssistantList());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteAIAssistant"));
  }
}
