import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import CrossIcon from "../../../../../../assets/icons/cross.svg?react";
import { useTranslation } from "react-i18next";
import TelegramIcon from "../../../../../../assets/icons/telegramIcon.svg?react";
import useGA from "../../../../../../common/ga/GAEventTracker";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import { GroupsAddMacEvents } from "../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export const SuccessfulModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const groupChatInfoTranslation = useTranslation("translation", { keyPrefix: "groupChat.successfulModal" }).t;
  const trackEvent = useGA(EventCategories.Group);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => trackEvent(GroupsAddMacEvents.GroupsAddMacModalClose)}
    >
      <ModalOverlay zIndex={10001} />
      <ModalContent
        containerProps={{
          zIndex: "10001",
        }}
        data-pw="modal"
        borderRadius="12px"
        maxWidth="460px"
        p="32px"
        gap="16px"
        mx="16px"
      >
        <ModalHeader p="0px">
          <Flex gap="8px" align="center">
            <Flex minW="32px">
              <TelegramIcon width="32px" height="32px" />
            </Flex>
            <Text variant="h1">{groupChatInfoTranslation("The link has been sent")}</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          data-pw="modal-close"
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
        >
          <CrossIcon />
        </ModalCloseButton>
        <ModalBody p="0px">
          <Text variant="medium">
            {groupChatInfoTranslation("To add the bot to your group, open the link in the Telegram mobile app")}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
