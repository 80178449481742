import { useBrowserFitContent, useMobileView, useTabletView } from "../../../LayoutHelper/ResolutionHooks";
import { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { BotContainer } from "../../../../bot/BotContainer";
import { BotHeader } from "../../../../bot/components/BotHeader/BotHeader";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { HEADER, TRIAL_HEIGHT, SUB_HEADER } from "../../../../layout/LayoutHelper/ResolutionConst";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { selectIsOrganizationTrial } from "../../../../organisation/OrganisationSlice";
import { useParams } from "react-router-dom";
import { getBotInfo, selectBotInfo } from "../../../../bot/BotSlice";
import { WebBotContainer } from "../../../../webBot/components/webBot/WebBotContainer";

export const BotPage = () => {
  const dispatch = useAppDispatch();
  const browserFill = useBrowserFitContent();
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const isTablet = useTabletView();
  const isMobile = useMobileView();
  const [tabletView, setTabletView] = useState(isTablet);
  const { botId } = useParams();
  const botInfo = useAppSelector(selectBotInfo);

  useEffect(() => {
    if (botId) {
      dispatch(getBotInfo({ botId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  const calculateContainerHeight = () => {
    let height = window.innerHeight - HEADER - SUB_HEADER;

    if (isMobile && isTrial) {
      height -= TRIAL_HEIGHT;
    }

    return height;
  };

  useEffect(() => {
    if (isTablet !== tabletView) {
      setTabletView(isTablet);
    }
  }, [tabletView, isTablet]);

  return (
    <Flex flexDirection={"column"} height={"100%"}>
      <ProtectedHeaderContainer>
        <BotHeader />
      </ProtectedHeaderContainer>
      <Box bg="bgLight" height={tabletView ? calculateContainerHeight() : browserFill}>
        <Loading />
        {botInfo?.channel === "Telegram" ? (
          <BotContainer botInfo={botInfo} botId={botId} />
        ) : (
          botInfo?.channel === "Web" && <WebBotContainer botInfo={botInfo} botId={botId} />
        )}
      </Box>
    </Flex>
  );
};
