import { Divider, Flex } from "@chakra-ui/react";
import s from "./Separator.module.scss";
import { ReactNode } from "react";

export const Separator = ({ children, width = "100%" }: { children: ReactNode; width?: string }) => {
  return (
    <Flex w={width} className={s.separator} align="center">
      <Divider size="lg" borderWidth="1px" borderColor="var(--chakra-colors-line)" />
      {children}
      <Divider size="lg" borderWidth="1px" borderColor="var(--chakra-colors-line)" />
    </Flex>
  );
};
