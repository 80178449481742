import KeyIcon from "../../../../assets/icons/keyIcon.svg?react";
import ThunderIcon from "../../../../assets/icons/thunderIcon.svg?react";
import RefUrlIcon from "../../../../assets/icons/refUrlIcon.svg?react";
import ButtonClickIcon from "../../../../assets/icons/buttonClickIcon.svg?react";
import CommandIcon from "../../../../assets/icons/commandTriggerIcon.svg?react";
import SheduleIcon from "../../../../assets/icons/scheduleTriggerIcon.svg?react";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import { TriggerTypeDiscriminator } from "../../FlowBuilderModel";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Wrap, Text, Icon } from "@chakra-ui/react";
import { AddButton } from "./components/AddButton";
import { useTranslation } from "react-i18next";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { TooltipWithTouch } from "../../../../common/tooltipWithTouch/TooltipWithTouch";
import { BotModel } from "../../../sidebar/SidebarModel";

interface Props {
  onCreateTrigger: (type: TriggerTypeDiscriminator) => void;
  disabledTypes: TriggerTypeDiscriminator[];
  onClose: () => void;
  currentBot?: BotModel;
}

export const AddNewTriggerPopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const buttons = [
    {
      type: TriggerTypeDiscriminator.KeywordMatch,
      label: t("Keyword"),
      icon: KeyIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.KeywordMatch),
      tooltip: t("The trigger will be activated if the contact sends a message to the bot containing the specified word"),
    },
    {
      type: TriggerTypeDiscriminator.CommandMatch,
      label: t("Command"),
      icon: CommandIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.CommandMatch),
      tooltip: t("The trigger will be activated if the contact sends a command to the bot (/start, /book, etc.)"),
      isHide: props.currentBot?.channel === "Web",
    },
    {
      type: TriggerTypeDiscriminator.RefUrl,
      label: t("Reference URL"),
      icon: RefUrlIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.RefUrl),
      tooltip: t("The trigger will be activated if the contact joins the chat via a unique link"),
      isHide: props.currentBot?.channel === "Web",
    },
    {
      type: TriggerTypeDiscriminator.ButtonClick,
      label: t("Button click"),
      icon: ButtonClickIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.ButtonClick),
      tooltip: t("The trigger will be activated if the contact clicks on a specific button in the chat"),
    },
    {
      type: TriggerTypeDiscriminator.ConversationStart,
      label: t("Conversation Start"),
      icon: ThunderIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.ConversationStart),
      tooltip: t("The trigger will be activated if a new contact connects to the bot"),
    },
    {
      type: TriggerTypeDiscriminator.Schedule,
      label: t("Schedule"),
      icon: SheduleIcon,
      isDisabled: props.disabledTypes.includes(TriggerTypeDiscriminator.Schedule),
      tooltip: t("The trigger will be activated once at a specified time or at a set interval"),
      isHide: props.currentBot?.channel === "Web",
    },
  ];

  return (
    <Modal isOpen={true} onClose={props.onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent width={["300px", "auto", "auto"]}>
        <ModalHeader display={"flex"} alignItems={"center"}>
          <Text variant="largeBold">{t("Add Trigger")}</Text>
          <TooltipWithTouch
            variant="dominoLight"
            whiteSpace={"pre-wrap"}
            label={t(
              "Trigger is an event that starts a flow.  You can set up multiple triggers at the same time - the flow will be started when at least one of the conditions is met",
            )}
          >
            <QuestionOutlineIcon ml={"8px"} boxSize={"15px"} color="darkGrey" _hover={{ color: "darkPurple" }} />
          </TooltipWithTouch>
        </ModalHeader>
        <ModalCloseButton
          borderRadius={"50%"}
          color={"darkGrey"}
          _hover={{ bg: "defaultGrey", color: "mainPurple" }}
          data-pw="modal-close"
        >
          <Icon boxSize="20px" as={CrossIcon} />
        </ModalCloseButton>
        <ModalBody p="0px 24px 24px">
          <Wrap spacing="16px">
            {buttons.map(button => {
              if (!button.isHide) {
                return (
                  <AddButton
                    key={button.type}
                    label={button.label}
                    icon={button.icon}
                    type={button.type}
                    isDisabled={button.isDisabled}
                    tooltip={button.tooltip}
                    onClick={props.onCreateTrigger}
                  />
                );
              }
            })}
          </Wrap>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
