import React, { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, Flex, Icon, Input, PlacementWithLogical, Tooltip } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
  ButtonsContainerFlowAction,
  ShoppingCartFlowActionModel,
  FlowActionModel,
  FlowActionType,
  NodeModel,
  SendAudioFlowActionModel,
  SendDocumentFlowActionModel,
  SendPhotoFlowActionModel,
  SendVideoFlowActionModel,
  TextContainerFlowAction,
} from "../../flowBuilder/FlowBuilderModel";
import { editNode } from "../../flowBuilder/FlowBuilderSlice";
import { InputType } from "../../inputWrapper/InputWrapperModel";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import { ReplyKeyboardButtonModel, ReplyKeyboardMarkupModel } from "../../../common/AppButtonsModel";
import { ButtonType, ButtonTypeDiscriminator, CustomVariableModel, FlowMarkupTypeDiscriminator } from "../../../common/AppEnums";
import { InputWrapper } from "../../inputWrapper/InputWrapper";
import CrossIcon from "../../../assets/icons/cross.svg?react";
import PhoneIcon from "../../../assets/icons/phoneIcon.svg?react";
import CatalogNodeIcon from "../../../assets/icons/CatalogNodeIcon.svg?react";
import s from "./ReplyButtonPopover.module.scss";
import ss from "../../dnd/SortableItem/SortableItem.module.scss";
import { selectCurrentBot } from "../../sidebar/SidebarSlice";

interface Props {
  button?: ReplyKeyboardButtonModel;
  flowAction: FlowActionModel;
  variables: CustomVariableModel[];
  isDefaultPayload?: boolean;
  isBroadcast?: boolean;
  placement?: PlacementWithLogical;
  node: NodeModel;
  isButton?: boolean;
  deleteButton?: (callbackData: string) => void;
  addVariable?: (nodeEditorInfo: unknown) => void;
  onButtonDataChanges: (value: SetStateAction<boolean>) => void;
}

export const ReplyButtonPopover = ({ button, flowAction, node, isButton, deleteButton, onButtonDataChanges }: Props) => {
  const [addPhoneRequest, setAddPhoneRequest] = useState<boolean | undefined>(false);
  const [addOrderButton, setAddOrderButton] = useState<boolean | undefined>(false);
  const [addReply, setAddReply] = useState(false);
  const [editReply, setEditReply] = useState(false);
  const [editReplyButton, setEditReplyButton] = useState(button);
  const [replyText, setReplyText] = useState("");
  const [invalidText, setIsInvalidText] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const currentBot = useAppSelector(selectCurrentBot);

  const onChangeReplyButtonNameHandle = (event: { target: { value: SetStateAction<string> } }) => {
    if (event.target.value.length >= 100) {
      setIsInvalidText(true);
      setReplyText(event.target.value.toString().substring(0, 100));
    } else {
      setIsInvalidText(false);
      setReplyText(event.target.value);
    }
  };

  const isPhoneRequest =
    flowAction?.replyMarkup?.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup &&
    (flowAction.replyMarkup as ReplyKeyboardMarkupModel).buttons.some(row => row.some(button => button.requestContact === true));

  const onAddReplyButton = () => {
    const action = flowAction as ButtonsContainerFlowAction;
    const reply = action.replyMarkup as ReplyKeyboardMarkupModel;
    if (reply && reply.buttons && reply.buttons.length > 100) {
      return;
    }
    if (!replyButtonTextValidate(replyText)) {
      return;
    }
    setReplyText("");
    const buttons: ReplyKeyboardButtonModel[][] = [];
    if (reply && reply.buttons) {
      reply.buttons.forEach(element => buttons.push(element));
    }

    const replyButton: ReplyKeyboardButtonModel = {
      id: uuidv4(),
      label: replyText.trim(),
      typeDiscriminator: ButtonTypeDiscriminator.FlowReplyKeyboardButton,
      targetNodeId: "",
      type: ButtonType.CallbackButton,
      ...(addPhoneRequest && { requestContact: true }),
      ...(addOrderButton && { requestOrder: true }),
    };

    buttons.push([replyButton]);
    onDataChange(buttons);

    if (addPhoneRequest) {
      setAddPhoneRequest(false);
    }

    if (addOrderButton) {
      setAddOrderButton(false);
    }
  };

  const onDeleteReplyButton = () => {
    if (button && deleteButton) {
      deleteButton(button.id);
    }
  };

  const onEditReplyButton = () => {
    if (editReplyButton && deleteButton && replyText && replyButtonTextValidate(replyText)) {
      const buttons: ReplyKeyboardButtonModel[][] = [];
      const reply = (flowAction as ButtonsContainerFlowAction).replyMarkup as ReplyKeyboardMarkupModel;
      if (reply && reply.buttons) {
        reply.buttons.forEach(element => buttons.push(element));
      }
      const newReplyButton: ReplyKeyboardButtonModel = {
        id: editReplyButton.id,
        label: replyText.trim(),
        typeDiscriminator: ButtonTypeDiscriminator.FlowReplyKeyboardButton,
        targetNodeId: "",
        type: ButtonType.CallbackButton,
        ...(button?.requestContact && { requestContact: true }),
        ...(button?.requestOrder && { requestOrder: true }),
      };
      setReplyText("");
      const index = buttons.findIndex(replyButtons => replyButtons.find(replyButton => replyButton.id === editReplyButton.id));
      buttons[index] = [newReplyButton];
      onDataChange(buttons);
    }
  };
  const onDataChange = (replyButton: ReplyKeyboardButtonModel[][]) => {
    const newAction = getFlowByType(flowAction, replyButton);
    dispatch(editNode({ ...node, flowAction: newAction }));
    setAddReply(false);
  };

  function replyButtonTextValidate(buttonText: string) {
    if (buttonText.length === 0 || buttonText.length > 100) {
      return false;
    }
    const regex = new RegExp(" ", "g");
    const matches = buttonText.match(regex);
    return buttonText.length !== matches?.length;
  }

  function getFlowByType(flow: FlowActionModel, replyButtons: ReplyKeyboardButtonModel[][]) {
    const text = (flowAction as TextContainerFlowAction).text ?? undefined;
    switch (flowAction.typeDiscriminator) {
      case FlowActionType.SendTextFlowAction:
        const textAction = flowAction as TextContainerFlowAction;
        const currentTextAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          flowId: textAction.flowId,
          text: textAction.text ?? "",
          typeDiscriminator: textAction.typeDiscriminator,
        };
        if (!replyButtons) {
          return { ...currentTextAction };
        }
        return {
          ...currentTextAction,
          fallbackMessage: (textAction as SendAudioFlowActionModel).fallbackMessage,
          isFallback: (textAction as SendAudioFlowActionModel).isFallback,
        };
      case FlowActionType.SendPhotoFlowAction:
        const photoAction = flowAction as SendPhotoFlowActionModel;
        const currentPhotoAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          isFallback: replyButtons.length && photoAction.isFallback ? true : false,
          retryCount: photoAction.retryCount,
          fallbackMessage: photoAction.fallbackMessage,
          flowId: photoAction.flowId,
          typeDiscriminator: photoAction.typeDiscriminator,
          file: photoAction.file,
          fileId: photoAction.fileId,
          fileUrl: photoAction.fileUrl,
          fileInfo: photoAction.fileInfo,
          customVariableIds: photoAction.customVariableIds,
        };
        if (text) {
          return { ...currentPhotoAction, text };
        }
        return { ...currentPhotoAction };
      case FlowActionType.SendDocumentFlowAction:
        const documentAction = flowAction as SendDocumentFlowActionModel;
        const currentDocumentAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          isFallback: replyButtons.length && documentAction.isFallback ? true : false,
          retryCount: documentAction.retryCount,
          fallbackMessage: documentAction.fallbackMessage,
          flowId: documentAction.flowId,
          typeDiscriminator: documentAction.typeDiscriminator,
          file: documentAction.file,
          fileId: documentAction.fileId,
          fileUrl: documentAction.fileUrl,
          fileInfo: documentAction.fileInfo,
          customVariableIds: documentAction.customVariableIds,
        };
        if (text) {
          return { ...currentDocumentAction, text };
        }
        return { ...currentDocumentAction };
      case FlowActionType.SendVideoFlowAction:
        const videoAction = flowAction as SendVideoFlowActionModel;
        const currentVideoAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          isFallback: replyButtons.length && videoAction.isFallback ? true : false,
          retryCount: videoAction.retryCount,
          fallbackMessage: videoAction.fallbackMessage,
          flowId: videoAction.flowId,
          typeDiscriminator: videoAction.typeDiscriminator,
          file: videoAction.file,
          fileId: videoAction.fileId,
          fileUrl: videoAction.fileUrl,
          fileInfo: videoAction.fileInfo,
          customVariableIds: videoAction.customVariableIds,
        };
        if (text) {
          return { ...currentVideoAction, text };
        }
        return { ...currentVideoAction };
      case FlowActionType.SendAudioFlowAction:
        const audioAction = flowAction as SendAudioFlowActionModel;
        const currentAudioAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          isFallback: replyButtons.length && audioAction.isFallback ? true : false,
          retryCount: audioAction.retryCount,
          fallbackMessage: audioAction.fallbackMessage,
          flowId: audioAction.flowId,
          typeDiscriminator: audioAction.typeDiscriminator,
          file: audioAction.file,
          fileId: audioAction.fileId,
          fileUrl: audioAction.fileUrl,
          fileInfo: audioAction.fileInfo,
          customVariableIds: audioAction.customVariableIds,
        };
        if (text) {
          return { ...currentAudioAction, text };
        }
        return { ...currentAudioAction };
      case FlowActionType.ShoppingCartFlowAction:
        const catalogAction = flowAction as ShoppingCartFlowActionModel;
        const currentCatalogAction = {
          replyMarkup: { buttons: replyButtons, typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup },
          flowId: catalogAction.flowId,
          text: catalogAction.text ?? "",
          fileName: catalogAction.fileName,
          typeDiscriminator: catalogAction.typeDiscriminator,
          targetCustomVariableId: catalogAction.targetCustomVariableId,
        };
        if (!replyButtons) {
          return { ...currentCatalogAction };
        }
        return {
          ...currentCatalogAction,
        };
    }
  }

  const text = t("editNodePopup.Quick replies");
  const phoneTooltip = t("editNodePopup.Send your phone number Tooltip");

  const addReplyButton = () =>
    !addReply ? (
      <Flex flexDir={"column"} gap="8px">
        <Tooltip alignSelf={"right"} color={"#232B39"} width={"240px"} hasArrow label={text} bg={"#FEF6DC"}>
          <Button
            fontSize={"15px"}
            background={"white"}
            color={"#3E5E95"}
            paddingX={"8px"}
            className={s.addButton}
            onClick={() => setAddReply(true)}
            data-pw="add-reply-button"
          >
            + {t("editNodePopup.Add quick reply")} <Icon className={s.questionIcon} />
          </Button>
        </Tooltip>
        {currentBot &&
          currentBot?.channel !== "Web" &&
          flowAction.typeDiscriminator !== FlowActionType.ShoppingCartFlowAction &&
          !isPhoneRequest && (
            <Tooltip alignSelf={"right"} color={"#232B39"} width={"240px"} hasArrow label={phoneTooltip} bg={"#FEF6DC"}>
              <Button
                fontSize={"15px"}
                background={"white"}
                color={"#3E5E95"}
                paddingX={"8px"}
                className={s.addButton}
                onClick={() => {
                  setAddReply(true);
                  setAddPhoneRequest(true);
                  setReplyText(t("editNodePopup.Send your phone number"));
                }}
                data-pw="phone-button"
              >
                <PhoneIcon style={{ paddingRight: "4px" }} />
                {t("editNodePopup.Send your phone number")} <Icon className={s.questionIcon} />
              </Button>
            </Tooltip>
          )}
      </Flex>
    ) : (
      <InputWrapper type={InputType.Input} maxLength={100} charactersNumber={replyText.length}>
        <Input
          autoFocus={true}
          isTruncated
          onKeyDown={e => {
            if (e.key === "Enter") {
              onAddReplyButton();
            }
          }}
          onBlur={() => {
            onAddReplyButton();
            setAddReply(false);
            setReplyText("");
            setIsInvalidText(false);
          }}
          onSubmit={onAddReplyButton}
          isInvalid={invalidText}
          errorBorderColor="crimson"
          onChange={onChangeReplyButtonNameHandle}
          paddingRight={"10%"}
          value={replyText}
          placeholder={t("editNodePopup.Type text")}
          data-pw="edit-reply-input"
          pr={"29px"}
        ></Input>
      </InputWrapper>
    );
  const getReplyButtons = () =>
    button?.label && (
      <div tabIndex={1}>
        <Box>
          {editReply && (
            <Box marginTop={1} marginBottom={-5}>
              <InputWrapper type={InputType.Input} maxLength={100} charactersNumber={replyText.length}>
                <Input
                  autoFocus={true}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onEditReplyButton();
                      setEditReply(false);
                      onButtonDataChanges(false);
                    }
                  }}
                  onBlur={() => {
                    onEditReplyButton();
                    setEditReply(false);
                    setReplyText("");
                    setIsInvalidText(false);
                    onButtonDataChanges(false);
                  }}
                  isInvalid={invalidText}
                  errorBorderColor="crimson"
                  onChange={onChangeReplyButtonNameHandle}
                  value={replyText}
                  placeholder="Type text"
                  data-pw="edit-reply-input"
                  pr={"29px"}
                />
              </InputWrapper>
            </Box>
          )}
          {flowAction.typeDiscriminator === FlowActionType.ShoppingCartFlowAction && (
            <ButtonGroup tabIndex={-1} width={"100%"} size="sm" isAttached variant="outline" marginBottom={1}>
              {!editReply && (
                <Button
                  isTruncated
                  onClick={() => {
                    setEditReplyButton(button);
                    setReplyText(button?.label);
                    setEditReply(true);
                    onButtonDataChanges(true);
                    setAddOrderButton(button.requestOrder);
                  }}
                  background={"#DCE7FB"}
                  _hover={{ bg: "#EFF5FE" }}
                  paddingX={"8px"}
                  className={ss.button}
                  data-pw="edit-reply-button"
                >
                  <Flex className={s.buttonGroup}>
                    {button.requestContact && <PhoneIcon className={s.phoneIcon} />}
                    {button.requestOrder && <CatalogNodeIcon className={s.phoneIcon} />}
                    <div style={{ flexGrow: 1 }} className={s.buttonText}>
                      {button.label}
                    </div>
                    {!button.requestOrder && (
                      <CrossIcon onClick={() => onDeleteReplyButton()} className={s.crossIcon} data-pw="cross-icon" />
                    )}
                  </Flex>
                </Button>
              )}
            </ButtonGroup>
          )}
          {flowAction.typeDiscriminator !== FlowActionType.ShoppingCartFlowAction && (
            <ButtonGroup tabIndex={-1} width={"100%"} size="sm" isAttached variant="outline" marginTop={1} marginBottom={1}>
              {!editReply && (
                <Button
                  isTruncated
                  onClick={() => {
                    setEditReplyButton(button);
                    setReplyText(button?.label);
                    setEditReply(true);
                    onButtonDataChanges(true);
                    setAddPhoneRequest(button.requestContact);
                    setAddOrderButton(button.requestOrder);
                  }}
                  background="#DCE7FB"
                  _hover={{ bg: "#EFF5FE" }}
                  paddingX={"8px"}
                  className={`${ss.button} ${button.requestContact && s.requestContact}`}
                  data-pw="edit-reply-button"
                >
                  <Flex className={s.buttonGroup}>
                    {button.requestContact && <PhoneIcon className={s.phoneIcon} />}
                    {button.requestOrder && <CatalogNodeIcon className={s.phoneIcon} />}
                    <div style={{ flexGrow: 1 }} className={s.buttonText}>
                      {button.label}
                    </div>
                    <CrossIcon onClick={() => onDeleteReplyButton()} className={s.crossIcon} data-pw="cross-icon" />
                  </Flex>
                </Button>
              )}
            </ButtonGroup>
          )}
        </Box>
      </div>
    );

  return (
    <Box w="100%">
      {getReplyButtons()}
      {!isButton && addReplyButton()}
    </Box>
  );
};
