export enum ProfanityFilterDataTypeEnum {
  Warning = "Warning",
  Mute = "Mute",
  Block = "Block",
}

interface ProfanityFilterRule {
  violation: number;
  ruleAction: {
    type: ProfanityFilterDataTypeEnum;
    forLong?: string | null; // "hh:mm" | "dd.mm:hh"
  };
}

export interface ProfanityFilterData {
  isEnabled: boolean;
  rules: ProfanityFilterRule[];
  customProfanities: string[];
}
