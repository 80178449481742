import { Box, Flex, Skeleton, Text, Tooltip as TooltipChakra } from "@chakra-ui/react";
import { Checkbox, Stack } from "@chakra-ui/react";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import {
  DashboardFilterCheckboxTypeModel,
  DataMessagesStatisticModel,
  DataMessagesModel,
  DataUTMStatisticModel,
} from "../../DashboardModel";
import DashboardEmptyDataChart from "./DashboardEmptyDataChart/DashboardEmptyDataChart";
import DashboardZeroTotalChart from "./DashboardZeroTotalChart/DashboardZeroTotalChart";
import DashboardCustomTooltipChart from "./DashboardCustomTooltipChart/DashboardCustomTooltipChart";
import s from "../../DashboardContainer.module.scss";

interface Props {
  dataChart?: DataMessagesModel[] | DataMessagesStatisticModel[] | DataUTMStatisticModel[];
  label: string;
  total?: number;
  totalElement: ReactNode;
  labelCheckbox: string;
  origins: Array<DashboardFilterCheckboxTypeModel>;
  isTranslate?: boolean;
  isLoading: boolean;
}

export default function DashboardItem({
  dataChart,
  label,
  labelCheckbox,
  totalElement,
  total,
  origins,
  isTranslate = true,
  isLoading,
}: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<DashboardFilterCheckboxTypeModel[]>(origins);

  useEffect(() => {
    setSelectedCheckboxes(origins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origins]);

  const changeSelectedCheckBox = (label: DashboardFilterCheckboxTypeModel, isAdd: boolean) => {
    let filtered: Array<DashboardFilterCheckboxTypeModel> = selectedCheckboxes;
    filtered = selectedCheckboxes.map(checkbox => {
      if (checkbox.label === label.label) {
        return { ...checkbox, checked: isAdd };
      }
      return checkbox;
    });
    setSelectedCheckboxes(filtered);
  };

  return (
    <Box mb={"24px"}>
      <Flex justifyContent={"space-between"} mb={"16px"}>
        <Text variant={"largeBold"} flex={0.905}>
          {t(label)}
        </Text>
        {totalElement}
      </Flex>
      <Flex className={s.flexContainerDashboard}>
        {!isLoading ? (
          <>
            {total ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  key={label}
                  className={s.dashboardItem}
                  data={dataChart}
                  margin={{ top: 38, right: 56, left: 20, bottom: 12 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tickMargin={8} dataKey="name" />
                  <YAxis type="number" domain={[0, 4]} />
                  <Tooltip content={<DashboardCustomTooltipChart isTranslate={isTranslate} active={false} />} />
                  {selectedCheckboxes.map((item, index) => {
                    if (item.checked) {
                      return <Line dot={{ r: 0 }} key={item.label} type="monotone" dataKey={item.label} stroke={item.color} />;
                    } else {
                      return <></>;
                    }
                  })}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <DashboardZeroTotalChart origins={selectedCheckboxes} dataChart={dataChart} />
            )}
          </>
        ) : (
          <DashboardEmptyDataChart />
        )}

        <Box className={s.CheckBoxContainer}>
          <Box className={s.boxCheck}>
            <Flex className={s.flexCheckBoxContainer} gap="16px" flexDirection="column">
              <Box>
                <Text variant={"preLargeBold"}> {t(labelCheckbox)}:</Text>
              </Box>
              <Stack overflowY="auto" overflowX="hidden" className={s.stackCheckBox} spacing="10px" direction="column">
                {selectedCheckboxes.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      {dataChart ? (
                        <Checkbox
                          isChecked={item.checked}
                          isDisabled={!total}
                          onChange={e => changeSelectedCheckBox(item, e.target.checked)}
                          key={index}
                          variant={"dominoDashboard"}
                          iconColor="white"
                          _checked={{
                            _before: {
                              bg: `${item.color}`,
                              content: `""`,
                              color: "white",
                            },
                          }}
                          _disabled={{
                            cursor: "not-allowed",
                            _before: {
                              bg: "#E2E8F0",
                              content: `""`,
                            },
                          }}
                          _before={{
                            content: `""`,
                            color: "white",
                            width: "20px",
                            h: "20px",
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <TooltipChakra
                            label={isTranslate ? t(item.label) : item.label}
                            placement="top"
                            isDisabled={(isTranslate ? t(item.label) : item.label).length <= 12}
                            variant={"dominoChat"}
                            bg={"#FEF6DC"}
                            hasArrow={true}
                            openDelay={100}
                            w="max-content"
                          >
                            <Flex gap="4px">
                              <Text className={s.checkboxText} wordBreak="break-all" noOfLines={1}>
                                {isTranslate ? t(item.label) : item.label}
                              </Text>
                              <Text>{item.total}</Text>
                            </Flex>
                          </TooltipChakra>
                        </Checkbox>
                      ) : (
                        <Skeleton
                          key={index}
                          speed={0.5}
                          startColor="line"
                          endColor="bgLight"
                          borderRadius="4px"
                          height="24px"
                          width="80%"
                          border="1px solid"
                          borderColor="line"
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Stack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
