import { Button, Text, Flex, Input, InputGroup, InputRightElement, useDisclosure } from "@chakra-ui/react";
import s from "./WebBotSettings.module.scss";
import CrossIcon from "../../../../../../assets/icons/cross.svg?react";
import { useTranslation } from "react-i18next";
import { OverflowEllipsesText } from "../../../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import { AppSettings } from "../../../../../../common/AppSettings";
import { BotModel } from "../../../../../bot/BotModel";
import { getOrganisationIdFromStorage } from "../../../../../../common/BaseApi";
import { useState } from "react";
import { useValidateInput } from "../../../../addWebBot/components/addWebBotFormValidation";
import { useAppDispatch } from "../../../../../../common/state/store";
import { updateWebBot } from "../../../../WebBotSlice";
import { WebBotChangeModal } from "../WebBotChangeModal/WebBotChangeModal";
import ButtonCopy from "../../../../../../UI/atoms/buttonCopy/ButtonCopy";

declare const appSettings: AppSettings;

interface Props {
  botInfo: BotModel | undefined;
  onOpenBotRemoveModal: () => void;
}

export const WebBotSettings = ({ botInfo, onOpenBotRemoveModal }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "bot" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const orgId = getOrganisationIdFromStorage();
  const validateInput = useValidateInput();
  const dispatch = useAppDispatch();
  const widgetScript = `<script src="${appSettings.chatWidgetScript}"></script> ${" "}
  <script type="text/javascript">window.dominoChatWidget.init("${orgId}", "${botInfo?.id}", "${appSettings.chatWidgetUrl}", "${
    botInfo?.title
  }")</script>`;

  const [newName, setNewName] = useState(botInfo?.name || "");
  const [newTitle, setNewTitle] = useState(botInfo?.title || "");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);
  const [titleError, setTitleError] = useState<string | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNameChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setNewName(value);
    const error = validateInput(value);
    setNameError(error);
  };

  const handleTitleChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setNewTitle(value);
    const error = validateInput(value);
    setTitleError(error);
  };

  const onDeleteName = () => {
    setNewName("");
    const error = validateInput("");
    setNameError(error);
  };

  const onDeleteTitle = () => {
    setNewTitle("");
    const error = validateInput("");
    setTitleError(error);
  };

  const handleSaveName = () => {
    if (!botInfo) return;
    const error = validateInput(newName.trim());
    setNameError(error);
    if (!error) {
      const updatedBot: BotModel = {
        ...botInfo,
        name: newName,
      };
      dispatch(updateWebBot(updatedBot));
      setIsEditingName(false);
    } else {
      setNewName("");
    }
  };

  const handleSaveTitle = () => {
    if (!botInfo) return;
    const error = validateInput(newTitle.trim());
    setTitleError(error);
    if (!error) {
      const updatedBot: BotModel = {
        ...botInfo,
        title: newTitle,
      };

      dispatch(updateWebBot(updatedBot));
      setIsEditingTitle(false);
      onOpen();
    } else {
      setNewTitle("");
    }
  };

  const onOpenBotRemoveModalClick = () => {
    onOpenBotRemoveModal();
  };

  return (
    <Flex className={s.main}>
      <Flex className={s.secondBlock}>
        <Flex className={s.block}>
          <Flex className={s.blockTitle}>
            <Text>{t("Bot name")}</Text>
          </Flex>
          <Flex className={s.blockContent} flexDir="column" gap="12px" flex={1}>
            <Flex className={s.botToken} overflow="hidden">
              {isEditingName ? (
                <Flex flexDir="column" gap="4px" w="100%">
                  <InputGroup>
                    <Input
                      value={newName}
                      onChange={handleNameChange}
                      className={s.input}
                      isInvalid={!!nameError}
                      maxLength={64}
                    />
                    <InputRightElement>
                      {newName && <CrossIcon color="#8592A3" cursor={"pointer"} className={s.crossIcon} onClick={onDeleteName} />}
                    </InputRightElement>
                  </InputGroup>
                  {nameError && <Text variant="inputError">{nameError}</Text>}
                </Flex>
              ) : (
                <OverflowEllipsesText
                  text={widgetScript}
                  content={
                    <Text
                      className={s.botInfoText}
                      whiteSpace="normal !important"
                      wordBreak="break-all"
                      fontSize="15px"
                      noOfLines={1}
                    >
                      {botInfo?.name}
                    </Text>
                  }
                />
              )}
              <Button
                variant="dominoOutlineViolet"
                overflow={{ base: "hidden", md: "" }}
                textOverflow={"ellipsis"}
                w="140px"
                className={s.buttons}
                isDisabled={!!nameError}
                h="36px"
                onClick={() => (isEditingName ? handleSaveName() : setIsEditingName(true))}
                alignSelf={nameError ? "baseline" : "auto"}
              >
                {isEditingName ? ct("Save") : t("Change")}
              </Button>
            </Flex>
            <Text className={s.botTokenText}>
              {t("To change the bot's name that will be displayed to visitors during the chat, click the “Change“ button")}
            </Text>
          </Flex>
        </Flex>
        <Flex className={s.block}>
          <Flex className={s.blockTitle}>
            <Text>{t("Widget title on the website")}</Text>
          </Flex>
          <Flex className={`${s.blockContent} ${s.blockToken}`}>
            <Flex className={s.botToken}>
              {isEditingTitle ? (
                <Flex flexDir="column" gap="4px" w="100%">
                  <InputGroup>
                    <Input
                      value={newTitle}
                      onChange={handleTitleChange}
                      className={s.input}
                      isInvalid={!!titleError}
                      maxLength={64}
                    />
                    <InputRightElement>
                      {newTitle && (
                        <CrossIcon color="#8592A3" cursor={"pointer"} className={s.crossIcon} onClick={onDeleteTitle} />
                      )}
                    </InputRightElement>
                  </InputGroup>
                  {titleError && <Text variant="inputError">{titleError}</Text>}
                </Flex>
              ) : (
                <OverflowEllipsesText
                  text={botInfo?.title}
                  content={
                    <Text
                      whiteSpace="normal !important"
                      wordBreak="break-all"
                      noOfLines={1}
                      className={s.botInfoText}
                      fontSize="15px"
                    >
                      {botInfo?.title}
                    </Text>
                  }
                />
              )}
              <Button
                variant="dominoOutlineViolet"
                overflow={{ base: "hidden", md: "" }}
                textOverflow={"ellipsis"}
                w="140px"
                className={s.buttons}
                h="36px"
                isDisabled={!!titleError}
                onClick={() => (isEditingTitle ? handleSaveTitle() : setIsEditingTitle(true))}
                alignSelf={titleError ? "baseline" : "auto"}
              >
                {isEditingTitle ? ct("Save") : t("Change")}
              </Button>
            </Flex>
            <Text className={s.botTokenText}>
              {t(
                "After changing the title, the code for embedding on the site will be updated automatically, don't forget to refresh it on your website",
              )}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={s.block}>
        <Flex className={s.blockTitle}>
          <Text>{t("Script")}</Text>
        </Flex>
        <Flex className={s.blockContent} flexDir="column" gap="12px">
          <Flex className={s.botToken}>
            <OverflowEllipsesText tooltip={false} text={widgetScript} content={<Text variant="medium">{widgetScript}</Text>} />
            <ButtonCopy variant="dominoOutlineViolet" minW="140px" copyText={widgetScript} h="36px">
              {ct("New Copy")}
            </ButtonCopy>
          </Flex>
          <Text className={s.botTokenText}>{t("Place this code on every page of your site before the closing </body> tag")}</Text>
        </Flex>
      </Flex>

      <Flex className={s.block}>
        <Flex className={s.blockTitle}>
          <Text>{t("Remove Widget Bot")}</Text>
        </Flex>
        <Flex className={s.blockContent} flexDir="column" gap="12px" flex="1 0 0">
          <Flex className={`${s.botToken} ${s.botTokenDelete}`}>
            <Text className={s.botTokenText}>{t("Remove web bot text")}</Text>
            <Button
              className={`${s.buttons} ${s.buttonDel}`}
              variant="dominoOutlineRed"
              onClick={onOpenBotRemoveModalClick}
              h="36px"
            >
              {t("Remove")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <WebBotChangeModal isOpen={isOpen} onClose={onClose} copyText={widgetScript} />
    </Flex>
  );
};
