import {
  Button,
  Box,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from "@chakra-ui/react";
import s from "./WebBotListItem.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";
import PlusIcon from "../../../../assets/icons/plusIconTile.svg?react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../common/state/store";
import { selectCanAddNewFlow } from "../../../automation/AutomationSlice";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { OverflowEllipsesText } from "../../../../UI/atoms/textOverflow/OverflowEllipsesText";
import ClipboardText from "../../../../UI/atoms/clipboardText/ClipboardText";
import { BotModel } from "../../../botList/BotListModel";
import { useNavigate } from "react-router-dom";

interface Props {
  info: BotModel;
  currentPage: number;
  createNewFlow: (botInfo: BotModel) => void;
  redirectToBotFlows: (bot: BotModel) => void;
}

export const WebBotListItem = ({ info, createNewFlow, currentPage, redirectToBotFlows }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "botList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const navigate = useNavigate();
  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;
  const tooltipLabe = t("Copy and paste this code onto every page of your website before the closing </body> tag.");

  const widgetScript = `<script src="${appSettings.chatWidgetScript}"></script> ${" "}
  <script type="text/javascript">window.dominoChatWidget.init("OrganizationId", "BotId", "${
    appSettings.chatWidgetUrl
  }")</script>`;

  return (
    <Box
      className={s.item}
      onClick={() => {
        navigate(`/bots/${info.id}`, { state: { page: currentPage, channel: info?.channel } });
      }}
    >
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Name")}:</Text>
        <Text>{info.name}</Text>
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Title")}:</Text>
        <Text>{info.title}</Text>
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Code")}:</Text>
        <OverflowEllipsesText
          text={"utmLink"}
          tooltip={false}
          content={
            <ClipboardText
              tooltipText={tooltipLabe}
              elemIsCopy={
                <Text fontSize="15px" fontWeight="400">
                  {widgetScript}
                </Text>
              }
              textIsCopy={"ss"}
              color="#3e5e95"
            />
          }
        />
      </Box>
      <Box className={s.info}>
        <Text className={s.mobileTitle}>{t("Flows")}:</Text>
        <Box
          className={s.flowLink}
          onClick={e => {
            e.stopPropagation();
            redirectToBotFlows(info);
          }}
        >
          {info.flowCount}
        </Box>
      </Box>
      <Box className={s.flowInfo} flexDirection={"column"} alignItems={"flex-end"} justifyContent={"space-between"}>
        {!canAddFlow ? (
          <Popover trigger={"hover"}>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Button
                    variant="dominoDashedViolet"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    data-pw="new-flow-button"
                  >
                    <Icon className={`${sp.starPlanHover} starPlanHover`} stroke="#6d5bf7" boxSize="20px" as={StarPlan} />{" "}
                    {t("New Flow")}
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                    <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                    <Flex alignItems="center" justifyContent="center" direction="column">
                      <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                        {tp("Advanced feature")}
                      </PopoverHeader>
                      <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                        {tp("Please upgrade your plan to create more Flows")}
                      </PopoverBody>
                      <TariffPlanRedirectButton onClose={onClose} />
                    </Flex>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
        ) : (
          <Button
            variant="dominoDashedViolet"
            onClick={e => {
              e.stopPropagation();
              createNewFlow(info);
            }}
            data-pw="new-flow-button"
          >
            <Flex gap={"8px"} align={"center"}>
              <PlusIcon />
              {t("New Flow")}
            </Flex>
          </Button>
        )}
      </Box>
    </Box>
  );
};
