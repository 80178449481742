import { Button, Flex, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import s from "../AddWebBot.module.scss";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../../assets/icons/cross.svg?react";
import { useState } from "react";
import { useValidateInput } from "./addWebBotFormValidation";
import { useAppDispatch } from "../../../../common/state/store";
import { createWebBot } from "../../WebBotSlice";
import { CreateWebBotType } from "../../WebBotApi";
import { useNavigate } from "react-router-dom";

export const AddWebBotForm = () => {
  const { t } = useTranslation("translation", { keyPrefix: "addBot.createNew" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const dispatch = useAppDispatch();
  const [nameValue, setNameValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [nameError, setNameError] = useState<string | null>(null);
  const [titleError, setTitleError] = useState<string | null>(null);
  const validateInput = useValidateInput();
  const navigate = useNavigate();

  const handleNameChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setNameValue(value);
    const error = validateInput(value);
    setNameError(error);
  };

  const handleTitleChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setTitleValue(value);
    const error = validateInput(value);
    setTitleError(error);
  };

  const onDeleteName = () => {
    setNameValue("");
    const error = validateInput("");
    setNameError(error);
  };

  const onDeleteTitle = () => {
    setTitleValue("");
    const error = validateInput("");
    setTitleError(error);
  };

  const onCreate = () => {
    const webBot: CreateWebBotType = {
      name: nameValue,
      title: titleValue,
    };
    dispatch(createWebBot(webBot));
  };

  return (
    <Flex className={s.main}>
      <Text variant="h2Bold">{t("Web bot button")}</Text>
      <Flex className={s.formAndButton}>
        <Flex className={s.form}>
          <Flex className={s.formBlock}>
            <Text className={s.title}>{t("Bot name")}</Text>
            <Flex flexDir="column" gap="4px">
              <InputGroup>
                <Input
                  value={nameValue}
                  onChange={handleNameChange}
                  placeholder={t("Website assistant")}
                  className={s.input}
                  isInvalid={!!nameError}
                  maxLength={64}
                />
                <InputRightElement>
                  {nameValue && <CrossIcon color={"#8592A3"} cursor={"pointer"} className={s.crossIcon} onClick={onDeleteName} />}
                </InputRightElement>
              </InputGroup>
              {nameError && <Text variant="inputError">{nameError}</Text>}
            </Flex>
          </Flex>
          <Flex className={s.formBlock}>
            <Text className={s.title}>{t("Widget title on the website")}</Text>
            <Flex flexDir="column" gap="4px">
              <InputGroup>
                <Input
                  value={titleValue}
                  onChange={handleTitleChange}
                  placeholder={t("Online assistant")}
                  className={s.input}
                  isInvalid={!!titleError}
                  maxLength={64}
                />
                <InputRightElement>
                  {titleValue && (
                    <CrossIcon color={"#8592A3"} cursor={"pointer"} className={s.crossIcon} onClick={onDeleteTitle} />
                  )}
                </InputRightElement>
              </InputGroup>
              {titleError && <Text variant="inputError">{titleError}</Text>}
            </Flex>
          </Flex>
        </Flex>
        <Flex className={s.buttons}>
          <Button onClick={() => navigate("/addbot")} variant="dominoOutlineViolet">
            {ct("Cancel")}
          </Button>
          <Button onClick={onCreate} variant="dominoViolet" disabled={!nameValue || !titleValue || !!nameError || !!titleError}>
            {ct("Create")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
