import { Avatar, Box, Button, Flex, Icon, Tooltip } from "@chakra-ui/react";
import s from "../../Sidebar.module.scss";
import { Dispatch, SetStateAction } from "react";
import DownArrowMenu from "../../../../assets/images/downArrow.svg?react";
import { OrganisationModel } from "../../../organisation/OrganisationModel";
import { BotModel } from "../../SidebarModel";

interface Props {
  currentBot: BotModel | undefined;
  organizations: OrganisationModel | undefined;
  isOpenSideBar: boolean;
  setIsOpenSideBar: Dispatch<SetStateAction<boolean>>;
  isOpenBot: boolean;
  setIsOpenBot: Dispatch<SetStateAction<boolean>>;
  visibleToolBot: boolean;
  setVisibleToolBot: Dispatch<SetStateAction<boolean>>;
}

export const SidebarCurrentBot = (props: Props) => {
  return (
    <Tooltip
      variant="dominoLight"
      label={props.currentBot?.name ?? "No bot..."}
      visibility={props.isOpenSideBar || props.visibleToolBot ? "hidden" : "visible"}
      placement={"right"}
    >
      <Button
        data-pw="sidebar-current-bot"
        as={Button}
        justifyContent={"space-between"}
        variant="dominoMenuBtn"
        height={{ base: "48px", md: "60px" }}
        onClick={e => {
          props.setIsOpenSideBar(true);
          props.setIsOpenBot(!props.isOpenBot);
        }}
        onFocus={() => props.setVisibleToolBot(true)}
        onMouseEnter={() => props.setVisibleToolBot(false)}
        onMouseLeave={() => props.setVisibleToolBot(true)}
        pr={0}
        rightIcon={
          <Icon
            as={DownArrowMenu}
            boxSize="14px"
            color="midDeepBlue"
            transform={props.isOpenBot ? "rotate(180deg)" : "rotate(0)"}
            transition="ease-out .2s"
            opacity={props.isOpenSideBar ? "1" : "0"}
            width={props.isOpenSideBar ? "25px" : "0px"}
            mr={props.isOpenSideBar ? "6px" : "0"}
            className={s.arrowChangeBot}
          />
        }
      >
        <Flex
          alignItems={"center"}
          maxWidth={"100%"}
          overflow="hidden"
          maxW="169px"
          position={"relative"}
          zIndex={"2"}
          fontSize={{ base: "14px", md: "15px" }}
        >
          <Avatar variant="dominoAvatar" className={s.menuBotAvatar} name={props.currentBot?.name ?? "No bot..."} size="sm" />

          <Flex maxW="118px" direction="column" align="flex-start">
            <Tooltip
              display={props.currentBot && props.currentBot?.name?.length > 14 ? "auto" : "none"}
              variant="dominoLight"
              label={props.currentBot?.name}
              placement="right"
            >
              <Box w="100%" textAlign="left" noOfLines={1} display="block" className={s.menuText}>
                {props.currentBot?.name ?? "No bot..."}
              </Box>
            </Tooltip>
            <Tooltip
              display={props.organizations && props.organizations?.name.length > 17 ? "auto" : "none"}
              variant="dominoLight"
              label={props.organizations?.name}
              placement="right"
            >
              <Box w="100%" textAlign="left" noOfLines={1} display="block" className={s.menuSmallText}>
                {props.organizations?.name}
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Button>
    </Tooltip>
  );
};
