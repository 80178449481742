import { Box, Text, useDisclosure } from "@chakra-ui/react";
import s from "./TeamsPage.module.scss";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { useEffect, useState } from "react";
import {
  addOperatorsToTeam,
  clearOrganisationTeams,
  deleteOrganisationTeam,
  deleteTeamOperator,
  editOrganisationTeam,
  getOrganisationTeams,
  getOrganisationUsers,
  selectOrganisationTeams,
  selectOrganisationTeamUsage,
} from "../../OrganisationSlice";
import { DeleteTeamPopup } from "../DeleteTeamPopup/DeleteTeamPopup";
import { DeleteOperatorFromTeamPopup } from "../DeleteOperatorFromTeamPopup/DeleteOperatorFromTeamPopup";
import { OrganisationTeamItemModel, TeamMemberModel } from "../../OrganisationModel";
import { AddOperatorModal } from "../AddOperatorModal/AddOperatorModal";
import { TeamListItem } from "./components/TeamListItem";
import { PaginatorWithSearchParams } from "../../../../common/paginator/components/PaginatorWithSearchParams";
import { TEAMS_SIZE } from "../../../../common/paginator/paginatorSizes";

export const TeamsPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "organisation" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const teams = useAppSelector(selectOrganisationTeams);
  const hasTeamsItems = teams?.totalItems;
  const teamUsageFlows = useAppSelector(selectOrganisationTeamUsage);
  const [searchParams] = useSearchParams("");
  const [deleteTeamPopupState, setDeleteTeamPopupState] = useState<{
    id: string;
    itemName: string;
  } | null>(null);
  const [deleteOperatorPopupState, setDeleteOperatorPopupState] = useState<{
    teamId: string;
    operatorId: string;
    teamName: string;
    operatorName: string;
  } | null>(null);
  const [editMode, setEditMode] = useState({ teamId: "", teamName: "" });
  const [addOperatorModalState, setAddOperatorModalState] = useState<{
    includedOperators: TeamMemberModel[];
    teamId: string;
  }>({
    includedOperators: [],
    teamId: "",
  });
  const currentPage = Number(searchParams.get("page") ?? 1);

  useEffect(() => {
    dispatch(getOrganisationTeams({ page: currentPage }));
  }, [currentPage, dispatch]);

  const onDelete = (teamId: string) => {
    dispatch(deleteOrganisationTeam({ teamId }));
  };

  const onDeleteOperator = (teamId: string, operatorId: string) => {
    const operatorIds = [operatorId];
    dispatch(deleteTeamOperator({ teamId, operatorIds }));
  };

  const onEditTeam = (team: OrganisationTeamItemModel) => {
    if (team.name !== editMode.teamName && editMode.teamName) {
      const teamItem = { ...team, name: editMode.teamName.replace(/ +/g, " ").trim() };
      dispatch(editOrganisationTeam(teamItem));
    }
    setEditMode({ teamId: "", teamName: "" });
  };

  const onDeleteTeamPopupClose = () => setDeleteTeamPopupState(null);
  const onDeleteOperatorPopupClose = () => setDeleteOperatorPopupState(null);

  const onApplyOperatorModal = (assignedOperatorIds: string[], teamId: string) => {
    dispatch(addOperatorsToTeam({ teamId, operatorIds: assignedOperatorIds }));
    onClose();
  };

  const onOperatorSearchChange = (value: string) => {
    dispatch(getOrganisationUsers({ page: 1, filter: value }));
  };

  useEffect(() => {
    return () => {
      dispatch(clearOrganisationTeams());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!teams) {
    return <></>;
  }

  return (
    <Box className={s.teamListContainer}>
      <Box className={s.teamListContent}>
        <Box color="darkGrey" className={`${s.teamListItemGrid} ${s.listName}`} mx="24px" h="52px">
          <Text></Text>
          {hasTeamsItems ? (
            <>
              <Text pt="16px" noOfLines={1} variant="medium">
                {t("Name")}
              </Text>
              <Text pt="16px" noOfLines={1} variant="medium" textAlign="end">
                {t("Operators")}
              </Text>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box position="relative" paddingBottom={"48px"}>
          <TeamListItem
            teams={teams}
            setAddOperatorModalState={setAddOperatorModalState}
            onEditTeam={onEditTeam}
            editMode={editMode}
            setEditMode={setEditMode}
            setDeleteOperatorPopupState={setDeleteOperatorPopupState}
            setDeleteTeamPopupState={setDeleteTeamPopupState}
            onOpen={onOpen}
          />
          <DeleteTeamPopup
            flows={teamUsageFlows}
            deleteItemState={deleteTeamPopupState}
            onClose={onDeleteTeamPopupClose}
            onDelete={onDelete}
          />
          <DeleteOperatorFromTeamPopup
            deleteItemState={deleteOperatorPopupState}
            onClose={onDeleteOperatorPopupClose}
            onDelete={onDeleteOperator}
          />
          <AddOperatorModal
            onOperatorSearchChange={onOperatorSearchChange}
            isOpen={isOpen}
            onClose={onClose}
            modalState={addOperatorModalState}
            onApply={onApplyOperatorModal}
          />
          <Box className={s.paginatorBox}>
            <PaginatorWithSearchParams
              sizeItems={TEAMS_SIZE}
              paddingRight={{ base: "28px", sm: "16px", md: "32px" }}
              data={teams}
              typeOfPage="TeamList"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
