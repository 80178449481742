import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

declare type MyTooltop<MyValue extends ValueType, MyName extends NameType> = TooltipProps<MyValue, MyName> & {
  isTranslate?: boolean;
};

export default function DashboardCustomTooltipChart({ active, payload, isTranslate }: MyTooltop<ValueType, NameType>) {
  const { t } = useTranslation("translation", { keyPrefix: "dashboard" });
  if (active && payload && payload.length) {
    return (
      <Box
        maxW="200px"
        maxH="300px"
        overflow="hidden"
        padding="8px"
        borderRadius="4px"
        backgroundColor="white"
        boxShadow="0 0 10px #0000001A"
      >
        <Text lineHeight="16px" fontSize="13px" mb="4px">
          {payload[0].payload.name}
        </Text>
        {payload.map((item, ind) => {
          return (
            <Flex lineHeight="16px" fontSize="13px" gap="4px" color={item.color} key={item.name + String(ind)}>
              <Text noOfLines={1}>{item.name ? (isTranslate ? t(item.name.toString()) : item.name.toString()) : ""}</Text>
              <Text>{item.value}</Text>
            </Flex>
          );
        })}
      </Box>
    );
  }
  return null;
}
