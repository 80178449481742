import React, { useState } from "react";
import s from "./ButtonInput.module.scss";
import { Box, Input, Button, ButtonOptions, Icon, InputGroup, InputRightElement, Text } from "@chakra-ui/react";

export const ButtonInput = ({
  onChange,
  value,
  leftIcon,
  rightIcon,
  maxLength = 100,
}: {
  value: string;
  onChange: (text: string) => void;
  leftIcon: ButtonOptions["leftIcon"];
  rightIcon?: ButtonOptions["rightIcon"];
  maxLength?: number;
}) => {
  const [editButton, setButton] = useState(false);
  const [initValue, setInitValue] = useState(value);

  const onChangeComplete = () => {
    if (value.trim() === "") {
      onChange(initValue);
    } else {
      setInitValue(value);
    }
    setButton(false);
  };

  return (
    <Box className={s.wrapper} w="100%">
      {!editButton && (
        <Button
          bgColor="lightDeepBlue"
          border="1px solid var(--chakra-colors-line)"
          w="100%"
          onClick={() => setButton(true)}
          leftIcon={leftIcon}
          rightIcon={rightIcon || <Icon opacity={0} boxSize="24px" />}
          justifyContent="space-between"
          _hover={{
            bgColor: "bgMiddle",
            borderColor: "blueLink",
          }}
          _focusVisible={{ boxShadow: "none" }}
        >
          <Text
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {value}
          </Text>
        </Button>
      )}
      {editButton && (
        <InputGroup>
          <Input
            maxLength={maxLength}
            onKeyDown={e => {
              if (e.key === "Enter") {
                onChangeComplete();
              }
            }}
            onChange={e => onChange(e.target.value)}
            autoFocus
            onBlur={() => onChangeComplete()}
            value={value}
          />
          <InputRightElement color={"blueLink"}>{maxLength - value.length}</InputRightElement>
        </InputGroup>
      )}
    </Box>
  );
};
