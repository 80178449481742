import { Box, Flex, Heading, Icon, useMediaQuery, useDisclosure, Skeleton, SkeletonCircle, Text } from "@chakra-ui/react";
import s from "./ContactInfo.module.scss";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import {
  getContactInfo,
  getContactInfoFiles,
  getContactVariables,
  getOperators,
  getTeams,
  selectContactInfo,
  selectContactInfoDocFiles,
  selectContactInfoMediaFiles,
  selectContactVariables,
  selectOperators,
  selectTeams,
} from "./ContactInfoSlice";
import { selectCurrentBot } from "../sidebar/SidebarSlice";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../assets/icons/cross.svg?react";
import { assignConversation, selectConversationInfo } from "../conversation/ConversationSlice";
import { AssignedItemModel } from "../conversation/ConversationModel";
import { ApplicationState } from "../../common/state/ApplicationState";

import { mapVariableValuesToObject } from "./ContactInfoMapper";
import ContactInfoVariables from "./components/ContactInfoVariables/ContactInfoVariables";
import ContactInfoFlow from "./components/ContactInfoFlow/ContactInfoFlow";
import ContactInfoAssigned from "./components/ContactInfoAssigned/ContactInfoAssigned";
import ContactInfoAvatar from "./components/ContactInfoAvatar/ContactInfoAvatar";
import ContactInfoFiles from "./components/ContactInfoFiles/ContactInfoFiles";
import ContactMediaFiles from "./components/ContactInfoFiles/ContactMediaFiles/ContactMediaFiles";
import ContactDocuments from "./components/ContactInfoFiles/ContactDocuments/ContactDocuments";
import { throttleRequest } from "../../common/hooks/useDebounce";
import { ContactInfoBlockConversation } from "./components/ContactInfoBlock/ContactInfoBlockConversation";
import { EventCategories } from "../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../common/ga/GAEventTracker";
import { ChatEvent } from "../../common/ga/gaEventsEnums.ts/ChatGaEventsEnums";

export interface Props {
  conversationId: string;
  onClose?: () => void;
}

export const ContactInfoContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const dispatch = useAppDispatch();
  const [openMedia, setOpenMedia] = useState(false);

  const onOpenMediaClick = () => {
    setOpenMedia(true);
  };
  const [openDocs, setOpenDocs] = useState(false);
  const onOpenDocsClick = () => {
    setOpenDocs(true);
  };

  const trackEvent = GTM(EventCategories.Chats);

  const currentBot = useAppSelector(selectCurrentBot);
  const botId = currentBot?.id;
  const isWebBot = currentBot?.channel === "Web";
  const contactInfo = useAppSelector(selectContactInfo);
  const contactVariables = useAppSelector(selectContactVariables);
  const conversationInfo = useAppSelector(selectConversationInfo);
  const operators = useAppSelector(selectOperators);
  const teams = useAppSelector(selectTeams);
  const loadingState = useAppSelector((state: ApplicationState) => state.loadingState);
  const contactInfoDocs = useAppSelector(selectContactInfoDocFiles);
  const contactInfoMedia = useAppSelector(selectContactInfoMediaFiles);

  const conversationId = props.conversationId;
  const [contactId, setContactId] = useState<string | undefined>();

  const [searchParams, setSearchParams] = useSearchParams();
  const clearSearchParams = () => {
    searchParams.delete("search");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setOpenMedia(false);
    setOpenDocs(false);
    clearSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  const onTeamMenuClose = useDisclosure().onClose;
  const onOperatorMenuClose = useDisclosure().onClose;
  const [assignState, setAssignState] = useState<{ team?: AssignedItemModel; operator?: AssignedItemModel }>({
    team: conversationInfo?.assignedTeam ?? { id: "", name: "" },
    operator: conversationInfo?.assignedOperator ?? { id: "", name: "" },
  });
  const [teamSearchValue, setTeamSearchValue] = useState("");
  const [operatorSearchValue, setOperatorSearchValue] = useState("");
  const [variablesText, setVariablesText] = useState<Record<string, unknown> | undefined>(
    mapVariableValuesToObject(contactVariables?.items),
  );
  const [isDesktop] = useMediaQuery("(min-width: 1270px)");

  const [currentAssignState, setCurrentAssignState] = useState<{ team?: AssignedItemModel; operator?: AssignedItemModel }>({
    team: conversationInfo?.assignedTeam ?? { id: "", name: "" },
    operator: conversationInfo?.assignedOperator ?? { id: "", name: "" },
  });

  mapVariableValuesToObject(contactVariables?.items);
  const contactInfoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVariablesText(mapVariableValuesToObject(contactVariables?.items));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactVariables]);

  useEffect(() => {
    if (conversationInfo) {
      setAssignState({
        team: conversationInfo?.assignedTeam ?? { id: "", name: "" },
        operator: conversationInfo?.assignedOperator ?? { id: "", name: "" },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationInfo]);

  useEffect(() => {
    const teamId = assignState?.team?.id ?? "";
    const operatorId = assignState?.operator?.id ?? "";
    const currentTeamId = currentAssignState?.team?.id ?? "";
    const currentOperatorId = currentAssignState?.operator?.id ?? "";

    if (currentTeamId !== teamId || currentOperatorId !== operatorId) {
      onAssignConversation(assignState.team, assignState.operator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignState]);

  useEffect(() => {
    setContactId(conversationInfo?.contact?.id);

    if (conversationInfo?.contact?.id && botId) {
      dispatch(getContactInfo({ botId, contactId: conversationInfo.contact.id, conversationId }));
      dispatch(getContactInfoFiles({ conversationId }));
      dispatch(getContactVariables({ contactId: conversationInfo.contact.id, size: "20", page: "1" }));
      dispatch(getTeams({ page: 1 }));
      dispatch(getOperators({ page: 1 }));
    }
  }, [botId, dispatch, conversationId, conversationInfo?.contact?.id]);

  const getTeamPage = (page: number) => {
    dispatch(getTeams({ page }));
  };

  const getOperatorPage = (page: number) => {
    dispatch(getOperators({ page }));
  };

  const getAdditionalItems = (item: "Team" | "Operator") => {
    if (item === "Team" && teams?.currentPage) {
      getTeamPage(teams.currentPage + 1);
    }
    if (item === "Operator" && operators?.currentPage) {
      getOperatorPage(operators.currentPage + 1);
    }
  };

  function onAssignConversation(team?: AssignedItemModel, operator?: AssignedItemModel) {
    if (conversationId) {
      const hasTeamChanged = currentAssignState.team?.id !== team?.id;
      const hasOperatorChanged = currentAssignState.operator?.id !== operator?.id;

      if (hasTeamChanged || hasOperatorChanged) {
        dispatch(assignConversation({ conversationId, team, operator }));

        if (hasTeamChanged) {
          trackEvent(ChatEvent.ChatTeamAssigned);
        }
        if (hasOperatorChanged) {
          trackEvent(ChatEvent.ChatOperatorAssigned);
        }

        setCurrentAssignState({ team, operator });
      }
    }
  }

  const onSearchTextChange = (value: string, type: "Team" | "Operator") => {
    if (type === "Team") {
      setTeamSearchValue(value);
      dispatch(getTeams({ page: 1, filter: value }));
      return;
    }
    setOperatorSearchValue(value);
    dispatch(getOperators({ page: 1, filter: value }));
  };

  const onMenuClose = (type: "Team" | "Operator") => {
    if (type === "Team") {
      onSearchTextChange("", "Team");
      onTeamMenuClose();
      return;
    }
    onSearchTextChange("", "Operator");
    onOperatorMenuClose();
  };

  const getVariablesContactsOnScroll = () => {
    if (contactInfoRef.current) {
      const scrollHeight = contactInfoRef.current.scrollHeight;
      const scrollTop = contactInfoRef.current.scrollTop;
      const scrollPosition = scrollHeight - scrollTop;
      const isBottom = scrollPosition < contactInfoRef.current?.getBoundingClientRect().bottom;
      if (
        isBottom &&
        conversationInfo?.contact &&
        contactVariables?.items &&
        contactVariables?.currentPage < contactVariables?.totalPages
      ) {
        dispatch(
          getContactVariables({
            contactId: conversationInfo.contact.id,
            size: "20",
            page: String(contactVariables?.currentPage + 1),
          }),
        );
      }
    }
  };

  if (!contactId || !botId || !contactInfo || !contactVariables || !variablesText) {
    return (
      <Flex flexDir={"column"} h={"100%"}>
        <Flex justifyContent="center" className={s.header}>
          {!isDesktop && <Icon className={s.crossIcon} as={CrossIcon} boxSize="42px" onClick={() => props.onClose?.()} />}
          <Skeleton className={s.skeletonBase} speed={0.5} height="24px" width="80% !important" />
        </Flex>
        <Box className={`${s.contactInfoWrapper} loaderContainer`}>
          <Loading scope="contactInfo" />
          <Flex className={s.contactInfo} flexDir={"column"} alignItems={"center"}>
            <SkeletonCircle speed={0.5} startColor="line" endColor="bgLight" size="100px" />
            <Flex w="100%" alignItems={"center"} flexDir={"column"} mt={"16px"}>
              <Skeleton className={s.skeletonBase} speed={0.5} w="100%" height="12px" />
              <Flex w="100%" className={s.contactName}>
                <Skeleton className={s.skeletonBase} speed={0.5} height="12px" />
              </Flex>
            </Flex>
          </Flex>
          <Flex className={s.assignContainer} flexDir={"column"} justifyContent={"center"}>
            <Heading fontSize={"16px"} fontWeight={"400"}>
              {t("Assigned Team")}
            </Heading>
            <Skeleton className={s.skeletonBase} mt={"8px"} speed={0.5} height="36px" />
            <Heading fontSize={"16px"} fontWeight={"400"} mt="16px">
              {t("Assigned Operator")}
            </Heading>
            <Skeleton className={s.skeletonBase} mt={"8px"} speed={0.5} height="36px" />
            <Skeleton className={s.skeletonBase} mt={"16px"} speed={0.5} height="32px" />
          </Flex>
          <Flex className={s.contactInFlow} flexDir={"column"} justifyContent={"center"}>
            <Skeleton className={s.skeletonBase} mt={"16px"} speed={0.5} height="24px" />
          </Flex>

          <Flex className={s.userFields} flexDirection={"column"}>
            <Flex w={"100%"}>
              <Heading fontSize={"16px"} fontWeight={"400"}>
                {t("Contact fields")}
              </Heading>
            </Flex>
            {[1, 2, 3, 4, 5].map(el => (
              <Skeleton key={el} className={s.skeletonBase} mt={"16px"} speed={0.5} height="24px" />
            ))}
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <>
      {!openMedia && !openDocs ? (
        <Flex flexDir={"column"} h={"100%"}>
          <Box className={s.header}>
            {!isDesktop && <Icon as={CrossIcon} className={s.crossIcon} boxSize="42px" onClick={() => props.onClose?.()} />}
            <Text color={conversationInfo?.isBlocked ? "darkGrey" : "inherit"}>
              {contactInfo.firstName} {contactInfo.lastName}
            </Text>
          </Box>
          <Box
            ref={contactInfoRef}
            onScroll={throttleRequest(getVariablesContactsOnScroll, 300)}
            className={`${s.contactInfoWrapper} loaderContainer`}
          >
            <Loading scope="contactInfo" />
            <ContactInfoAvatar contactInfo={contactInfo} isBlocked={conversationInfo?.isBlocked ?? false} />
            {!isWebBot && <ContactInfoFiles onOpenMediaClick={onOpenMediaClick} onOpenDocsClick={onOpenDocsClick} />}
            <ContactInfoAssigned
              conversationInfo={conversationInfo}
              assignState={assignState}
              onMenuClose={onMenuClose}
              getAdditionalItems={getAdditionalItems}
              onSearchTextChange={onSearchTextChange}
              operatorSearchValue={operatorSearchValue}
              teamSearchValue={teamSearchValue}
              operators={operators}
              setAssignState={setAssignState}
              teams={teams}
            />
            <ContactInfoFlow contactInfo={contactInfo} />
            <ContactInfoVariables
              contactId={contactId}
              contactVariables={contactVariables.items}
              setVariablesText={setVariablesText}
              variablesText={variablesText}
            />
            {loadingState.getContactVariables.isLoading &&
              [1, 2, 3, 4, 5].map(el => <Skeleton key={el} className={s.skeletonBase} mt={"16px"} speed={0.5} height="24px" />)}
            {!isWebBot && conversationInfo && !conversationInfo.isBlocked && (
              <ContactInfoBlockConversation conversationInfo={conversationInfo} />
            )}
          </Box>
        </Flex>
      ) : openMedia ? (
        <ContactMediaFiles conversationId={conversationId} setOpenMedia={setOpenMedia} mediaFilesCount={contactInfoMedia} />
      ) : openDocs ? (
        <ContactDocuments conversationId={conversationId} setOpenDocs={setOpenDocs} documentsCount={contactInfoDocs} />
      ) : null}
    </>
  );
};
