import s from "./AddNodePopup.module.scss";
import sp from "../../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import StarPlan from "../../../../assets/icons/starPlan.svg?react";
import {
  extrasNodes,
  FlowActionType,
  NodeModel,
  telegramNodes,
  webBotExtrasNodes,
  webBotTelegramNodes,
} from "../../FlowBuilderModel";
import {
  Box,
  Flex,
  Text,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SimpleGrid,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { TariffPlanRedirectButton } from "../../../../common/tariffPlan/TariffPlanRedirectButton";
import { NodeItem } from "./NodeItem";
import { useAppSelector } from "../../../../common/state/store";
import { selectFlow } from "../../FlowBuilderSlice";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";

interface Props {
  addNode: (newNode: NodeModel) => void;
  onClose: () => void;
  flowBuilderWidth: number;
  flowBuilderHeight: number;
}

export const AddNodePopup = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const typeIsAccessability = (title: string) => {
    switch (title) {
      case "HTTP request":
        return TariffPlanFeatureTypeEnum.NodeHttpRequest;
      case "Delay":
        return TariffPlanFeatureTypeEnum.NodeDelay;
      case "Start Flow":
        return TariffPlanFeatureTypeEnum.NodeStartFlow;
      case "AI Assistant":
        return TariffPlanFeatureTypeEnum.NodeAiAgent;
      default:
        break;
    }
  };
  const flow = useAppSelector(selectFlow);
  const currentBot = useAppSelector(selectCurrentBot);
  const isDisabledNode = (type: keyof typeof FlowActionType) => {
    switch (type) {
      case FlowActionType.AiFlowAction:
        return !!flow?.nodes.find(el => el.data?.typeDiscriminator === FlowActionType.AiFlowAction);
      default:
        return false;
    }
  };

  return (
    <PopoverContent width={["300px", "auto", "auto"]} padding={"16px"}>
      <Text variant="largeBold" paddingBottom="12px">
        {currentBot?.channel !== "Web" ? t("Telegram") : t("Widget")}
      </Text>
      <PopoverCloseButton data-pw="close-button" onClick={props.onClose} className={s.closeIcon} />
      <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
        {(currentBot?.channel !== "Web" ? telegramNodes : webBotTelegramNodes).map(el => (
          <NodeItem
            key={el.typeDiscriminator}
            title={el.title}
            actionType={el.typeDiscriminator}
            iconType={el.icon}
            addNode={props.addNode}
            flowBuilderWidth={props.flowBuilderWidth}
            flowBuilderHeight={props.flowBuilderHeight}
          />
        ))}
      </SimpleGrid>
      <Box className={s.extras}>
        <Text variant="largeBold" paddingBottom="12px">
          {t("Extras")}
        </Text>
        <SimpleGrid columns={[1, 2, 2]} spacing={[5, 5, 5]} gap="8px">
          {(currentBot?.channel !== "Web" ? extrasNodes : webBotExtrasNodes).map(el => {
            const type = typeIsAccessability(el.title);
            const { IsAvailable } = type ? getTariffPlanAccessability(type) : { IsAvailable: true };
            return (
              <>
                {!IsAvailable ? (
                  <Popover key={el.typeDiscriminator} trigger={"hover"}>
                    {({ onClose }) => (
                      <>
                        <PopoverTrigger>
                          <Flex className={s.starPlanItem}>
                            <Flex align="center" gap="8px">
                              <Icon className={s.svg} as={el.icon} />
                              <Box maxW="110px" className={s.typeName}>
                                {t(el.title)}
                              </Box>
                            </Flex>
                            <Box className={`${sp.starPlanHover} ${s.starPlan}`}>
                              <Icon boxSize="14px" as={StarPlan} />
                            </Box>
                          </Flex>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                            <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                            <Flex alignItems="center" justifyContent="center" direction="column">
                              <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                                {tp("Advanced feature")}
                              </PopoverHeader>
                              <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                                {tp("Please upgrade your plan to invite more Operators")}
                              </PopoverBody>
                              <TariffPlanRedirectButton onClose={onClose} />
                            </Flex>
                          </PopoverContent>
                        </Portal>
                      </>
                    )}
                  </Popover>
                ) : (
                  <NodeItem
                    key={el.typeDiscriminator}
                    title={el.title}
                    actionType={el.typeDiscriminator}
                    iconType={el.icon}
                    isDisabled={isDisabledNode(el.typeDiscriminator)}
                    addNode={props.addNode}
                    flowBuilderWidth={props.flowBuilderWidth}
                    flowBuilderHeight={props.flowBuilderHeight}
                  />
                )}
              </>
            );
          })}
        </SimpleGrid>
      </Box>
    </PopoverContent>
  );
};
