import { SetURLSearchParams } from "react-router-dom";

export const constructSearchParams = (
  search: string,
  debouncedSearchText: string | undefined,
  setSearchParams: SetURLSearchParams,
) => {
  if (search !== debouncedSearchText) {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (debouncedSearchText !== "") {
      currentSearchParams.set("search", debouncedSearchText ?? "");
    } else {
      currentSearchParams.delete("search");
    }
    setSearchParams(currentSearchParams.toString());
  }
};
