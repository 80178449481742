import { Helmet } from "react-helmet";
import WidgetScript from "./WidgetScript";

function HelmetContainer() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Helmet>
      <WidgetScript />
    </>
  );
}

export default HelmetContainer;
