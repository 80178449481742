import s from "./SendMessageNode.module.scss";
import { ActionNodeProps } from "../ActionNodeProps";
import { useTranslation } from "react-i18next";
import TelegramIcon from "../../../../../assets/icons/telegramIcon.svg?react";
import WidgetFill from "../../../../../assets/icons/dialogFill.svg?react";
import { Flex, Icon } from "@chakra-ui/react";
import { MessageButtonBlock } from "../MessageButtonBlock/MessageButtonBlock";
import { getButtonsWithExactType } from "../../../utils";
import { SendTextFlowActionModel } from "../../../FlowBuilderModel";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";

function SendMessageNode(props: { actionData: ActionNodeProps }) {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = props.actionData.node.flowAction as SendTextFlowActionModel;
  const currentBot = useAppSelector(selectCurrentBot);

  return (
    <Flex flexDirection="column" alignItems="center">
      <div className={s.header}>
        {currentBot?.channel === "Web" ? (
          <Flex h="32px" w="32px" rounded="full" bg="mainPurple" align="center" justify="center">
            <Icon as={WidgetFill} color="white" boxSize="20px" />
          </Flex>
        ) : (
          <Icon as={TelegramIcon} boxSize="32px" />
        )}
        <div className={s.textBlock}>
          <div className={s.telegram}>{currentBot?.channel === "Web" ? t("Widget") : t("Telegram")}</div>
          <div className={s.type}>{t("Send Message")}</div>
        </div>
      </div>
      {data.text || getButtonsWithExactType(data?.replyMarkup, props.actionData.node.flowAction)?.length ? (
        <MessageButtonBlock actionData={props.actionData} />
      ) : (
        <Flex justifyContent="center" alignItems="center" className={s.noTextBlock} h="74px">
          {t("Add Text")}
        </Flex>
      )}
    </Flex>
  );
}

export default SendMessageNode;
