import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const dominoGreenVariant = definePartsStyle({
  label: {
    marginLeft: "12px",
  },
  control: {
    height: "20px",
    width: "20px",
    borderRadius: "6px",
    borderColor: "darkGrey",
    _checked: {
      backgroundColor: "greenActive",
      borderColor: "greenActive",
      _hover: {
        backgroundColor: "green",
        borderColor: "green",
      },
    },
    _disabled: {
      color: "var(--chakra-colors-gray-200) !important",
    },
  },
});

const dominoBlueVariant = definePartsStyle({
  label: {
    marginLeft: "12px",
  },
  control: {
    height: "20px",
    width: "20px",
    borderRadius: "6px",
    borderColor: "darkGrey",
    _checked: {
      backgroundColor: "accentBlue",
      borderColor: "accentBlue",
      _hover: {
        backgroundColor: "blueLink",
        borderColor: "blueLink",
      },
    },
    _disabled: {
      color: "var(--chakra-colors-gray-200) !important",
    },
  },
});

const dominoOrangeVariant = definePartsStyle({
  label: {
    marginLeft: "12px",
  },
  control: {
    height: "20px",
    width: "20px",
    borderRadius: "6px",
    borderColor: "darkGrey",
    _checked: {
      backgroundColor: "mainYellow",
      borderColor: "mainYellow",
      _hover: {
        backgroundColor: "boldYellow",
        borderColor: "boldYellow",
      },
    },
    _disabled: {
      color: "var(--chakra-colors-gray-200) !important",
    },
  },
});

const dominoDashboardVariant = definePartsStyle({
  label: {
    marginLeft: "12px",
    width: "max-content",
  },
  control: {
    height: "20px",
    width: "20px",
    borderRadius: "6px",
    borderColor: "darkGrey",
    _disabled: {
      color: "var(--chakra-colors-gray-200) !important",
    },
  },
  icon: {
    zIndex: "2",
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: {
    dominoGreen: dominoGreenVariant,
    dominoBlue: dominoBlueVariant,
    dominoOrange: dominoOrangeVariant,
    dominoDashboard: dominoDashboardVariant,
  },
});
