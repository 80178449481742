import { ActionNodeProps } from "../ActionNodeProps";
import { Flex, Box, Icon, Text } from "@chakra-ui/react";
import AIAssistant from "../../../../../assets/icons/sidebar/aiAssistant.svg?react";
import CircleErrorIcon from "../../../../../assets/icons/cancelIcon.svg?react";
import CircleCheckIcon from "../../../../../assets/icons/circleCheckIcon.svg?react";

import s from "./AINode.module.scss";
import { useTranslation } from "react-i18next";
import {
  AiFlowActionModel,
  AssignFlowActionModel,
  FlowActionType,
  FlowModel,
  NodeModel,
  NodeType,
} from "../../../FlowBuilderModel";
import { OperatorBlock } from "./components/OperatorBlock/OperatorBlock";
import { selectAIAssistantList } from "../../../../aiAssistant/AIAssistantListSlice";
import { useAppDispatch, useAppSelector } from "../../../../../common/state/store";
import { useEffect } from "react";
import { setEdges, setNodes } from "../../../FlowBuilderSlice";
import { MarkerType } from "reactflow";
import { v4 as uuidv4 } from "uuid";

export const AINode = ({ actionData, flow }: { actionData: ActionNodeProps; flow: FlowModel }) => {
  const { t } = useTranslation("translation", { keyPrefix: "flow" });
  const data = actionData.node.flowAction as AiFlowActionModel;
  const aiAssistantList = useAppSelector(selectAIAssistantList);
  const dispatch = useAppDispatch();

  const addOperatorNode = () => {
    const id = "ai_operator_node";
    const hasAiOperatorNode = flow?.nodes.find(el => el.id === id);
    const hasAiOperatorEdge = flow?.edges.find(el => el.target === id && el.sourceHandle === "ai-source");
    if (hasAiOperatorEdge) {
      return;
    }
    const aiNode = flow?.nodes.find(el => el.data?.typeDiscriminator === FlowActionType.AiFlowAction);
    if (!aiNode) {
      return;
    }
    const newNode: NodeModel = {
      id,
      type: NodeType.Action,
      position: {
        x: aiNode ? aiNode?.position.x + 500 : 0,
        y: aiNode ? aiNode?.position.y + (aiNode.height ? aiNode.height / 2 : 0) : 0,
      },
      flowAction: {
        title: `Node ${id}`,
        typeDiscriminator: FlowActionType.AssignFlowAction,
        operatorId: null,
        operatorName: null,
        teamId: null,
        teamName: null,
      } as AssignFlowActionModel,
    };

    if (newNode && newNode.position && flow) {
      const newNodes = [
        ...flow.nodes,
        {
          id: newNode.id,
          type: newNode.type,
          position: newNode.position,
          data: newNode.flowAction,
        },
      ];
      const newEdge = {
        source: aiNode?.id,
        sourceHandle: "ai-source",
        target: newNode.id,
        targetHandle: "common-target",
        markerEnd: {
          type: MarkerType.ArrowClosed,
        },
        id: uuidv4(),
      };
      if (!hasAiOperatorNode) {
        dispatch(setNodes(newNodes));
      }
      dispatch(setEdges([...flow.edges, newEdge]));
    }
  };

  useEffect(() => {
    if (!data.operatorOptions.isRequestOperatorEnabled) {
      const newEdges = flow?.edges.filter(el => el.sourceHandle !== "ai-source");
      dispatch(setEdges(newEdges || []));
    } else {
      addOperatorNode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.operatorOptions.isRequestOperatorEnabled]);

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb="4px" className={s.header}>
        <Icon color="mainPurple" as={AIAssistant} boxSize="32px" />
        <Box className={s.textBlock}>
          <Box className={s.telegram}>{t("Telegram")}</Box>
          <Box className={s.type}>{t("AI Assistant")}</Box>
        </Box>
      </Box>
      {(data.welcomeMessageText ||
        data.operatorOptions.isRequestOperatorEnabled ||
        data.assistantId ||
        data.closingOptions.closingQuestionText) && (
        <Flex w="100%" flexDirection="column" gap="16px" mt="4px">
          {data.assistantId && (
            <Flex className={s.aiName}>
              <Text variant="medium">{t("AI")}:</Text>
              <Text variant="medium" color="midDeepBlue !important">
                {data.assistantName || aiAssistantList?.find(el => el.id === data.assistantId)?.name}
              </Text>
            </Flex>
          )}
          {data.welcomeMessageText && <Box className={s.baseTextViewBlock}>{data.welcomeMessageText}</Box>}
          {data.closingOptions.closingQuestionText && (
            <Flex className={s.questionBlock}>
              <Box className={s.baseTextViewBlock}>{data.closingOptions.closingQuestionText}</Box>
              <Flex className={s.answers}>
                <Flex w="100%" gap="4px" align="center" justify="space-between" className={s.baseTextViewBlock}>
                  <Icon as={CircleCheckIcon} boxSize="20px" />
                  <Text color="black !important" noOfLines={1}>
                    {data.closingOptions.successButtonText}
                  </Text>
                  <Icon opacity="0" as={CircleCheckIcon} boxSize="20px" />
                </Flex>
                <Flex w="100%" gap="4px" align="center" justify="space-between" className={s.baseTextViewBlock}>
                  <Icon as={CircleErrorIcon} boxSize="20px" />
                  <Text color="black !important" noOfLines={1}>
                    {data.closingOptions.failureButtonText}
                  </Text>
                  <Icon opacity="0" as={CircleErrorIcon} boxSize="20px" />
                </Flex>
              </Flex>
            </Flex>
          )}
          {data.operatorOptions.isRequestOperatorEnabled && <OperatorBlock actionData={actionData} flow={flow} />}
        </Flex>
      )}

      {!data.welcomeMessageText &&
        !data.operatorOptions.isRequestOperatorEnabled &&
        !data.assistantId &&
        !data.closingOptions.closingQuestionText && (
          <div className={s.noCondition}>
            <h4>{t("Select AI Assistant")}</h4>
          </div>
        )}
    </Flex>
  );
};
