import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleException } from "../../../../../../common/SagaHelper";
import { groupChatMemberListSlice, selectMemberList, selectSearchText } from "./GroupChatMemberListSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { beginScope, completeScope } from "../../../../../../common/loading/LoadingStateActions";
import * as GroupChatMemberListApi from "./GroupChatMemberListApi";
import { MemberData, MemberListData } from "./GroupChatMemberListData";
import { MemberListModel, MemberModel } from "./GroupChatMemberListModel";
import { signalRConnection } from "../../../../../../middlewares/signalRMiddleware";
import { mapGroupChatMemberListDataToModel } from "./GroupChatMemberListMapper";
import { store } from "../../../../../..";
import { MEMBERS_PER_PAGE } from "../../../../../../common/paginator/paginatorSizes";
import { GroupChatModel } from "../../../../GroupChatModel";
import { groupChatInfoSlice, selectGroupChatInfo } from "../GroupChatInfo/GroupChatInfoSlice";
import { t } from "i18next";
import { notify } from "../../../../../../common/notifications/NotificationSlice";
import { EventCategories } from "../../../../../../common/ga/gaEventCategoryEnums/EventCategoryEnums";
import GTM from "../../../../../../common/ga/GAEventTracker";
import { GroupsChatMembersEvents } from "../../../../../../common/ga/gaEventsEnums.ts/GroupGAEventsEnums";

export function* groupChatMemberListSaga() {
  yield takeLatest(groupChatMemberListSlice.actions.getMemberList, getMemberList);
  yield takeLatest(groupChatMemberListSlice.actions.blockMember, blockMember);
  yield takeLatest(groupChatMemberListSlice.actions.unblockMember, unblockMember);
  yield takeLatest(groupChatMemberListSlice.actions.muteMember, muteMember);
  yield takeLatest(groupChatMemberListSlice.actions.unmuteMember, unmuteMember);
  yield takeLatest(groupChatMemberListSlice.actions.deleteMember, deleteMember);

  signalRConnection.addHandler("receiveGroupMember", (data: MemberData) => {
    const model: MemberModel = { ...data };
    const updatedMemberList: MemberListModel = mapGroupChatMemberListDataToModel(model);
    const currentGroupChatId = store.getState().app.groupChatInfoSlice.groupChatInfo?.id;
    if (currentGroupChatId === model.groupId) {
      store.dispatch(groupChatMemberListSlice.actions.getMemberListCompleted(updatedMemberList));
      if (model.isNew) {
        store.dispatch(groupChatInfoSlice.actions.getGroupChatInfo(currentGroupChatId));
      }
    }
  });
}

function* getMemberList(action: PayloadAction<{ page: number; size: number; groupId: string; search: string }>) {
  try {
    yield put(beginScope("getMemberList"));
    const data: MemberListData = yield call(
      GroupChatMemberListApi.getMembers,
      action.payload.page,
      action.payload.size,
      action.payload.groupId,
      action.payload.search,
    );
    const model: MemberListModel = data;
    yield put(groupChatMemberListSlice.actions.getMemberListCompleted(model));
    const trackEvent = GTM(EventCategories.Group);
    trackEvent(GroupsChatMembersEvents.GroupsChatMembersLoaded);
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("getMemberList"));
  }
}

function* blockMember(action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) {
  try {
    yield put(beginScope("blockMember"));
    yield call(GroupChatMemberListApi.blockMember, action.payload.memberId, action.payload.botId);
    const memberList: MemberListModel = yield select(selectMemberList);
    const search: string = yield select(selectSearchText);
    const { id }: GroupChatModel = yield select(selectGroupChatInfo);
    yield put(
      notify({
        message: t("memberActionsNotifications.Member has been banned", {
          member: action.payload.memberName,
        }),
        type: "success",
      }),
    );
    yield put(
      groupChatMemberListSlice.actions.getMemberList({
        page: memberList.currentPage,
        search: search,
        size: MEMBERS_PER_PAGE,
        groupId: id,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("blockMember"));
  }
}

function* unblockMember(action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) {
  try {
    yield put(beginScope("unblockMember"));
    yield call(GroupChatMemberListApi.unlockMember, action.payload.memberId, action.payload.botId);
    yield put(
      notify({
        message: t("memberActionsNotifications.Member has left the group", {
          member: action.payload.memberName,
        }),
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("unblockMember"));
  }
}

function* muteMember(action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) {
  try {
    yield put(beginScope("muteMember"));
    yield call(GroupChatMemberListApi.muteMember, action.payload.memberId, action.payload.botId);

    const memberList: MemberListModel = yield select(selectMemberList);
    const search: string = yield select(selectSearchText);
    const { id }: GroupChatModel = yield select(selectGroupChatInfo);

    yield put(
      notify({
        message: t("memberActionsNotifications.Member has been restricted", {
          member: action.payload.memberName,
        }),
        type: "success",
      }),
    );

    yield put(
      groupChatMemberListSlice.actions.getMemberList({
        page: memberList.currentPage,
        search: search,
        size: MEMBERS_PER_PAGE,
        groupId: id,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("muteMember"));
  }
}

function* unmuteMember(action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) {
  try {
    yield put(beginScope("unmuteMember"));
    yield call(GroupChatMemberListApi.unmuteMember, action.payload.memberId, action.payload.botId);

    yield put(
      notify({
        message: t("memberActionsNotifications.Restrictions have been lifted from Member", {
          member: action.payload.memberName,
        }),
        type: "success",
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("unmuteMember"));
  }
}
function* deleteMember(action: PayloadAction<{ memberId: string; memberName: string; botId: string }>) {
  try {
    yield put(beginScope("deleteMember"));
    yield call(GroupChatMemberListApi.deleteMember, action.payload.memberId, action.payload.botId);
    const memberList: MemberListModel = yield select(selectMemberList);
    const search: string = yield select(selectSearchText);
    const { id }: GroupChatModel = yield select(selectGroupChatInfo);
    yield put(
      notify({
        message: t("memberActionsNotifications.Member has left the group", {
          member: action.payload.memberName,
        }),
        type: "success",
      }),
    );
    yield put(
      groupChatMemberListSlice.actions.getMemberList({
        page: memberList.currentPage,
        search: search,
        size: MEMBERS_PER_PAGE,
        groupId: id,
      }),
    );
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("deleteMember"));
  }
}
