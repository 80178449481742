import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { BotListHeader } from "../botList/components/BotListHeader/BotListHeader";
import s from "./AddBot.module.scss";
import { useAppSelector } from "../../common/state/store";
import { selectIsOrganizationTrial } from "../organisation/OrganisationSlice";
import { MOBILE_VIEW, TABLET_VIEW } from "../layout/LayoutHelper/ResolutionConst";
import { useNowWidthView } from "../layout/LayoutHelper/ResolutionHooks";
import { CreateConnectBotContainer } from "./components/CreateConnectBot/CreateConnectBotContainer";

export const AddBot = () => {
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const nowWidth = useNowWidthView();
  return (
    <Flex className={s.botsPageContainer}>
      <ProtectedHeaderContainer>
        <BotListHeader />
      </ProtectedHeaderContainer>
      <Box
        className={s.botsPageListContainer}
        height={
          nowWidth <= TABLET_VIEW
            ? `calc(100dvh - ${isTrial && nowWidth <= MOBILE_VIEW ? "180px" : "120px"})`
            : "calc(100dvh - 60px)"
        }
        overflow="auto"
      >
        <Loading />
        <CreateConnectBotContainer />
      </Box>
    </Flex>
  );
};
