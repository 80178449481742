import { Box, Flex, Text } from "@chakra-ui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { MAXIMAL_RESTRICTION_COUNT } from "../../consts";
import { useMobileView } from "../../../../../../../../../../layout/LayoutHelper/ResolutionHooks";

const LONG_DASH = "\u2014";
const RESTRICTIONS_DESCRIPTION = ["warning", "muting", "blocking"];

export const ProfanityFilterInfo = () => {
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityFilterPage",
  });
  const isMobile = useMobileView();
  const isEng = i18n.language === "en";

  const restrictionRenderer = RESTRICTIONS_DESCRIPTION.map((restriction, index) => {
    const prefix = t(`restrictions.${restriction}.prefix`);
    const key = t(`restrictions.${restriction}.key`);
    const value = t(`restrictions.${restriction}.value`);

    const Content = () => {
      return (
        <>
          {!!prefix && (
            <>
              <Text variant="medium" as="span" color="newBlack">
                {prefix}
              </Text>
              <Text variant="medium" as="span" color="newBlack">
                {" "}
              </Text>
            </>
          )}
          <Text variant="mediumBold" as="span" color="newBlack">
            {key}
          </Text>
          {!!value && (
            <Text variant="medium" as="span" color="newBlack">
              {" "}
              {!isEng && LONG_DASH} {value}
            </Text>
          )}
          {index + 1 < RESTRICTIONS_DESCRIPTION.length && (
            <Text variant="medium" as="span" color="newBlack">
              ,{" "}
            </Text>
          )}
        </>
      );
    };

    return !isEng && isMobile ? (
      <Text key={index} color="newBlack">
        <Content />
      </Text>
    ) : (
      <Fragment key={index}>
        <Content />
      </Fragment>
    );
  });

  return (
    <Flex flexDirection="column" rowGap="8px">
      <Text variant="h3" color="newBlack">
        {t("To tackle the use of obscene language in group text messages")}
      </Text>
      <Box>
        <Text variant="medium" as="span" color="newBlack">
          {t("The bot automatically deletes any inappropriate messages", { count: MAXIMAL_RESTRICTION_COUNT })}{" "}
        </Text>
        {restrictionRenderer}
      </Box>
    </Flex>
  );
};
