import { store } from "../../../../../..";
import { MemberData } from "./GroupChatMemberListData";
import { MemberListModel } from "./GroupChatMemberListModel";

export const mapGroupChatMemberListDataToModel = (data: MemberData): MemberListModel => {
  const groupChatMemberList: MemberListModel = store.getState().app.groupChatMemberListState.memberList;
  const memberList = groupChatMemberList.items ?? [];

  const updateGroupChatMemberList = memberList.map(member => (member.id === data.id ? data : member));

  const shouldIncreaseTotalItems = data.isNew === true;

  return {
    ...groupChatMemberList,
    items: updateGroupChatMemberList,
    totalItems: shouldIncreaseTotalItems ? groupChatMemberList.totalItems + 1 : groupChatMemberList.totalItems,
  };
};
