import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const dominoGreen = definePartsStyle({
  track: {
    backgroundColor: "greenActive",
    _checked: {
      bg: "mainPurple",
    },
  },
});

const dominoDefaultGreen = definePartsStyle({
  container: {
    outline: "none !important",
  },
  track: {
    bg: "darkGrey",
    outline: "none !important",
    boxShadow: "none !important",
    _checked: {
      bg: "greenActive",
    },
  },
});

const dominoDisabled = definePartsStyle({
  container: {
    outline: "none !important",
  },
  track: {
    bg: "darkGrey",
    outline: "none !important",
    boxShadow: "none !important",
    _checked: {
      bg: "#089850",
    },
  },
});

export const switchTheme = defineMultiStyleConfig({
  variants: { dominoGreen, dominoDefaultGreen, dominoDisabled },
});
