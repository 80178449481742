import { Box, Flex, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import TrashIcon from "../../../../../../../../../../../../../../assets/icons/TrashGrey.svg?react";
import AddIcon from "../../../../../../../../../../../../../../assets/icons/addTimeIcon.svg?react";
import { Select, SelectProps } from "../../../../../../../../../../../../../../UI/atoms/Select/Select";
import { RuleProps } from "../Rule";
import { useContext, useEffect, useRef } from "react";
import { ProfanityFilterContext } from "../../../../../ProfanityFilterContext";
import s from "../Rule.module.scss";
import { MAXIMAL_RESTRICTION_COUNT } from "../../../../../consts";
import { ProfanityFilterTypeEnum } from "../../../../../../../ProfanityFilterModel";

interface Props extends Pick<RuleProps, "rule" | "position" | "handlers"> {
  restrictionSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  timeFramesAmountSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  timeFrameSelect: Pick<SelectProps, "options" | "translattedOptions" | "onChange" | "value">;
  isShownAddButton: boolean;
  isShownDeleteButton: boolean;
}

export const DefaultRuleLayout = ({
  rule,
  restrictionSelect,
  timeFramesAmountSelect,
  timeFrameSelect,
  position,
  handlers,
  isShownAddButton,
  isShownDeleteButton,
}: Props) => {
  const { type, forLong } = rule;
  const t = useTranslation("translation", {
    keyPrefix: "groupChat.groupChatInfo.ProfanityFilterPage",
  }).t;
  const context = useContext(ProfanityFilterContext);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      context?.setRestrictionInfoNodeWidth(ref.current.clientWidth);
    }
  }, [context]);

  return (
    <Grid templateColumns="1fr 1fr" alignItems="center" w="100%" columnGap="16px">
      <GridItem w="100%" ref={ref}>
        <Flex alignItems="center" justifyContent="space-between" columnGap="16px">
          <Text className={s.nowrap} variant="preLargeBold" color="newBlack" minW="131px">
            {position} {t("violation")}
          </Text>
          <Box minW="178px">
            <Select
              options={restrictionSelect.options}
              translattedOptions={restrictionSelect.translattedOptions}
              onChange={restrictionSelect.onChange}
              value={restrictionSelect.value}
            />
          </Box>
        </Flex>
      </GridItem>
      <GridItem>
        <Flex alignItems="center" columnGap="16px" justifyContent="space-between">
          {forLong && (
            <Flex alignItems="center" columnGap="8px" w="100%">
              <Text className={s.nowrap} variant="medium" color="newBlack">
                {t("a member for")}
              </Text>
              <Flex alignItems="center" columnGap="8px" w="100%">
                <Box minW="68px">
                  <Select
                    options={timeFramesAmountSelect.options}
                    onChange={timeFramesAmountSelect.onChange}
                    value={timeFramesAmountSelect.value}
                    shownItems={5}
                  />
                </Box>
                <Box minW="102px" w="100%">
                  <Select
                    options={timeFrameSelect.options}
                    translattedOptions={timeFrameSelect.translattedOptions}
                    onChange={timeFrameSelect.onChange}
                    value={timeFrameSelect.value}
                  />
                </Box>
              </Flex>
            </Flex>
          )}
          <Flex align="center" columnGap="8px">
            {isShownDeleteButton && (
              <Icon
                as={TrashIcon}
                className={s.icon}
                boxSize="30px"
                color="darkGrey"
                _hover={{ color: "mainRed", bgColor: "newGrey" }}
                onClick={() => handlers.delete(position)}
              />
            )}
            {isShownAddButton && (
              <Icon
                as={AddIcon}
                className={s.icon}
                boxSize="30px"
                color="darkGrey"
                _hover={{ color: "darkGreen", bgColor: "newGrey" }}
                onClick={handlers.add}
              />
            )}
            {position === 1 && type !== ProfanityFilterTypeEnum.Mute && isShownAddButton && (
              <Text variant="small" color="darkGrey" wordBreak="break-all" noOfLines={1}>
                {t("Restrictions on violations", { count: MAXIMAL_RESTRICTION_COUNT })}
              </Text>
            )}
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  );
};
