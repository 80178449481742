import { Avatar, Box, Flex, Icon, Tooltip, useBoolean } from "@chakra-ui/react";
import s from "../../../Conversation.module.scss";
import { AppSettings } from "../../../../../common/AppSettings";
import PaymentIcon from "../../../../../assets/icons/PaymentNodeIcon.svg?react";
import { AvatarModel, ConversationMessageModel, ConversationTypeEnum } from "../../../ConversationModel";
import { PhotoMessageItem } from "../PhotoMessageItem";
import { DocumentMessageItem } from "../DocumentMessageItem";
import { TextMessageItem } from "../TextMessageItem";
import { ButtonMessageItem } from "../ButtonMessageItem";
import { useAppSelector } from "../../../../../common/state/store";
import { selectCurrentBot } from "../../../../sidebar/SidebarSlice";
import { ContactMessageItem } from "../ContactMessageItem";
import PaymentMessageItem from "../PaymentMessageItem";
import { UnsupportedMessageItem } from "../UnsupportedMessageItem";
import { MessageContextMenu } from "./components/MessageContextMenu/MessageContextMenu";
import { ReplyToMessage } from "./components/ReplyToMessage/ReplyToMessage";
import { RenderSmoothImage } from "../../../../../UI/molecules/renderSmoothImage/RenderSmoothImage";
import { MessageAvatar } from "./components/MessageAvatar/MessageAvatar";

declare const appSettings: AppSettings;

interface Props {
  message: ConversationMessageModel;
  highlightedText?: string;
  isInViewport?: boolean;
  isHighlighted?: boolean;
  userName?: string;
  avatars: AvatarModel[][];
  onReply: (reply: {
    enabled: boolean;
    message?: {
      id: string;
      author: string;
      text?: string;
      photoFileId?: string;
      documentName?: string;
      contact?: string;
    };
  }) => void;
  onReplyClick: (targetMessageId: string) => void;
  isReply?: boolean;
  conversationType: ConversationTypeEnum;
}

export const MessageItem = ({ ...props }: Props) => {
  const [messageHover, setMessageHover] = useBoolean(false);
  const botInfo = useAppSelector(selectCurrentBot);

  let displayImageIndex = 1;
  const message = props.message;

  const messagePhotoLength = props.message.photos.length;
  if (messagePhotoLength <= displayImageIndex) {
    displayImageIndex = messagePhotoLength - 1;
  }
  if (props.message.contact && props.message.replyToMessage) {
    const elem = document.getElementById(`contact-${props.message.id}`);
    const elemReply = document.getElementById(`reply-${props.message.id}`);
    if (elemReply && elem) {
      elemReply.style.width = `${elem.offsetWidth}px`;
    }
  }

  const isSupported = !!(
    message.replyToMessage ||
    message.text ||
    message.contact ||
    message.documents.length > 0 ||
    message.photos.length > 0 ||
    message.payment ||
    message.replyMarkup
  );

  const isWebBot = botInfo?.channel === "Web";

  return (
    <Box
      className={`${s.messageItemWrapper} ${s[props.message.direction]} ${props.message.replyToMessage ? s["WithReply"] : ""}`}
      onMouseLeave={setMessageHover.off}
    >
      {props.isInViewport && <Box className={s.backgroundHiglighted}></Box>}
      <Box className={`${s.messageHiglighted} ${props.isHighlighted ? s.messageHiglightedShow : s.messageHiglightedHide}`}></Box>
      {props.userName === "bot" && botInfo ? (
        <MessageAvatar origin={props.message.origin} botInfo={botInfo} />
      ) : props.message.payment ? (
        <Tooltip label={props.userName} variant="dominoLight">
          <Box className={s.payment}>
            <Icon as={PaymentIcon} boxSize="18px" viewBox="0 0 18 18" />
          </Box>
        </Tooltip>
      ) : props.avatars.length ? (
        <RenderSmoothImage
          isShowTooltip
          boxSize="32px"
          alt={props.userName}
          src={`${appSettings.apiBaseUrl}/file/${props.avatars[0][0].fileId}`}
        />
      ) : (
        <Tooltip label={props.userName} variant="dominoLight">
          <Avatar name={props.userName} className={s.avatar} size="smDomino" />
        </Tooltip>
      )}
      <Flex direction="column" style={{ maxWidth: "80%" }}>
        <Box
          onMouseEnter={setMessageHover.on}
          className={`${s.message} ${props.message.replyMarkup !== null ? s.messagWithButtons : ""}`}
          style={{
            maxWidth: props.message.photos[displayImageIndex]?.width ?? "",
          }}
        >
          {isSupported ? (
            <>
              {props.message.replyToMessage && (
                <ReplyToMessage
                  botInfo={botInfo}
                  displayImageIndex={displayImageIndex}
                  message={props.message}
                  onReplyClick={props.onReplyClick}
                />
              )}
              <PhotoMessageItem message={message} conversationType={props.conversationType} />
              <DocumentMessageItem message={message} conversationType={props.conversationType} />
              <TextMessageItem message={message} conversationType={props.conversationType} />
              <ContactMessageItem message={message} />
              <PaymentMessageItem message={message} />
            </>
          ) : (
            <UnsupportedMessageItem message={message} />
          )}
        </Box>
        <ButtonMessageItem message={message} />
      </Flex>
      {!isWebBot && (
        <MessageContextMenu
          message={message}
          onReply={props.onReply}
          isSupported={isSupported}
          isReply={props.isReply}
          userName={props.userName}
          messageHover={messageHover}
          conversationType={props.conversationType}
        />
      )}
    </Box>
  );
};
